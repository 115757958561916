(function webpackUniversalModuleDefinition(root, factory) {
	if(typeof exports === 'object' && typeof module === 'object')
		module.exports = factory(require("React"), require("ReactDOM"), require("ReactRouterDOM"), require("styled"));
	else if(typeof define === 'function' && define.amd)
		define(["React", "ReactDOM", "ReactRouterDOM", "styled"], factory);
	else if(typeof exports === 'object')
		exports["dashboard"] = factory(require("React"), require("ReactDOM"), require("ReactRouterDOM"), require("styled"));
	else
		root["__WIDGETS__"] = root["__WIDGETS__"] || {}, root["__WIDGETS__"]["dashboard"] = factory(root["React"], root["ReactDOM"], root["ReactRouterDOM"], root["styled"]);
})(window, function(__WEBPACK_EXTERNAL_MODULE_react__, __WEBPACK_EXTERNAL_MODULE_react_dom__, __WEBPACK_EXTERNAL_MODULE_react_router_dom__, __WEBPACK_EXTERNAL_MODULE_styled_components__) {
return 