import { mergeStyleSets } from "office-ui-fabric-react";
export var controlClass = mergeStyleSets({
    control: {
        marginBottom: '10px',
        marginRight: '30px',
        maxWidth: '300px',
    },
    searchStyles: {
        marginTop: '30px',
    },
    submitStyles: {
        marginTop: '20px',
        marginLeft: '10px',
    },
});
export var controlStyles = {
    root: {
        margin: '0 30px 20px 0',
        maxWidth: '150px',
    },
};
export var dropdownStyles = {
    dropdown: { width: 200 },
};
export var stackTokens = { childrenGap: 50 };
