var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { withContext } from '@employee-experience/common/lib/withContext';
import * as Styled from '../../shared/layout';
import { ComponentContext } from '@employee-experience/common/lib/Context/ComponentContext';
import { MessageBar, MessageBarType } from 'office-ui-fabric-react';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { useState, useEffect } from 'react';
import GenericForm from '../CommonUtilities/generic-form/GenericForm';
import { PostCleranceFormConfigurationData } from '../CommonUtilities/generic-form/GenericFormConfig';
import { LoadingIndicator } from '../CommonUtilities/LoadingIndicator';
import { toast } from 'react-toastify';
import { httpGet, httpPut } from '../../shared/APICalls';
import { verticalStackProps } from './PostClearenceStyles';
var appConfig = require('../../config/appConfig.js').appConfig;
var baseUrl = appConfig.AppSettings.APIUrl;
var PostClearence = function (props) {
    var context = React.useContext(ComponentContext);
    var _httpClient = context === null || context === void 0 ? void 0 : context.httpClient;
    var _a = useState({}), postClrData = _a[0], setPostClrData = _a[1];
    var _b = useState(false), isDataLoaded = _b[0], setIsDataLoaded = _b[1];
    var _c = React.useState(false), showErrors = _c[0], setShowErrors = _c[1];
    var _d = React.useState([]), errors = _d[0], setErrors = _d[1];
    useEffect(function () {
        var url = baseUrl + "/PostClearance?shipmentRefNo=" + props.shipmentRefNumber;
        httpGet(_httpClient, url, function (response) {
            setPostClrData(response.data);
            setIsDataLoaded(true);
        }, function (error) {
            console.log(error);
        });
    }, []);
    var onPanelSubmit = function (panelData) {
        var url = baseUrl + "/PostClearance/";
        httpPut(_httpClient, url, function (response) {
            setShowErrors(false);
            if (response.data == true) {
                toast.success("Post Clearance Data Saved Successfully");
            }
            else {
                toast.error("Post Clearance Data Save Failed");
            }
        }, function (error) {
            var errorTmp = [];
            for (var key in error.data) {
                if (error.data.hasOwnProperty(key)) {
                    errorTmp.push(error.data[key]);
                }
            }
            setErrors(errorTmp);
            setShowErrors(true);
        }, JSON.stringify(panelData));
    };
    var ErrorExample = function (props) { return (React.createElement(MessageBar, { messageBarType: MessageBarType.error, isMultiline: false, 
        //onDismiss={p.resetChoice}
        dismissButtonAriaLabel: "Close" }, props.data)); };
    return (React.createElement(Styled.listContainer, null,
        React.createElement("div", null,
            React.createElement(LoadingIndicator, null)),
        showErrors &&
            React.createElement(Stack, __assign({}, verticalStackProps), errors.map(function (data, index) { return (React.createElement(ErrorExample, { data: data })); })),
        React.createElement(GenericForm, { onPanelSubmit: onPanelSubmit, formConfigurationData: PostCleranceFormConfigurationData, formValues: postClrData, submitButtonText: "Submit Post Clearance Data" })));
};
var connected = withContext(PostClearence);
export { connected as PostClearence };
