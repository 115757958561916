import * as React from 'react';
import { useId } from '@uifabric/react-hooks';
import { Stack, Label, DetailsList, SelectionMode, } from 'office-ui-fabric-react';
import { ComponentContext } from '@employee-experience/common';
import { useHistory } from 'react-router-dom';
import { useState, useEffect } from 'react';
import { httpGet } from '../../shared/APICalls';
var appConfig = require('../../config/appConfig.js').appConfig;
var baseUrl = appConfig.AppSettings.APIUrl;
export var PODetails = function (props) {
    var resourceId = appConfig.AppSettings.AppClientId;
    // Use useId() to ensure that the IDs are unique on the page.
    // (It's also okay to use plain strings and manually ensure uniqueness.)
    var titleId = useId('title');
    var context = React.useContext(ComponentContext);
    var _httpClient = context === null || context === void 0 ? void 0 : context.httpClient;
    var history = useHistory();
    var _a = useState([{
            companyCode: '',
            currency: null,
            invoiceAmount: 0,
            poNumber: '',
            vendorName: null,
            vendorCode: '',
            billToLocation: '',
            shipToLocation: '',
            paymentTerms: '',
            poValue: '',
            shortDescription: '',
        }]), poDetails = _a[0], setPODetails = _a[1];
    var _b = useState([]), defaultPODetails = _b[0], setDefaultPODetails = _b[1];
    var _c = useState([]), columns = _c[0], setColumns = _c[1];
    var stackStyles = { root: { width: 500 } };
    var horizontalGapStackTokens = {
        childrenGap: 50,
        padding: 10,
    };
    var poLineItemColumns = [
        {
            key: 'lineItemNo',
            name: 'LineItem No',
            ariaLabel: 'lineItemNo',
            fieldName: 'lineItemNo',
            isResizable: true,
            minWidth: 50,
            maxWidth: 100,
            data: 'string',
            //onColumnClick: handleColumnClick,
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.poItem); },
        },
        {
            key: 'lineItemDesc',
            name: 'LineItem Description',
            ariaLabel: 'lineItemDesc',
            fieldName: 'lineItemDesc',
            isResizable: true,
            minWidth: 80,
            maxWidth: 200,
            data: 'string',
            //onColumnClick: handleColumnClick,
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.shortDescription); },
        },
        {
            key: 'unitPrice',
            name: 'Unit Price',
            ariaLabel: 'Unit Price',
            fieldName: 'unitPrice',
            isResizable: true,
            minWidth: 50,
            maxWidth: 150,
            data: 'string',
            //onColumnClick: handleColumnClick,
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.poUnitPrice); },
        },
        {
            key: 'invoiceQuantiy',
            name: 'PO Quantiy',
            ariaLabel: 'PO Quantiy',
            fieldName: 'invoiceQuantiy',
            isResizable: true,
            minWidth: 50,
            maxWidth: 100,
            data: 'string',
            //onColumnClick: handleColumnClick,
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.poQuanity); },
        },
        {
            key: 'poValue',
            name: 'PO Value',
            ariaLabel: 'PO Value',
            fieldName: 'poValue',
            isResizable: true,
            minWidth: 50,
            maxWidth: 100,
            data: 'string',
            //onColumnClick: handleColumnClick,
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.poValue); },
        },
    ];
    useEffect(function () {
        //const FetchFormData = async () => {
        var url = baseUrl + "/PurchaseOrder?poNumber=" + props.poNumber;
        httpGet(_httpClient, url, function (response) {
            setPODetails(response.data);
            setDefaultPODetails(response.data);
        }, function (error) {
            console.log(error);
        });
    }, []);
    return (React.createElement(Stack, null,
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(Label, { id: "poNumber" },
                "PO Number: ",
                poDetails[0].poNumber)),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(Label, { id: "companyCode" },
                "Company Code: ",
                poDetails[0].companyCode)),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(Label, { id: "vendorCode" },
                "Vendor Code: ",
                poDetails[0].vendorCode)),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(Label, { id: "vendorName" },
                "Vendor Name: ",
                poDetails[0].vendorName)),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(Label, { id: "currency" },
                "Currency: ",
                poDetails[0].currency)),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(Label, { id: "billToLocation" },
                "Bill To Location: ",
                poDetails[0].billToLocation)),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(Label, { id: "shipToLocation" },
                "Ship To Location: ",
                poDetails[0].shipToLocation)),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(Label, { id: "paymentTerms" },
                "Payment Terms: ",
                poDetails[0].paymentTerms,
                " ")),
        React.createElement(Stack, null,
            React.createElement(Label, null, "PO Lineitem Details:")),
        React.createElement(Stack, null,
            React.createElement(DetailsList, { selectionMode: SelectionMode.none, columns: poLineItemColumns, items: defaultPODetails }))));
};
