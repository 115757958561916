import { trackPromise } from 'react-promise-tracker';
import { appConfig } from '../config/appConfig';
var resourceId = appConfig.AppSettings.AppClientId;
var apimKey = appConfig.AppSettings.APIMKey;
export function httpGet(_httpClient, url, onSuccessCallback, onErrorCallback) {
    trackPromise(_httpClient
        .get(url, {
        method: 'GET',
        resource: resourceId,
        headers: {
            'Content-Type': 'application/json',
            'X-Content-Type-Options': 'nosniff',
            'Ocp-Apim-Subscription-Key': "" + apimKey
        },
    })
        .then(function (response) {
        if (onSuccessCallback != null) {
            onSuccessCallback(response);
        }
    })
        .catch(function (error) {
        if (onErrorCallback != null) {
            onErrorCallback(error);
        }
    }));
}
export function httpPut(_httpClient, url, onSuccessCallback, onErrorCallback, formData) {
    trackPromise(_httpClient
        .put(url, {
        method: 'PUT',
        resource: resourceId,
        headers: {
            'Content-Type': 'application/json',
            'X-Content-Type-Options': 'nosniff',
            'Ocp-Apim-Subscription-Key': "" + apimKey
        },
        data: formData,
    })
        .then(function (response) {
        if (onSuccessCallback != null) {
            onSuccessCallback(response);
        }
    })
        .catch(function (error) {
        if (onErrorCallback != null) {
            onErrorCallback(error);
        }
    }));
}
export function httpPost(_httpClient, url, onSuccessCallback, onErrorCallback, formData) {
    trackPromise(_httpClient
        .post(url, {
        method: 'POST',
        resource: resourceId,
        headers: {
            'Content-Type': 'application/json',
            'X-Content-Type-Options': 'nosniff',
            'Ocp-Apim-Subscription-Key': "" + apimKey
        },
        data: formData,
    })
        .then(function (response) {
        if (onSuccessCallback != null) {
            onSuccessCallback(response);
        }
    })
        .catch(function (error) {
        if (onErrorCallback != null) {
            onErrorCallback(error);
        }
    }));
}
export function httpDelete(_httpClient, url, onSuccessCallback, onErrorCallback) {
    trackPromise(_httpClient
        .delete(url, {
        method: 'DELETE',
        resource: resourceId,
        headers: {
            'Content-Type': 'application/json',
            'X-Content-Type-Options': 'nosniff',
            'Ocp-Apim-Subscription-Key': "" + apimKey
        },
    })
        .then(function (response) {
        if (onSuccessCallback != null) {
            onSuccessCallback(response);
        }
    })
        .catch(function (error) {
        if (onErrorCallback != null) {
            onErrorCallback(error);
        }
    }));
}
