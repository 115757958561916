import * as React from 'react';
import { withContext } from '@employee-experience/common/lib/withContext';
import { Card, CardHeading, CardContent } from '@employee-experience/common/lib/Card';
import { addDays, addMonths } from 'office-ui-fabric-react/lib/utilities/dateMath/DateMath';
import { useState, useEffect } from 'react';
import { ComponentContext } from '@employee-experience/common/lib/Context/ComponentContext';
import '!style-loader!css-loader!react-toastify/dist/ReactToastify.css';
import { Pie } from 'react-chartjs-2';
import { httpPost } from '../../shared/APICalls';
import { dateToISOLikeButLocal } from '../CommonUtilities/CommonFunctions';
var appConfig = require('../../config/appConfig.js').appConfig;
var baseUrl = appConfig.AppSettings.APIUrl;
function ShipmentsPOTypeDetailsCard() {
    var context = React.useContext(ComponentContext);
    var _httpClient = context === null || context === void 0 ? void 0 : context.httpClient;
    var _a = useState(0), numberOfShipmentsWithPO = _a[0], setNumberOfShipmentsWithPO = _a[1];
    var _b = useState(0), numberOfShipmentsWithoutPO = _b[0], setNumberOfShipmentsWithoutPO = _b[1];
    useEffect(function () {
        var url = baseUrl + "/Dashboard/GetDashboardIfWithoutPOShipmentDetails";
        httpPost(_httpClient, url, function (response) {
            setNumberOfShipmentsWithPO(response.data.numberOfShipmentsWithPO);
            setNumberOfShipmentsWithoutPO(response.data.numberOfShipmentsWithoutPO);
        }, function (error) {
            console.log(error);
        }, JSON.stringify({
            fromDate: dateToISOLikeButLocal(addMonths(new Date(Date.now()), -2)),
            toDate: dateToISOLikeButLocal(addDays(new Date(Date.now()), 0)),
        }));
    }, []);
    return (React.createElement(Card, null,
        React.createElement(CardHeading, null, "Shipments With/Without PO"),
        React.createElement(CardContent, null,
            React.createElement("div", null,
                React.createElement(Pie, { data: {
                        labels: ['Shipments with PO', 'Shipments without PO'],
                        datasets: [
                            {
                                label: 'Shipments Type',
                                backgroundColor: [
                                    '#039be5',
                                    '#4caf50'
                                ],
                                hoverBackgroundColor: [
                                    '#039be5',
                                    '#4caf50'
                                ],
                                data: [numberOfShipmentsWithPO, numberOfShipmentsWithoutPO]
                            }
                        ]
                    }, width: 250, height: 250, options: {
                        title: {
                            display: false,
                            text: 'Shipments by Type (last 2 months)',
                            fontSize: 20
                        },
                        maintainAspectRatio: false,
                        legend: {
                            display: true,
                            position: 'bottom'
                        }
                    } })))));
}
var connected = withContext(ShipmentsPOTypeDetailsCard);
export { connected as ShipmentsPOTypeDetailsCard };
