import * as React from 'react';
import { PrimaryButton, TextField, DatePicker } from 'office-ui-fabric-react';
import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { useState } from 'react';
import { EditType } from './EditableGridConfig';
import { InputType } from '../generic-form/GenericFormConfig';
import { DayPickerStrings } from '../DatePickerConfigs';
import { dateToISOLikeButLocal } from '../CommonFunctions';
var EditPanel = function (props) {
    var _a = useState([]), renderObj = _a[0], setRenderObj = _a[1];
    var textFieldStyles = { fieldGroup: {} };
    var formFieldStyle = {
        marginLeft: '15%',
        marginTop: 5,
        marginRight: '20%',
        marginBottom: 18,
    };
    var buttonFieldStyle = {
        width: 200,
        marginLeft: '50%',
        marginRight: '50%',
    };
    var updateObj = {};
    var verticalGapStackTokens = {
        childrenGap: 15,
        padding: 10,
    };
    var onTextUpdate = function (ev, text) {
        updateObj[ev.target.id] = text;
        //console.log(updateObj);
    };
    var controlClass = mergeStyleSets({
        control: {
            marginBottom: '10px',
            marginRight: '30px',
            maxWidth: '300px',
        },
        searchStyles: {
            marginTop: '30px',
        },
        submitStyles: {
            marginTop: '20px',
            marginLeft: '10px',
            marginRight: '10px',
            maxWidth: '300px',
        },
        labelValue: {
            fontWeight: 'bold',
        }
    });
    var stackStyles = { root: { width: 500 } };
    var horizontalGapStackTokens = {
        childrenGap: 10,
        padding: 10,
    };
    var onPanelSubmit = function () {
        console.log(updateObj);
        props.onChange(updateObj);
    };
    var onSubmit = function (e) { };
    var updatePostClearanceData = function (e) { };
    var onCellDateChange = function (date, item) {
        updateObj[item.key] = dateToISOLikeButLocal(date);
    };
    var createTextFields = function () {
        var tmpRenderObj = [];
        props.columnConfigurationData.forEach(function (item, index) {
            if (item.editable == true) {
                switch (item.inputType) {
                    case InputType.Date:
                        tmpRenderObj.push(React.createElement(DatePicker, { label: item.text, showWeekNumbers: true, strings: DayPickerStrings, showMonthPickerAsOverlay: true, placeholder: "Select a date...", ariaLabel: "Select a date", onSelectDate: function (date) { return onCellDateChange(date, item); }, 
                            //value={props != null && props.panelValues != null ? new Date(props.panelValues[item.key]) : new Date()}
                            value: new Date() }));
                        break;
                    default:
                        tmpRenderObj.push(React.createElement(TextField, { name: item.text, id: item.key, label: item.text, styles: textFieldStyles, onChange: onTextUpdate, defaultValue: props.editType == EditType.RowEdit && props.panelValues ? props.panelValues[item.key] : '' }));
                        break;
                }
            }
        });
        console.log(tmpRenderObj);
        return tmpRenderObj;
    };
    return (React.createElement(Stack, null,
        React.createElement(Stack, { tokens: verticalGapStackTokens }, createTextFields()),
        React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
            React.createElement(PrimaryButton, { text: "Save To Grid", className: controlClass.submitStyles, onClick: onPanelSubmit, allowDisabledFocus: true }))));
};
export default EditPanel;
