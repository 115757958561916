var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { CommandBarButton, DayOfWeek, DetailsListLayoutMode, Fabric, FontWeights, IconButton, Modal, SelectionMode, Stack, getTheme, makeStyles, mergeStyleSets, Dropdown, } from '@fluentui/react';
import * as Styled from '../../shared/layout';
import { useId, useBoolean } from '@fluentui/react-hooks';
import * as React from 'react';
import { LoadingIndicator } from '../CommonUtilities/LoadingIndicator';
import { ComponentContext } from '@employee-experience/common';
import { useEffect, useState } from 'react';
import EditableGridWithPagination from '../Reports/EditableGridWithPagination';
import { NotificationRateColumnConfigurationData } from './EditableGridConfigExchangeRate';
import { appConfig } from '../../config/appConfig';
import { httpGet, httpPost } from '../../shared/APICalls';
import { toast } from 'react-toastify';
import { TextField, PrimaryButton } from '@fluentui/react';
import { DatePicker } from 'office-ui-fabric-react';
var baseUrl = appConfig.AppSettings.APIUrl;
var useStyles = makeStyles({
    content: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
    },
});
function ConfigureNotification() {
    var context = React.useContext(ComponentContext);
    var _httpClient = context === null || context === void 0 ? void 0 : context.httpClient;
    var _a = useState([]), defaultItems = _a[0], setDefaultItems = _a[1];
    var datePickerStrings = {
        months: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ],
        shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        goToToday: 'Go to today',
        prevMonthAriaLabel: 'Go to previous month',
        nextMonthAriaLabel: 'Go to next month',
        prevYearAriaLabel: 'Go to previous year',
        nextYearAriaLabel: 'Go to next year',
        closeButtonAriaLabel: 'Close date picker',
        isRequiredErrorMessage: 'Field is required.',
    };
    var today = new Date();
    var dropdownStyles = {
        dropdown: { width: 300 },
    };
    var options = [
        { key: 'IGST', text: 'IGST' },
        { key: 'BasicDutyRate', text: 'Basic Duty Rate' },
        { key: 'ECESS', text: 'ECESS' },
        { key: 'SplDuty', text: 'Spl Duty' },
        { key: 'CessRate', text: 'CessRate' },
    ];
    var _b = useState({
        id: 0,
        notificationNumber: '',
        serialNumber: '',
        newRate: '',
        validFrom: today,
        validTo: today,
        dutyRateType: 'IGST',
        method: '',
    }), formData = _b[0], setFormData = _b[1];
    var handleChange = function (event, fieldName) {
        var _a;
        setFormData(__assign(__assign({}, formData), (_a = {}, _a[fieldName] = event.target.value, _a)));
    };
    var handleFromDateChange = function (date) {
        setFormData(__assign(__assign({}, formData), { validFrom: date || today }));
    };
    var handleToDateChange = function (date) {
        setFormData(__assign(__assign({}, formData), { validTo: date || today }));
    };
    var handleSubmit = function (event) {
        event.preventDefault();
        hideModal();
        if (formData.validFrom > formData.validTo) {
            toast.error('Valid From date cannot be greater than Valid To date');
            return;
        }
        if (formData.notificationNumber == '' || formData.serialNumber == '' || formData.newRate == '') {
            toast.error('Please fill all the fields');
            return;
        }
        var url = baseUrl + "/ConfigureRates/UpdateNotificationRates";
        var createRequestObject = {
            id: formData.id,
            notification_Number: formData.notificationNumber,
            serial_Number: formData.serialNumber,
            new_Rate: formData.newRate,
            valid_From: formData.validFrom,
            valid_To: formData.validTo,
            dutyRateType: formData.dutyRateType,
            method: formData.method,
        };
        httpPost(_httpClient, url, onPOSTRequestSuccessCallback, onErrorCallback, [createRequestObject]);
    };
    var onPOSTRequestSuccessCallback = function (response) {
        if (response.data.status == false) {
            toast.error(response.data.message);
        }
        if (response.data.status == true) {
            toast.success(response.data.message);
        }
        var url = baseUrl + "/ConfigureRates/NotificationRates";
        httpGet(_httpClient, url, onSuccessCallback, onErrorCallback);
    };
    var onSuccessCallback = function (response) {
        setDefaultItems(response.data);
        if (response) {
            if (response.data.length == 0)
                toast.success("No entries to show.");
        }
    };
    var onErrorCallback = function (error) {
        toast.error('Invalid request or server error. Please try again later.');
    };
    useEffect(function () {
        toast.configure({
            position: 'top-center',
            autoClose: 5000,
            bodyClassName: 'toast-body',
            hideProgressBar: true,
            style: { marginTop: 50 },
        });
        var url = baseUrl + "/ConfigureRates/NotificationRates";
        httpGet(_httpClient, url, onSuccessCallback, onErrorCallback);
    }, []);
    var addIcon = { iconName: 'Add' };
    var stackStyles = {
        root: {
            height: 44,
            marginTop: 8,
            marginBottom: 5,
            marginLeft: 10,
        },
    };
    useEffect(function () {
        setDefaultItems(defaultItems);
    }, [defaultItems]);
    var verticalGapStackTokens = {
        childrenGap: 15,
        padding: 10,
    };
    var theme = getTheme();
    var _c = useBoolean(false), isModalOpen = _c[0], _d = _c[1], showModal = _d.setTrue, hideModal = _d.setFalse;
    var titleId = useId('Add Notification Rate Data');
    var contentStyles = mergeStyleSets({
        container: {
            display: 'flex',
            flexFlow: 'column nowrap',
            alignItems: 'stretch',
        },
        header: [
            // eslint-disable-next-line deprecation/deprecation
            theme.fonts.xLargePlus,
            {
                flex: '1 1 auto',
                borderTop: "4px solid " + theme.palette.themePrimary,
                color: theme.palette.neutralPrimary,
                display: 'flex',
                alignItems: 'center',
                fontWeight: FontWeights.semibold,
                padding: '12px 12px 14px 24px',
            },
        ],
        heading: {
            color: theme.palette.neutralPrimary,
            fontWeight: FontWeights.semibold,
            fontSize: 'inherit',
            margin: '0',
        },
        body: {
            flex: '4 4 auto',
            padding: '0 24px 24px 24px',
            overflowY: 'hidden',
            selectors: {
                p: { margin: '14px 0' },
                'p:first-child': { marginTop: 0 },
                'p:last-child': { marginBottom: 0 },
            },
        },
    });
    var cancelIcon = { iconName: 'Cancel' };
    var iconButtonStyles = {
        root: {
            color: theme.palette.neutralPrimary,
            marginLeft: 'auto',
            marginTop: '4px',
            marginRight: '2px',
        },
        rootHovered: {
            color: theme.palette.neutralDark,
        },
    };
    var onRowEdit = function (rowData) {
        setFormData({
            id: rowData[0].id,
            notificationNumber: rowData[0].notification_Number,
            serialNumber: rowData[0].serial_Number,
            newRate: rowData[0].new_Rate,
            validFrom: new Date(rowData[0].valid_From),
            validTo: new Date(rowData[0].valid_To),
            method: 'Put',
            dutyRateType: rowData[0].dutyRateType,
        });
        showModal();
    };
    var onNotifiCreate = function () {
        setFormData({
            id: 0,
            notificationNumber: '',
            serialNumber: '',
            newRate: '',
            validFrom: today,
            validTo: today,
            dutyRateType: 'IGST',
            method: 'Post',
        });
        showModal();
    };
    return (React.createElement("div", null,
        React.createElement(Stack, { horizontal: true, styles: stackStyles },
            React.createElement(CommandBarButton, { onClick: onNotifiCreate, iconProps: addIcon, text: "Add Notification Rate Data" })),
        React.createElement(Modal, { titleAriaId: titleId, isOpen: isModalOpen, onDismiss: hideModal, isBlocking: false, containerClassName: contentStyles.container },
            React.createElement("div", { className: contentStyles.header },
                React.createElement("h2", { className: contentStyles.heading, id: titleId }, "Add Notification Rate Data"),
                React.createElement(IconButton, { styles: iconButtonStyles, iconProps: cancelIcon, ariaLabel: "Close popup modal", onClick: hideModal })),
            React.createElement("div", { className: contentStyles.body },
                React.createElement("form", { onSubmit: handleSubmit },
                    React.createElement(Stack, { tokens: verticalGapStackTokens },
                        React.createElement(TextField, { label: "Notification Number", value: formData.notificationNumber, onChange: function (event, value) { return handleChange(event, 'notificationNumber'); } }),
                        React.createElement(TextField, { label: "Serial Number", value: formData.serialNumber, onChange: function (event, value) { return handleChange(event, 'serialNumber'); } }),
                        React.createElement(Dropdown, { label: "Duty Rate Type", defaultSelectedKey: formData.dutyRateType, options: options, styles: dropdownStyles, onChange: function (e, option) {
                                return setFormData(__assign(__assign({}, formData), { dutyRateType: (option === null || option === void 0 ? void 0 : option.key.toString()) || '' }));
                            } }),
                        React.createElement(TextField, { label: "New Rate", value: formData.newRate, onChange: function (event, value) { return handleChange(event, 'newRate'); } }),
                        React.createElement(DatePicker, { label: "Valid From", value: formData.validFrom, placeholder: "Select a date", showMonthPickerAsOverlay: true, formatDate: function (date) { return date.toLocaleDateString(); }, firstDayOfWeek: DayOfWeek.Sunday, strings: datePickerStrings, onSelectDate: handleFromDateChange }),
                        React.createElement(DatePicker, { label: "Valid To", value: formData.validTo, placeholder: "Select a date", showMonthPickerAsOverlay: true, formatDate: function (date) { return date.toLocaleDateString(); }, firstDayOfWeek: DayOfWeek.Sunday, strings: datePickerStrings, onSelectDate: handleToDateChange }),
                        React.createElement(PrimaryButton, { type: "submit", text: "Submit" }))))),
        React.createElement(LoadingIndicator, null),
        React.createElement(Styled.listContainer, null,
            React.createElement(Fabric, null, defaultItems.length > 0 && (React.createElement(EditableGridWithPagination, { enableColumnEdit: false, enableExport: false, enableSave: false, columnConfigurationData: NotificationRateColumnConfigurationData, layoutMode: DetailsListLayoutMode.justified, selectionMode: SelectionMode.none, enableRowEdit: true, enableBulkEdit: false, gridData: defaultItems, onRowEdit: onRowEdit }))))));
}
export { ConfigureNotification as ConfigureNotification };
