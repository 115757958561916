import { mergeStyleSets } from "office-ui-fabric-react";
export var controlClass = mergeStyleSets({
    control: {
        width: 200
    },
    searchStyles: {
        marginTop: '30px',
    },
    submitStyles: {
        marginTop: '20px',
        marginLeft: '10px',
    },
    shipToSpanStyles: {
        display: 'block',
        wordwrap: 'break-word'
    },
    shipToToolTipStyles: {
        overflow: 'hidden',
        textoverflow: 'ellipsis'
    },
    multilineTextStyles: {
        display: 'inline-block',
        width: '100%',
        height: '100%',
        whiteSpace: 'pre-wrap',
        textAlign: 'left'
    }
});
export var hostStyles = {
    root: { display: 'block' },
};
export var controlStyles = {
    root: {
        margin: '0 30px 20px 0',
        maxWidth: '150px',
    },
};
export var stackTokens = { childrenGap: 50 };
export var dropdownStyles = {
    dropdown: { width: 200 },
};
