var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { render } from 'react-dom';
import { Text } from 'office-ui-fabric-react/lib/Text';
import { CoherenceCustomizations } from '@cseo/styles';
import { Customizer } from 'office-ui-fabric-react';
import { ShellWithStore } from './ShellWithStore';
import { useLoginOnStartup } from '@employee-experience/common/lib/useLoginOnStartup';
import { BrowserRouter } from 'react-router-dom';
import { ShellStyles } from '@employee-experience/shell/lib/Shell';
import { Header } from '@employee-experience/shell/lib/Header';
import { Nav } from '@employee-experience/shell/lib/Nav';
import { Main } from '@employee-experience/shell/lib/Main';
import { Footer } from '@employee-experience/shell/lib/Footer';
import { navConfig } from './navConfig';
import { Scopes, GraphConfig } from './Config';
import { useHeaderConfig } from './useHeaderConfig';
import { Routes } from './Routes';
import { LoadingIndicator } from '../src/containers/CommonUtilities/LoadingIndicator';
import "./App.css";
import { ComponentContext } from '@employee-experience/common/lib/Context/ComponentContext';
import { Client } from "@microsoft/microsoft-graph-client";
function App() {
    useLoginOnStartup();
    var _a = useState(true), isAuthorized = _a[0], setIsAuthorized = _a[1];
    var authClient = React.useContext(ComponentContext).authClient;
    React.useEffect(function () {
        authClient.acquireToken(Scopes)
            .then(function (token) {
            var gClient = Client.init({
                authProvider: function (done) { done(null, token); }
            });
            gClient.api(GraphConfig.graphurl)
                .query(GraphConfig.graphQuery).get()
                .then(function (groups) {
                if (groups.value != null) {
                    console.log(groups.value.length + ' groups');
                    var selectedG = groups.value.find(function (g) { return g.id == GraphConfig.authGrpId; });
                    if (selectedG != null) {
                        setIsAuthorized(true);
                    }
                    else {
                        setIsAuthorized(false);
                    }
                }
            });
        })
            .catch(console.log);
    });
    var headerConfig = useHeaderConfig();
    if (isAuthorized) {
        return (React.createElement(Customizer, __assign({}, CoherenceCustomizations),
            React.createElement(LoadingIndicator, null),
            React.createElement(ShellStyles, null),
            React.createElement(BrowserRouter, null,
                React.createElement(Header, __assign({}, headerConfig)),
                React.createElement(Nav, { groups: navConfig }),
                React.createElement(Main, null,
                    React.createElement(Routes, null)),
                React.createElement(Footer, null,
                    React.createElement("div", { className: "center" },
                        React.createElement(Text, null,
                            React.createElement("a", { href: "#" }, "Terms & Conditions"),
                            ' | ',
                            React.createElement("a", { href: "https://privacy.microsoft.com/en-US/data-privacy-notice", target: "_blank" }, "Privacy Statement"))),
                    React.createElement("div", { className: "center" },
                        React.createElement(Text, null,
                            "To create a support ticket for critical system issues and notify on-call engineer \u00A0",
                            React.createElement("a", { href: "mailto:tradeDRI@microsoft.com?subject=%23ICM3 | Critical Support Request from India Imports Portal", target: "_blank" }, "click here")))))));
    }
    else {
        return (React.createElement(Customizer, __assign({}, CoherenceCustomizations),
            React.createElement(BrowserRouter, null,
                React.createElement(Main, null,
                    React.createElement("div", { className: "spam" },
                        React.createElement("h1", { className: "dh1" }, " Access Denied(403)"),
                        React.createElement("br", null),
                        React.createElement("br", null),
                        React.createElement("p", null,
                            "Please reach out India Imports Team / Trade BI Team for access  ",
                            React.createElement("br", null),
                            React.createElement("br", null),
                            React.createElement("small", null, "------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------------")))))));
    }
}
render(React.createElement(ShellWithStore, null,
    React.createElement(App, null)), document.getElementById('app'));
