export var EditType;
(function (EditType) {
    EditType[EditType["None"] = 0] = "None";
    EditType[EditType["ColumnEdit"] = 1] = "ColumnEdit";
    EditType[EditType["BulkEdit"] = 2] = "BulkEdit";
    EditType[EditType["RowEdit"] = 3] = "RowEdit";
    EditType[EditType["DeleteRow"] = 4] = "DeleteRow";
    EditType[EditType["AddRow"] = 5] = "AddRow";
})(EditType || (EditType = {}));
export var ExportType;
(function (ExportType) {
    ExportType[ExportType["XLSX"] = 0] = "XLSX";
    ExportType[ExportType["CSV"] = 1] = "CSV";
})(ExportType || (ExportType = {}));
export var Operation;
(function (Operation) {
    Operation[Operation["None"] = 1] = "None";
    Operation[Operation["Add"] = 2] = "Add";
    Operation[Operation["Update"] = 3] = "Update";
    Operation[Operation["Delete"] = 4] = "Delete";
})(Operation || (Operation = {}));
export var SummaryReportColumnConfigurationData = [
    {
        key: 'shipmentReferenceNumber',
        text: 'Shipment Reference Number',
        editable: false,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true
    },
    {
        key: 'asnDate',
        text: 'ASN Date',
        editable: false,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'vendorName',
        text: 'Vendor Name',
        editable: true,
        dataType: 'number',
        minWidth: 250,
        maxWidth: 1000,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true
    },
    {
        key: 'poNumber',
        text: 'PO Number',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
        multipleValues: true
    },
    {
        key: 'airWayBillNo',
        text: 'Air Way Bill No',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'billToLocation',
        text: 'Bill To Location',
        editable: true,
        dataType: 'number',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'shipToLocation',
        text: 'Ship To Location',
        editable: true,
        dataType: 'string',
        minWidth: 250,
        maxWidth: 1000,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'proformaInvoiceNumber',
        text: 'Proforma Invoice Number',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
        multipleValues: true
    },
    {
        key: 'proformaInvoiceDate',
        text: 'Proforma Invoice Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
        multipleValues: true
    },
    {
        key: 'icApprovalAppliedDate',
        text: 'IC Approval Applied Date',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'icApprovalReceivedDate',
        text: 'ICApprovalReceivedDate',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'shipperSInvoiceNumber',
        text: "Shipper's Invoice Number",
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
        multipleValues: true
    },
    {
        key: 'shipperSInvoiceDate',
        text: "Shipper's Invoice Date",
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'incoTerm',
        text: 'Inco Term',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'icNo',
        text: 'IC No',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'icDate',
        text: 'IC Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'cgOpeningBalance',
        text: 'CG Opening Balance',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'cgClosingBalance',
        text: 'CG Closing Balance',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'pcApprovalAppliedDate',
        text: 'PCApprovalAppliedDate',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'pcApprovalReceivedDate',
        text: 'PC Approval Received Date',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'b17BondAmountExecuted',
        text: 'B17 Bond Amount Executed',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'bgAmountAndValidity',
        text: 'BG Validity',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'b17BondOpeningBalance',
        text: 'B17 Bond Opening Balance',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'percentage25OfDutyForgone',
        text: '25% of duty forgone',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'b17BondBalance',
        text: 'B17 Bond Balance',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'pcNo',
        text: 'PC No',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'pcDate',
        text: 'PC Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'nameOfFreightForwarder',
        text: 'Name of Freight Forwarder',
        editable: true,
        dataType: 'number',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'brokerName',
        text: 'Broker Name',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'mawbNo',
        text: 'MAWB No',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'mawbDate',
        text: 'MAWB Date',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'hawbBL',
        text: 'HAWB/BL No',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'hawbBlDate',
        text: 'HAWB/BL Date',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'coo',
        text: 'COO',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'portOfShipment',
        text: 'Port of Shipment',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'typeOfShipment',
        text: 'Type of Shipment',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'portOfEntryInIndia',
        text: 'Port of Entry in India',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'eta',
        text: 'ETA',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'grossWeightKgs',
        text: 'Gross Weight(kgs)',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'noOfPallets',
        text: 'No. of Pallets',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'invoiceQuantity',
        text: 'Invoice Quantity',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'invoiceValue',
        text: 'Invoice Value',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'miscellaneousCharges',
        text: 'Miscellaneous Charges',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'freightAsCalculated',
        text: 'Freight(as calculated)',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'insurance',
        text: 'Insurance',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'otherAdditions',
        text: 'Other Additions',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'checklistReceivedDate',
        text: 'ChecklistReceivedDate',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'checklistApprovalDate',
        text: 'Checklist Approval Date',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'exchangeRate',
        text: 'Exchange Rate',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'exchangeRateDate',
        text: 'Exchange Rate Date',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'customsValueINR',
        text: 'Customs Value INR',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'currency',
        text: 'Invoice Currency',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'basicCustomsDutyValue',
        text: 'Basic Customs Duty Value',
        editable: true,
        dataType: 'number',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'igstValue',
        text: 'IGST Value',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'socialWelfareSurchargeValue',
        text: 'Social Welfare Surcharge Value',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'splDutyValue',
        text: 'Spl.Duty value',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'cessValue',
        text: 'CESS Value',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'totalCustomsDutyINR',
        text: 'Total Customs Duty INR',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'totalDutyPayable',
        text: 'Total Duty Payable',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'billOfEntryNumber',
        text: 'Bill Of Entry Number',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'billOfEntryDate',
        text: 'Bill of Entry Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'customQueryReceivedDate',
        text: 'Custom Query Received Date',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'customQueryResolvedDate',
        text: 'Custom Query Resolved Date',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'dutyPaymentDate',
        text: 'Duty Payment Date',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'documentSharePointUploadDate',
        text: 'DocumentSharePointUploadDate',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'internalBondNumber',
        text: 'Internal Bond Number',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'reWarehousingDate',
        text: 'Re-Warehousing Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'dutyChallanNumber',
        text: 'Duty Challan Number',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'dutyChallanDate',
        text: 'Duty Challan Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'dutyAsPerChallanInr',
        text: 'Duty as per Challan INR',
        editable: true,
        dataType: 'number',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'disputedAmount',
        text: 'Disputed Amount',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'reasonForDispute',
        text: 'Reason for Dispute',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'interestAmount',
        text: 'Interest Amount',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'penaltyAmount',
        text: 'Penalty Amount',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'eddChallanNumber',
        text: 'EDD Challan Number',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'eddChallanDate',
        text: 'EDD Challan Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'edDepositInr',
        text: 'EDD Deposit INR',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'igmArrivalDate',
        text: 'IGM/Arrival Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'customsReleaseDate',
        text: 'Customs Release Date',
        editable: true,
        dataType: 'number',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'turnaroundTime',
        text: 'Turnaround Time',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'comments',
        text: 'Comments',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'delayReas',
        text: 'Delay Reason/Remarks',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'vehicleDetails',
        text: 'Vehicle Details',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'invoiceReceivedDate',
        text: 'Invoice Received Date',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'invoiceApprovalDate',
        text: 'InvoiceApprovalDate',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'generalDescription',
        text: 'General Description',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'preAlertReceivedDate',
        text: 'Pre-Alert Receive dDate',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'broker1stInvoiceReceivedDate',
        text: 'Broker 1st Invoice Received Date',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'broker1stInvoiceNo',
        text: 'Broker 1st InvoiceNo',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'broker1stInvoiceDate',
        text: 'Broker 1st Invoice Date',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'broker1stInvoiceAmount',
        text: 'Broker 1st Invoice Amount',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'broker1stInvoiceSubmissionDate',
        text: 'Broker 1st Invoice Submission Date',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'broker2ndInvoiceReceivedDate',
        text: 'Broker 2nd Invoice Received Date',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'broker2ndInvoiceNo',
        text: 'Broker 2nd Invoice No',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'broker2ndInvoiceDate',
        text: 'Broker 2nd Invoice Date',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'broker2ndInvoiceAmount',
        text: 'Broker 2nd Invoice Amount',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'broker2ndInvoiceSubmissionDate',
        text: 'Broker 2nd Invoice SubmissionDate',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
];
export var DetailReportColumnConfigurationData = [
    {
        key: 'shipmentReferenceNumber',
        text: 'Shipment Reference Number',
        editable: false,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true
    },
    {
        key: 'shipmentLineItemNumber',
        text: 'Shipment Line Item Number',
        editable: false,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true
    },
    {
        key: 'asnDate',
        text: 'ASN Date',
        editable: false,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'vendorCode',
        text: 'Vendor Code',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'vendorName',
        text: 'Vendor Name',
        editable: true,
        dataType: 'number',
        minWidth: 250,
        maxWidth: 1000,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true
    },
    {
        key: 'poNumber',
        text: 'PO Number',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'poItem',
        text: 'PO Item',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'airWayBillNo',
        text: 'Air Way Bill No',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'billToLocation',
        text: 'Bill To Location',
        editable: true,
        dataType: 'number',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'shipToLocation',
        text: 'Ship To Location',
        editable: true,
        dataType: 'string',
        minWidth: 250,
        maxWidth: 1000,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'buyerItemNo',
        text: 'Buyer Item No',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'partNumber',
        text: 'PART Number',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'shortDescription',
        text: 'Short Description',
        editable: true,
        dataType: 'string',
        minWidth: 250,
        maxWidth: 1000,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'poQuantity',
        text: 'PO Quantity',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'poUnitPrice',
        text: 'PO Unit Price',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'poValue',
        text: 'PO Value',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'poDocumentTypeDescription',
        text: 'PO Document Type(description)',
        editable: true,
        dataType: 'string',
        minWidth: 250,
        maxWidth: 300,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'proformaInvoiceNumber',
        text: 'Proforma Invoice Number',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'proformaInvoiceDate',
        text: 'Proforma Invoice Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'shipperSInvoiceNumber',
        text: "Shipper's Invoice Number",
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'shipperSInvoiceDate',
        text: "Shipper's Invoice Date",
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'shipperInvoiceDescription',
        text: "Shipper's Invoice Description",
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'icNo',
        text: 'IC No',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'icDate',
        text: 'IC Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'pcNo',
        text: 'PC No',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'pcDate',
        text: 'PC Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'nameOfFreightForwarder',
        text: 'Name of Freight Forwarder',
        editable: true,
        dataType: 'number',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'countryOfOrigin',
        text: 'Country of Origin',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'portOfShipment',
        text: 'Port of Shipment',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'mawbNo',
        text: 'MAWB No',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'mawbDate',
        text: 'MAWB Date',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'hawbBL',
        text: 'HAWB/BL No',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'hawbBlDate',
        text: 'HAWB/BL Date',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'typeOfShipment',
        text: 'Type of Shipment',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'portOfEntryInIndia',
        text: 'Port of Entry in India',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'eta',
        text: 'ETA',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'invoiceQty',
        text: 'Invoice Qty',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'invoiceUnitPrice',
        text: 'Invoice Unit Price',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'invoiceValue',
        text: 'Invoice Value',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'hsnCode',
        text: 'HSN Code',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'miscellaneousCharges',
        text: 'Miscellaneous Charges',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'freightAsCalculated',
        text: 'Freight(as calculated)',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'insurance',
        text: 'Insurance',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'otherAdditions',
        text: 'Other Additions',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'exchangeRateDate',
        text: 'Exchange Rate Date',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'exchangeRate',
        text: 'Exchange Rate',
        editable: true,
        dataType: 'string',
        minWidth: 70,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
    },
    {
        key: 'customsValueINR',
        text: 'Customs Value INR',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'currency',
        text: 'Invoice Currency',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'basicCustomsDutyRate',
        text: 'Basic Customs Duty Rate',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'igstRate',
        text: 'IGST Rate',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'ecess',
        text: 'Ecess',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'splDuty',
        text: 'Spl.Duty',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'cessRate',
        text: 'Cess Rate',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'totalDutyRate',
        text: 'Total Duty Rate',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'basicCustomsDutyValue',
        text: 'Basic Customs Duty Value',
        editable: true,
        dataType: 'number',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'igstValue',
        text: 'IGST Value',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'socialWelfareSurchargeValue',
        text: 'Social Welfare Surcharge Value',
        editable: true,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'splDutyValue',
        text: 'Spl.Duty value',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'cessValue',
        text: 'CESS Value',
        editable: true,
        dataType: 'string',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'totalCustomsDutyINR',
        text: 'Total Customs Duty INR',
        editable: true,
        dataType: 'number',
        minWidth: 100,
        maxWidth: 150,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'totalDutyPayable',
        text: 'Total Duty Payable',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'billOfEntryNumber',
        text: 'Bill Of Entry Number',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'billOfEntryDate',
        text: 'Bill of Entry Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'internalBondNumber',
        text: 'Internal Bond Number',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'reWarehousingDate',
        text: 'Re-Warehousing Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'dutyChallanNumber',
        text: 'Duty Challan Number',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'dutyChallanDate',
        text: 'Duty Challan Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'dutyAsPerChallanInr',
        text: 'Duty as per Challan INR',
        editable: true,
        dataType: 'number',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'eddChallanNumber',
        text: 'EDD Challan Number',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'eddChallanDate',
        text: 'EDD Challan Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'igmArrivalDate',
        text: 'IGM/Arrival Date',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
    {
        key: 'customsReleaseDate',
        text: 'Customs Release Date',
        editable: true,
        dataType: 'number',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false
    },
];
