import * as React from 'react';
import { Card, CardHeading, CardContent } from '@employee-experience/common/lib/Card';
import { useState, useEffect } from 'react';
import { Line } from 'react-chartjs-2';
import { addDays, addMonths } from 'office-ui-fabric-react/lib/utilities/dateMath/DateMath';
import { ComponentContext } from '@employee-experience/common/lib/Context/ComponentContext';
import '!style-loader!css-loader!react-toastify/dist/ReactToastify.css';
import moment from 'moment';
import { httpPost } from '../../shared/APICalls';
import { dateToISOLikeButLocal } from '../CommonUtilities/CommonFunctions';
var appConfig = require('../../config/appConfig.js').appConfig;
var baseUrl = appConfig.AppSettings.APIUrl;
var buildData = function (data) {
    var graphData = [];
    var graphLabels = [];
    for (var d in data) {
        graphData.push(data[d].numberOfShipments);
        graphLabels.push(moment(data[d].shipmentDate).format('MM/DD/YY'));
    }
    return [graphData, graphLabels];
};
var buildShipmentStatusData = function (data, status) {
    var graphData = [];
    for (var i = 0; i < data.length; i++) {
        var arr = data[i].status;
        var flag = false;
        for (var j = 0; j < arr.length; j++) {
            if (arr[j].shipmentsStatus == status) {
                graphData.push(arr[j].shipmentsCount);
                flag = true;
            }
        }
        if (flag == false)
            graphData.push(0);
    }
    return graphData;
};
function DateWiseDistributionCard1() {
    var context = React.useContext(ComponentContext);
    var _httpClient = context === null || context === void 0 ? void 0 : context.httpClient;
    var _a = useState([]), data = _a[0], setData = _a[1];
    var _b = useState([]), labels = _b[0], setLabels = _b[1];
    var _c = useState([]), ShipmentsInProgressData = _c[0], setShipmentsInProgressData = _c[1];
    var _d = useState([]), ShipmentsCompletedData = _d[0], setShipmentsCompletedData = _d[1];
    var _e = useState([]), ShipmentsNotStartedYetData = _e[0], setShipmentsNotStartedYetData = _e[1];
    useEffect(function () {
        var url = baseUrl + "/Dashboard/GetDashboardDatewiseShipmentsDetails";
        httpPost(_httpClient, url, function (response) {
            var data = buildData(response.data);
            setData(data[0]);
            setLabels(data[1]);
            setShipmentsInProgressData(buildShipmentStatusData(response.data, 'InProgress'));
            setShipmentsCompletedData(buildShipmentStatusData(response.data, 'Completed'));
            setShipmentsNotStartedYetData(buildShipmentStatusData(response.data, 'NotStartedYet'));
        }, function (error) {
            console.log(error);
        }, JSON.stringify({
            fromDate: dateToISOLikeButLocal(addMonths(new Date(Date.now()), -2)),
            toDate: dateToISOLikeButLocal(addDays(new Date(Date.now()), 0)),
        }));
    }, []);
    return (React.createElement(Card, null,
        React.createElement(CardHeading, null, "Shipments by Status"),
        React.createElement(CardContent, null,
            React.createElement("div", null,
                React.createElement(Line, { data: {
                        labels: labels,
                        datasets: [
                            {
                                label: 'Number Of Shipments',
                                fill: false,
                                lineTension: 0.5,
                                backgroundColor: '#ef9a9a',
                                borderColor: '#ef9a9a',
                                borderWidth: 2,
                                data: data,
                            },
                            {
                                label: 'In Progress',
                                fill: false,
                                lineTension: 0.5,
                                backgroundColor: '#ffeb3b',
                                borderColor: '#ffeb3b',
                                borderWidth: 2,
                                data: ShipmentsInProgressData,
                            },
                            {
                                label: 'Completed',
                                fill: false,
                                lineTension: 0.5,
                                backgroundColor: '#039be5',
                                borderColor: '#039be5',
                                borderWidth: 2,
                                data: ShipmentsCompletedData,
                            },
                            {
                                label: 'Not Started Yet',
                                fill: false,
                                lineTension: 0.5,
                                backgroundColor: '#4caf50',
                                borderColor: '#4caf50',
                                borderWidth: 2,
                                data: ShipmentsNotStartedYetData,
                            },
                        ],
                    }, width: 250, height: 250, options: {
                        title: {
                            display: false,
                            text: 'Shipments by Status',
                            fontSize: 20,
                        },
                        maintainAspectRatio: false,
                        legend: {
                            display: true,
                            position: 'bottom',
                        },
                    } })))));
}
export default DateWiseDistributionCard1;
