var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { withContext } from '@employee-experience/common/lib/withContext';
import * as Styled from '../../shared/layout';
import { ConstrainMode, DetailsListLayoutMode, Fabric, SelectionMode } from 'office-ui-fabric-react';
import { useEffect, useState } from 'react';
import EditableGrid from '../CommonUtilities/editable-grid/EditableGrid';
import { ASNCalcValuesColumnConfigurationData } from '../CommonUtilities/editable-grid/EditableGridConfig';
import { ComponentContext } from '@employee-experience/common';
import { trackPromise } from 'react-promise-tracker';
import { LoadingIndicator } from '../CommonUtilities/LoadingIndicator';
import { httpGet } from '../../shared/APICalls';
var appConfig = require('../../config/appConfig.js').appConfig;
var baseUrl = appConfig.AppSettings.APIUrl;
var PreClearence = function (props) {
    var context = React.useContext(ComponentContext);
    var _httpClient = context === null || context === void 0 ? void 0 : context.httpClient;
    var _a = useState(''), shipmentRefNumber = _a[0], setShipmentRefNumber = _a[1];
    var _b = useState([]), preClearanceCal = _b[0], setPreClearanceCal = _b[1];
    useEffect(function () {
        if (preClearanceCal.length > 0) {
            //CalculateColumnSummations(preClearanceCal);
            //ModifyPreClearanceValues(preClearanceCal);
        }
    }, [preClearanceCal]);
    var CalculateColumnSummations = function (data) {
        var freightSum = 0;
        var insuranceSum = 0;
        var miscSum = 0;
        var basicCustomsDutyValueSum = 0;
        var socialWelfareSurchargeValueSum = 0;
        var cessValueSum = 0;
        var splDutyvalueSum = 0;
        var igstValueSum = 0;
        var totalDutyINRSum = 0;
        var totalPayableSum = 0;
        var assValueINRSum = 0;
        var otherAddsSum = 0;
        data.forEach(function (item) {
            freightSum += Number(item.freight == null ? 0 : item.freight);
            insuranceSum += Number(item.insurance == null ? 0 : item.insurance);
            miscSum += Number(item.misc == null ? 0 : item.misc);
            basicCustomsDutyValueSum += Number(item.basicCustomsDutyValue == null ? 0 : item.basicCustomsDutyValue);
            socialWelfareSurchargeValueSum += Number(item.socialWelfareSurchargeValue == null ? 0 : item.socialWelfareSurchargeValue);
            cessValueSum += Number(item.cessValue == null ? 0 : item.cessValue);
            splDutyvalueSum += Number(item.splDutyvalue == null ? 0 : item.splDutyvalue);
            igstValueSum += Number(item.igstValue == null ? 0 : item.igstValue);
            totalDutyINRSum += Number(item.totalDutyINR == null ? 0 : item.totalDutyINR);
            totalPayableSum += Number(item.totalPayable == null ? 0 : item.totalPayable);
            assValueINRSum += Number(item.assValueINR == null ? 0 : item.assValueINR);
            otherAddsSum += Number(item.otherAdds == null ? 0 : item.otherAdds);
        });
        ASNCalcValuesColumnConfigurationData.filter(function (item) { return item.key == 'freight'; }).map(function (item) { return item.text = 'Freight (' + Math.round(freightSum * 100) / 100 + ')'; });
        ASNCalcValuesColumnConfigurationData.filter(function (item) { return item.key == 'insurance'; }).map(function (item) { return item.text = 'Insurance (' + Math.round(insuranceSum * 100) / 100 + ')'; });
        ASNCalcValuesColumnConfigurationData.filter(function (item) { return item.key == 'misc'; }).map(function (item) { return item.text = 'Misc (' + Math.round(miscSum * 100) / 100 + ')'; });
        ASNCalcValuesColumnConfigurationData.filter(function (item) { return item.key == 'basicCustomsDutyValue'; }).map(function (item) { return item.text = 'Basic Customs Duty Value (' + Math.round(basicCustomsDutyValueSum * 100) / 100 + ')'; });
        ASNCalcValuesColumnConfigurationData.filter(function (item) { return item.key == 'socialWelfareSurchargeValue'; }).map(function (item) { return item.text = 'Social Welfare Surcharge (' + Math.round(socialWelfareSurchargeValueSum * 100) / 100 + ')'; });
        ASNCalcValuesColumnConfigurationData.filter(function (item) { return item.key == 'cessValue'; }).map(function (item) { return item.text = 'Cess Value (' + Math.round(cessValueSum * 100) / 100 + ')'; });
        ASNCalcValuesColumnConfigurationData.filter(function (item) { return item.key == 'splDutyvalue'; }).map(function (item) { return item.text = 'Spl Duty Val (' + Math.round(splDutyvalueSum * 100) / 100 + ')'; });
        ASNCalcValuesColumnConfigurationData.filter(function (item) { return item.key == 'igstValue'; }).map(function (item) { return item.text = 'IGST Value (' + Math.round(igstValueSum * 100) / 100 + ')'; });
        ASNCalcValuesColumnConfigurationData.filter(function (item) { return item.key == 'totalDutyINR'; }).map(function (item) { return item.text = 'Total Duty INR (' + Math.round(totalDutyINRSum * 100) / 100 + ')'; });
        ASNCalcValuesColumnConfigurationData.filter(function (item) { return item.key == 'totalPayable'; }).map(function (item) { return item.text = 'Total Payable (' + Math.round(totalPayableSum * 100) / 100 + ')'; });
        ASNCalcValuesColumnConfigurationData.filter(function (item) { return item.key == 'assValueINR'; }).map(function (item) { return item.text = 'Assessable Value INR (' + Math.round(assValueINRSum * 100) / 100 + ')'; });
        ASNCalcValuesColumnConfigurationData.filter(function (item) { return item.key == 'otherAdds'; }).map(function (item) { return item.text = 'Other Additions (' + Math.round(otherAddsSum * 100) / 100 + ')'; });
        ModifyPreClearanceValues(data);
    };
    var ModifyPreClearanceValues = function (data) {
        debugger;
        data.map(function (item) { return item.splDutyvalue = (item.splDutyvalue != null ? item.splDutyvalue : '0') + ' (' + (item.splDuty != null ? item.splDuty : '0') + '%)'; });
        data.map(function (item) { return item.socialWelfareSurchargeValue = (item.socialWelfareSurchargeValue != null ? item.socialWelfareSurchargeValue : '0') + ' (' + (item.ecess != null ? item.ecess : '0') + '%)'; });
        data.map(function (item) { return item.basicCustomsDutyValue = (item.basicCustomsDutyValue != null ? item.basicCustomsDutyValue : '0') + ' (' + (item.basicCustomsDutyRate != null ? item.basicCustomsDutyRate : '0') + '%)'; });
        data.map(function (item) { return item.cessValue = (item.cessValue != null ? item.cessValue : '0') + ' (' + (item.cessRate != null ? item.cessRate : '0') + '%)'; });
        data.map(function (item) { return item.igstValue = (item.igstValue != null ? item.igstValue : '0') + ' (' + (item.igstRate != null ? item.igstRate : '0') + '%)'; });
        data.map(function (item) { return item.totalDutyINR = (item.totalDutyINR != null ? item.totalDutyINR : '0') + ' (' + (item.totalDutyRate != null ? item.totalDutyRate : '0') + '%)'; });
        setPreClearanceCal(data);
    };
    var FetchFormData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            url = baseUrl + "/PreClearance?shipmentRefNo=" + props.shipmentRefNumber;
            httpGet(_httpClient, url, function (response) {
                //ModifyPreClearanceValues(response.data);
                CalculateColumnSummations(response.data);
            }, function (error) {
                console.log(error);
            });
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        var split = location.pathname.split("/");
        setShipmentRefNumber(split[split.length - 1]);
    }, []);
    useEffect(function () {
        if (shipmentRefNumber != null && shipmentRefNumber != '') {
            trackPromise(FetchFormData());
        }
    }, [shipmentRefNumber]);
    return (React.createElement(Styled.listContainer, null,
        React.createElement("div", null,
            React.createElement(LoadingIndicator, null)),
        React.createElement(Fabric, null,
            React.createElement(EditableGrid, { enableExport: true, columnConfigurationData: ASNCalcValuesColumnConfigurationData, layoutMode: DetailsListLayoutMode.justified, selectionMode: SelectionMode.multiple, gridData: preClearanceCal, height: '55vh', position: 'relative', constrainMode: ConstrainMode.unconstrained }))));
};
var connected = withContext(PreClearence);
export { connected as PreClearence };
