var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import * as React from 'react';
import { useState, useEffect } from 'react';
import { DetailsList } from 'office-ui-fabric-react/lib/components/DetailsList/DetailsList';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { Selection, } from 'office-ui-fabric-react/lib/DetailsList';
import { MarqueeSelection } from 'office-ui-fabric-react/lib/MarqueeSelection';
import { IconButton } from 'office-ui-fabric-react/lib/components/Button/IconButton/IconButton';
import { useConstCallback } from '@uifabric/react-hooks';
import { ComponentContext } from '@employee-experience/common/lib/Context/ComponentContext';
import { PrimaryButton, Panel, PanelType, mergeStyleSets, Fabric, Dropdown, DialogFooter, Announced, Dialog, SpinButton, DatePicker, ScrollablePane, Sticky, StickyPositionType, TooltipHost } from 'office-ui-fabric-react';
import { TextField } from 'office-ui-fabric-react/lib/TextField';
// @ts-ignore
import { EventEmitter, EventType } from '../EventEmitter';
import EditPanel from './EditPanel';
import { EditType, ExportType, Operation } from './EditableGridConfig';
import { InputType } from '../generic-form/GenericFormConfig';
import { DayPickerStrings } from '../DatePickerConfigs';
import MessageDialog from './MessageDialog';
import ColumnUpdateDialog from './ColumnUpdateDialog';
import { ExportToCSVUtil, ExportToExcelUtil } from '../ExcelExport';
import { dropdownStyles } from '../../Reports/IndiaImportsReportsStyles';
var EditableGrid = function (props) {
    var context = React.useContext(ComponentContext);
    var _selection;
    var _a = React.useState(false), showTextBoxInGrid = _a[0], setShowTextBoxInGrid = _a[1];
    var _b = React.useState(false), isOpenForEdit = _b[0], setIsOpenForEdit = _b[1];
    var _c = React.useState(false), isUpdateColumnClicked = _c[0], setIsUpdateColumnClicked = _c[1];
    var dismissPanelForEdit = useConstCallback(function () { return setIsOpenForEdit(false); });
    var _d = useState(EditType.None), editType = _d[0], setEditType = _d[1];
    var _e = useState({}), rowValuesInPanel = _e[0], setRowValuesInPanel = _e[1];
    var _f = useState([]), items = _f[0], setItems = _f[1];
    var _g = useState([]), columns = _g[0], setColumns = _g[1];
    var _h = useState([]), gridData = _h[0], setGridData = _h[1];
    var _j = useState([]), defaultGridData = _j[0], setDefaultGridData = _j[1];
    var _k = useState([]), defaultGridDataWithState = _k[0], setDefaultGridDataWithState = _k[1];
    var _l = useState([]), activateCellEdit = _l[0], setActivateCellEdit = _l[1];
    var _m = React.useState(undefined), dialogContent = _m[0], setDialogContent = _m[1];
    var _o = React.useState(false), isGridInEdit = _o[0], setIsGridInEdit = _o[1];
    var _p = React.useState({
        visible: false,
        message: '',
        subMessage: ''
    }), messageDialogProps = _p[0], setMessageDialogProps = _p[1];
    var _q = useState(false), isGridStateEdited = _q[0], setIsGridStateEdited = _q[1];
    var _r = React.useState(undefined), announced = _r[0], setAnnounced = _r[1];
    var SpinRef = React.createRef();
    function dateToISOLikeButLocal(date) {
        var offsetMs = date.getTimezoneOffset() * 60 * 1000;
        var msLocal = date.getTime() - offsetMs;
        var dateLocal = new Date(msLocal);
        var iso = dateLocal.toISOString();
        var isoLocal = iso.slice(0, 10);
        return isoLocal;
    }
    var getExportableData = function () {
        var exportableColumns = props.columnConfigurationData.filter(function (x) { return x.includeColumnInExport == true; });
        var exportableData = [];
        var exportableObj = {};
        gridData.forEach(function (item1, index1) {
            exportableColumns.forEach(function (item2, index2) {
                exportableObj[item2.text] = item1[item2.key];
            });
            exportableData.push(exportableObj);
            exportableObj = {};
        });
        return exportableData;
    };
    var ExportToCSV = function (dataRows, fileName) {
        if (!props.onExcelExport) {
            ExportToCSVUtil(dataRows, fileName);
        }
        else {
            props.onExcelExport(ExportType.CSV);
        }
    };
    var ExportToExcel = function (dataRows, fileName) {
        if (!props.onExcelExport) {
            ExportToExcelUtil(dataRows, fileName);
        }
        else {
            props.onExcelExport(ExportType.XLSX);
        }
    };
    var onExportClick = function (type) {
        var _a;
        var fileName = (_a = props.exportFileName) !== null && _a !== void 0 ? _a : 'ExcelExport';
        switch (type) {
            case ExportType.XLSX:
                ExportToExcel(getExportableData(), fileName + '.xlsx');
                break;
            case ExportType.CSV:
                ExportToCSV(getExportableData(), fileName + '.csv');
                break;
        }
    };
    var controlClass = mergeStyleSets({
        control: {
            marginBottom: '10px',
            marginRight: '30px',
            maxWidth: '300px',
        },
        searchStyles: {
            marginTop: '5px',
        },
        submitStyles: {
            marginTop: '20px',
            marginLeft: '10px',
        },
        buttonStyles: {
            margin: 5
        },
        textFieldClass: {
            display: 'block',
            margin: 10,
        },
        spanStyles: {
            display: 'inline-block',
            width: '100%',
            height: '100%'
        },
        dialogSubMessageStyles: {
            margin: 10,
        },
        dialogHeaderStyles: {
            margin: 10,
        },
        multilineTextStyles: {
            display: 'inline-block',
            width: '100%',
            height: '100%',
            whiteSpace: 'pre-wrap',
            textAlign: 'left'
        }
    });
    var onEditPanelChange = function (item) {
        dismissPanelForEdit();
        updateGridBulkData(item);
    };
    var onCellDateChange = function (date, item1, row, column) {
        debugger;
        setGridEditState(true);
        var activateCellEditTmp = [];
        activateCellEdit.forEach(function (item, index) {
            if (row == index) {
                item.properties[column.key].value = dateToISOLikeButLocal(date);
            }
            activateCellEditTmp.push(item);
        });
        var rowObj = {};
        for (var key in activateCellEditTmp[row].properties) {
            rowObj[key] = activateCellEditTmp[row].properties[key].value;
        }
        checkFieldUpdateCallback(column, rowObj, row, column === null || column === void 0 ? void 0 : column.key, true);
        setActivateCellEdit(activateCellEditTmp);
    };
    var onCellDropdownChange = function (event, item1, row, column) {
        setGridEditState(true);
        var activateCellEditTmp = [];
        activateCellEdit.forEach(function (item, index) {
            if (row == index) {
                item.properties[column.key].value = item1.key;
            }
            activateCellEditTmp.push(item);
        });
        var rowObj = {};
        for (var key in activateCellEditTmp[row].properties) {
            rowObj[key] = activateCellEditTmp[row].properties[key].value;
        }
        checkFieldUpdateCallback(column, rowObj, row, column === null || column === void 0 ? void 0 : column.key, true);
        setActivateCellEdit(activateCellEditTmp);
    };
    var UpdateRowVals = function (rowProps, row, changeSourceKey, activateCurrentCell) {
        var objectKeys = Object.keys(rowProps);
        objectKeys.forEach(function (objKey) {
            editCellValue(objKey, row, true);
            onCellValueChange(null, rowProps[objKey], null, row, objKey, null);
            editCellValue(changeSourceKey, row, activateCurrentCell);
            if (showTextBoxInGrid) {
                debugger;
                showEditMode(!showTextBoxInGrid);
            }
            else if (activateCellEdit[row].isActivated) {
                showRowEditMode(row, true);
            }
        });
    };
    var checkFieldUpdateCallback = function (column, item, row, key, activateCurrentCell) {
        if (column && column.onChange) {
            column.onChange(item, { 'callback': UpdateRowVals, 'rowNum': row, 'changeSourceKey': key, 'activateCurrentCell': activateCurrentCell });
        }
    };
    var setGridEditState = function (editState) {
        if (isGridStateEdited != editState) {
            setIsGridStateEdited(editState);
        }
    };
    var onCellValueChange = function (ev, text, item, row, key, column) {
        debugger;
        var activateCellEditTmp = [];
        setGridEditState(true);
        activateCellEdit.forEach(function (item, index) {
            if (row == index) {
                item.properties[key].value = text;
            }
            activateCellEditTmp.push(item);
        });
        var rowObj = {};
        for (var key in activateCellEditTmp[row].properties) {
            rowObj[key] = activateCellEditTmp[row].properties[key].value;
        }
        checkFieldUpdateCallback(column, rowObj, row, column === null || column === void 0 ? void 0 : column.key, true);
        setActivateCellEdit(activateCellEditTmp);
    };
    var onKeyDownEvent = function (event, column, rowNum, activateCurrentCell) {
        console.log(event);
        if (event.key == "Enter") {
            if (!activateCellEdit[rowNum].isActivated) {
                editCellValue(column.key, rowNum, activateCurrentCell);
                event.preventDefault();
            }
        }
    };
    var onDoubleClickEvent = function (key, rowNum, activateCurrentCell) {
        editCellValue(key, rowNum, activateCurrentCell);
    };
    var updateGridColumnData = function (data) {
        updateBulkData(data);
        closeColumnUpdateDialog();
    };
    var propogateGridEdit = function (gridData) {
        if (props.onGridUpdate) {
            props.onGridUpdate(gridData);
        }
    };
    var getDefaultRowObject = function (rowCount) {
        var obj = {};
        var exisitingRowObj = {};
        var addedRows = [];
        var _new_grid_row_id_ = Math.max.apply(Math, defaultGridData.map(function (o) { return o._grid_row_id_; }));
        if (defaultGridData && defaultGridData.length > 0) {
            exisitingRowObj = defaultGridData[0];
        }
        else {
            props.columnConfigurationData.forEach(function (item, index) {
                exisitingRowObj[item.key] = '';
            });
        }
        var objectKeys = Object.keys(exisitingRowObj);
        for (var i = 1; i <= rowCount; i++) {
            obj = {};
            objectKeys.forEach(function (item, index) {
                //obj[item] = 'NEW';
                obj[item] = '';
            });
            obj._grid_row_id_ = ++_new_grid_row_id_;
            obj._grid_row_operation_ = Operation.Add;
            addedRows.push(obj);
        }
        return addedRows;
    };
    var closeRenameDialog = React.useCallback(function () {
        setDialogContent(undefined);
    }, []);
    var closeMessageDialog = function () {
        setMessageDialogProps({
            visible: false,
            message: '',
            subMessage: ''
        });
    };
    var closeColumnUpdateDialog = function () {
        debugger;
        setIsUpdateColumnClicked(false);
    };
    var showMessageDialog = function (message, subMessage) {
        setMessageDialogProps({
            visible: true,
            message: message,
            subMessage: subMessage
        });
    };
    var addRowsToGrid = function () {
        var updateItemName = function () {
            if (SpinRef && SpinRef.current.value) {
                setDialogContent(undefined);
                setAnnounced(React.createElement(Announced, { message: "Rows Added", "aria-live": "assertive" }));
                var rowCount_1 = parseInt(SpinRef.current.value, 10);
                console.log(rowCount_1);
                setGridEditState(true);
                setDefaultGridData(function (defaultGridData) { return __spreadArrays(defaultGridData, getDefaultRowObject(rowCount_1)); });
            }
        };
        setDialogContent(React.createElement(React.Fragment, null,
            React.createElement(SpinButton, { componentRef: SpinRef, defaultValue: "0", label: 'Row Count:', min: 0, max: 100, step: 1, incrementButtonAriaLabel: 'Increase value by 1', decrementButtonAriaLabel: 'Decrease value by 1' }),
            React.createElement(DialogFooter, null,
                React.createElement(PrimaryButton
                // eslint-disable-next-line react/jsx-no-bind
                , { 
                    // eslint-disable-next-line react/jsx-no-bind
                    onClick: updateItemName, text: "Save" }))));
    };
    var deleteSelectedRows = function () {
        debugger;
        setGridEditState(true);
        var defaultGridDataTmp = [];
        var selected_grid_row_ids_ = [];
        defaultGridData.filter(function (x) { return x._grid_row_operation_ == Operation.Delete; }).forEach(function (item, index) {
            defaultGridDataTmp.push(item);
        });
        defaultGridData.filter(function (x) { return x._grid_row_operation_ != Operation.Delete; }).forEach(function (item, index) {
            if (!(selectedIndices.indexOf(index) != -1 && item._grid_row_operation_ == Operation.Add)) {
                if (selectedIndices.indexOf(index) != -1) {
                    item._grid_row_operation_ = Operation.Delete;
                }
                defaultGridDataTmp.push(item);
            }
        });
        //propogateGridEdit(defaultGridDataTmp);
        setDefaultGridData(defaultGridDataTmp);
    };
    var updateBulkData = function (data) {
        setGridEditState(true);
        var newDefaultGridData = __spreadArrays(defaultGridData);
        var callbackRowsData = [];
        newDefaultGridData.filter(function (item) { return item._grid_row_operation_ != Operation.Delete; }).forEach(function (item, index) {
            if (selectedIndices.indexOf(index) != -1) {
                var objectKeys = Object.keys(data);
                objectKeys.forEach(function (objKey) {
                    item[objKey] = data[objKey];
                    if (item._grid_row_operation_ != Operation.Add) {
                        item._grid_row_operation_ = Operation.Update;
                    }
                    callbackRowsData.push({ 'item': item, 'index': index });
                });
            }
            //newDefaultGridData.push(item);
        });
        var columns = [];
        for (var key in data) {
            columns.push(props.columnConfigurationData.filter(function (item) { return item.key == key; })[0]);
        }
        columns.forEach(function (column) {
            callbackRowsData.forEach(function (row) {
                checkFieldUpdateCallback(column, row.item, row.index, column === null || column === void 0 ? void 0 : column.key, false);
            });
        });
        setDefaultGridData(newDefaultGridData);
    };
    var updateGridBulkData = function (data) {
        updateBulkData(data);
        //propogateGridEdit(defaultGridData);
    };
    var SaveGridCellValue = function () {
        var activateCellEditTmp = [];
        activateCellEditTmp = __spreadArrays(activateCellEdit);
        activateCellEditTmp.forEach(function (item, index) {
            var objectKeys = Object.keys(item.properties);
            objectKeys.forEach(function (objKey) {
                if (item.properties[objKey].activated == true) {
                    defaultGridData.filter(function (item) { return item._grid_row_operation_ != Operation.Delete; }).forEach(function (row, index2) {
                        if (index2 == index) {
                            row[objKey] = item.properties[objKey].value;
                            if (row._grid_row_operation_ != Operation.Add) {
                                row._grid_row_operation_ = Operation.Update;
                            }
                        }
                    });
                    // setDefaultGridData(defaultGridData);
                    // //propogateGridEdit(defaultGridData);
                }
                item.properties[objKey].activated = false;
            });
        });
        setDefaultGridData(defaultGridData);
        //propogateGridEdit(defaultGridData);
        return activateCellEditTmp;
    };
    var editCellValue = function (key, rowNum, activateCurrentCell) {
        setIsGridInEdit(activateCurrentCell);
        var activateCellEditTmp = SaveGridCellValue();
        console.log("activateCellEditTmp");
        console.log(activateCellEditTmp);
        activateCellEditTmp[rowNum]['properties'][key]['activated'] = activateCurrentCell;
        setActivateCellEdit(activateCellEditTmp);
    };
    var changeEditModeForRow = function (rowNum, enableTextField, deactivateOtherRows) {
        var activateCellEditTmp = [];
        activateCellEdit.forEach(function (item, index) {
            if (index == rowNum) {
                var objectKeys = Object.keys(item.properties);
                objectKeys.forEach(function (objKey) {
                    var colConfigProps = props.columnConfigurationData.filter(function (x) { return x.key == objKey; });
                    if (colConfigProps && colConfigProps.length > 0 && colConfigProps[0].editable) {
                        item.properties[objKey].activated = enableTextField;
                    }
                });
                item.isActivated = enableTextField;
            }
            else if (deactivateOtherRows && enableTextField) {
                item.isActivated = !enableTextField;
            }
            activateCellEditTmp.push(item);
        });
        setActivateCellEdit(activateCellEditTmp);
    };
    var showRowEditMode = function (rowNum, enableTextField) {
        //if(!enableTextField){
        SaveGridCellValue();
        //}
        changeEditModeForRow(rowNum, enableTextField, true);
        setIsGridInEdit(enableTextField);
    };
    var showColumnUpdate = function () {
        setIsUpdateColumnClicked(function (s) { return !s; });
    };
    var showGridEditMode = function () {
        showEditMode(showTextBoxInGrid);
        setShowTextBoxInGrid(function (s) { return !s; });
    };
    var showEditMode = function (isGridEditModeActivated) {
        if (isGridEditModeActivated) {
            SaveGridCellValue();
        }
        //else{
        activateCellEdit.forEach(function (item, index) {
            changeEditModeForRow(index, !isGridEditModeActivated, false);
        });
        //}
    };
    useEffect(function () {
        //alert('Is Grid In Edit: ' + isGridInEdit);
    }, [isGridInEdit]);
    useEffect(function () {
        setIsGridInEdit(showTextBoxInGrid);
    }, [showTextBoxInGrid]);
    var updateValueForSelectedRows = function (type, item) {
        switch (type) {
            case EditType.ColumnEdit:
                setEditType(EditType.ColumnEdit);
                debugger;
                if (selectedIndices.length > 0) {
                    showColumnUpdate();
                }
                else {
                    showMessageDialog('No Rows Selected', 'Please select some rows to perform this operation');
                    return false;
                }
                break;
            case EditType.BulkEdit:
                setEditType(EditType.BulkEdit);
                if (selectedIndices.length > 0) {
                    setIsOpenForEdit(true);
                }
                else {
                    showMessageDialog('No Rows Selected', 'Please select some rows to perform this operation');
                }
                break;
            case EditType.RowEdit:
                setEditType(EditType.RowEdit);
                setRowValuesInPanel(item);
                setIsOpenForEdit(true);
                break;
            case EditType.AddRow:
                addRowsToGrid();
                //toggleHideDialog;
                break;
            case EditType.DeleteRow:
                if (selectedIndices.length > 0) {
                    deleteSelectedRows();
                }
                else {
                    showMessageDialog('No Rows Selected', 'Please select some rows to perform this operation');
                }
                break;
        }
        return true;
    };
    var onGridSave = function () {
        if (props.onGridSave) {
            props.onGridSave(defaultGridData);
        }
        // setGridEditState(false);
    };
    var createCommandBarFarItemProps = function () {
        var commandBarItems = [];
        var tooltipText = isGridStateEdited ? 'Grid has unsaved data. Click on \'Submit\' to save' : '';
        if (props.enableUnsavedEditIndicator && (props.enableRowEdit || props.enableCellEdit || props.enableBulkEdit || props.enableColumnEdit
            || props.enableTextFieldEditMode)) {
            commandBarItems.push({
                key: 'info',
                text: tooltipText,
                // This needs an ariaLabel since it's icon-only
                ariaLabel: 'Info ' + tooltipText,
                disabled: !isGridStateEdited,
                iconOnly: true,
                iconProps: { iconName: 'InfoSolid' },
            });
        }
        return commandBarItems;
    };
    var createCommandBarItemProps = function () {
        var commandBarItems = [];
        if (props.enableExport == true) {
            commandBarItems.push({
                key: 'exportGrid',
                text: 'Export',
                ariaLabel: 'Export',
                disabled: isGridInEdit || showTextBoxInGrid,
                cacheKey: 'myCacheKey',
                iconProps: { iconName: 'Download' },
                subMenuProps: {
                    items: [
                        {
                            key: 'exportToExcel',
                            text: 'Excel Export',
                            iconProps: { iconName: 'Excel' },
                            onClick: function () { return onExportClick(ExportType.XLSX); }
                        },
                        {
                            key: 'exportToCSV',
                            text: 'CSV Export',
                            iconProps: { iconName: 'CSV' },
                            onClick: function () { return onExportClick(ExportType.CSV); }
                        }
                    ],
                }
            });
        }
        if (props.enableSave == true) {
            commandBarItems.push({
                key: 'submit',
                text: 'Submit',
                ariaLabel: 'Submit',
                disabled: isGridInEdit,
                iconProps: { iconName: 'Save' },
                onClick: function () { return onGridSave(); },
            });
        }
        if (props.enableTextFieldEditMode) {
            commandBarItems.push({
                key: 'editmode',
                disabled: isGridInEdit && !showTextBoxInGrid,
                text: !showTextBoxInGrid ? "Edit Mode" : "Save Edits",
                iconProps: { iconName: !showTextBoxInGrid ? "Edit" : "Save" },
                onClick: function () { return showGridEditMode(); }
            });
        }
        if (props.enableBulkEdit) {
            commandBarItems.push({
                key: 'bulkedit',
                text: "Bulk Edit",
                disabled: isGridInEdit || showTextBoxInGrid,
                iconProps: { iconName: "TripleColumnEdit" },
                onClick: function () { return updateValueForSelectedRows(EditType.BulkEdit, {}); }
            });
        }
        if (props.enableGridRowsAdd) {
            commandBarItems.push({
                key: 'addrows',
                text: "Add Rows",
                disabled: isGridInEdit || showTextBoxInGrid,
                iconProps: { iconName: "AddTo" },
                onClick: function () { return updateValueForSelectedRows(EditType.AddRow, {}); }
            });
        }
        if (props.enableGridRowsDelete) {
            commandBarItems.push({
                key: 'deleterows',
                text: "Delete Rows",
                disabled: isGridInEdit || showTextBoxInGrid,
                iconProps: { iconName: "DeleteRows" },
                onClick: function () { return updateValueForSelectedRows(EditType.DeleteRow, {}); }
            });
        }
        if (props.enableColumnEdit) {
            commandBarItems.push({
                key: 'updatecolumn',
                disabled: isGridInEdit || showTextBoxInGrid,
                text: !isUpdateColumnClicked ? "Update Column" : "Save Column Update",
                iconProps: { iconName: "SingleColumnEdit" },
                onClick: function () { return updateValueForSelectedRows(EditType.ColumnEdit, {}); }
            });
        }
        return commandBarItems;
    };
    var _farItems = createCommandBarFarItemProps();
    var _items = createCommandBarItemProps();
    var textFieldStyles = { fieldGroup: {} };
    var _s = useState(), selectedItems = _s[0], setSelectedItems = _s[1];
    var _t = useState(0), selectionCount = _t[0], setSelectionCount = _t[1];
    var _u = useState([]), selectedIndices = _u[0], setSelectedIndices = _u[1];
    var _v = useState(''), selectionDetails = _v[0], setSelectionDetails = _v[1];
    _selection = new Selection({
        onSelectionChanged: function () { return setSelectionDetails(_getSelectionDetails()); },
    });
    function _getSelectionDetails() {
        var count = _selection.getSelectedCount();
        setSelectionCount(count);
        setSelectedItems(_selection.getSelection());
        setSelectedIndices(_selection.getSelectedIndices());
        if (props.onGridSelectionChange) {
            props.onGridSelectionChange(_selection.getSelection());
        }
        switch (count) {
            case 0:
                return 'No items selected';
            case 1:
                return '1 item selected: ';
            default:
                return count + " items selected";
        }
    }
    var onSearchHandler = function (event) {
        if (event && event.target) {
            var queryText_1 = event.target.value;
            if (queryText_1) {
                var searchableColumns_1 = props.columnConfigurationData.filter(function (x) { return x.includeColumnInSearch == true; }).map(function (x) { return x.key; });
                var searchResult_1 = [];
                gridData.filter(function (_gridData) {
                    var BreakException = {};
                    try {
                        searchableColumns_1.forEach(function (item2, index2) {
                            if (_gridData[item2] && _gridData[item2].toString().toLowerCase() && _gridData[item2].toString().toLowerCase().includes(queryText_1.trim().toLowerCase())) {
                                searchResult_1.push(_gridData);
                                throw BreakException;
                            }
                        });
                    }
                    catch (e) {
                        // if (e !== BreakException) throw e;
                    }
                });
                setDefaultGridData(searchResult_1);
            }
            else {
                setDefaultGridData(gridData);
            }
        }
        else {
            setDefaultGridData(gridData);
        }
    };
    var handleColumnClick = function (ev, column) {
        debugger;
        var newColumns = columns.slice();
        var currColumn = newColumns.filter(function (currCol) { return column.key === currCol.key; })[0];
        newColumns.forEach(function (newCol) {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            }
            else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        debugger;
        var newItems = _copyAndSort(items, currColumn.fieldName, currColumn.isSortedDescending);
        setColumns(newColumns);
        setGridData(newItems);
        setDefaultGridData(newItems);
    };
    var createColumnConfigs = function () {
        var columnConfigs = [];
        var i = 1;
        var j = 0;
        props.columnConfigurationData.forEach(function (column, index) {
            columnConfigs.push({
                key: 'col' + i,
                name: column.text,
                ariaLabel: column.text,
                fieldName: column.key,
                isResizable: true,
                minWidth: column.minWidth,
                maxWidth: column.maxWidth,
                //data: item.dataType,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onRender: function (item, rowNum) {
                    switch (column.inputType) {
                        case InputType.MultilineTextField:
                            return React.createElement("span", { tabIndex: ++j }, ((!showTextBoxInGrid || !column.editable) && !(activateCellEdit && activateCellEdit[rowNum] && activateCellEdit[rowNum]['properties'][column.key] && activateCellEdit[rowNum]['properties'][column.key].activated))
                                ?
                                    React.createElement("span", { className: controlClass.multilineTextStyles, onDoubleClick: function () { return (props.enableCellEdit == true && column.editable == true)
                                            ? editCellValue(column.key, rowNum, true)
                                            : null; } }, item[column.key])
                                :
                                    React.createElement(TextField, { label: item.text, ariaLabel: "Value", multiline: true, autoAdjustHeight: true, rows: 1, styles: textFieldStyles, onChange: function (ev, text) { return onCellValueChange(ev, text, item, rowNum, column.key, column); }, defaultValue: item[column.key], 
                                        //onKeyDown={(event) => onKeyDownEvent(event, column.key, rowNum, false)}
                                        onDoubleClick: function () { return !activateCellEdit[rowNum].isActivated ? onDoubleClickEvent(column.key, rowNum, false) : null; }, maxLength: column.maxLength != null ? column.maxLength : 10000 }));
                            break;
                        case InputType.Date:
                            return React.createElement("span", { tabIndex: ++j }, ((!showTextBoxInGrid || !column.editable) && !(activateCellEdit && activateCellEdit[rowNum] && activateCellEdit[rowNum]['properties'][column.key] && activateCellEdit[rowNum]['properties'][column.key].activated))
                                ?
                                    React.createElement("span", { className: controlClass.spanStyles, onDoubleClick: function () { return (props.enableCellEdit == true && column.editable == true)
                                            ? editCellValue(column.key, rowNum, true)
                                            : null; } }, item && item[column.key] ? (new Date(item[column.key])).toDateString() : null)
                                :
                                    React.createElement(DatePicker, { strings: DayPickerStrings, placeholder: "Select a date...", ariaLabel: "Select a date", value: new Date(activateCellEdit[rowNum].properties[column.key].value), 
                                        //value={new Date(item[column.key])}
                                        //disabled={!column.editable}
                                        onSelectDate: function (date) { return onCellDateChange(date, item, rowNum, column); }, onDoubleClick: function () { return !activateCellEdit[rowNum].isActivated ? onDoubleClickEvent(column.key, rowNum, false) : null; }, allowTextInput: true }));
                            break;
                        case InputType.DropDown:
                            return React.createElement("span", { tabIndex: ++j }, ((!showTextBoxInGrid || !column.editable) && !(activateCellEdit && activateCellEdit[rowNum] && activateCellEdit[rowNum]['properties'][column.key] && activateCellEdit[rowNum]['properties'][column.key].activated))
                                ?
                                    React.createElement("span", { className: controlClass.multilineTextStyles, onDoubleClick: function () { return (props.enableCellEdit == true && column.editable == true)
                                            ? editCellValue(column.key, rowNum, true)
                                            : null; } }, item[column.key] != null && column.dropdownValues != null ? column.dropdownValues.filter(function (obj) { return obj.key == item[column.key]; })[0].text : "")
                                :
                                    React.createElement(Dropdown, { ariaLabel: "Select from the dropdown", placeholder: item[column.key] == null ? 'Select from the dropdown' : column.dropdownValues.filter(function (obj) { return obj.key == item[column.key]; })[0].text, options: column.dropdownValues, styles: dropdownStyles, onDoubleClick: function () { return !activateCellEdit[rowNum].isActivated ? onDoubleClickEvent(column.key, rowNum, false) : null; }, onChange: function (ev, item) { return onCellDropdownChange(ev, item, rowNum, column); } }));
                            break;
                        default:
                            return React.createElement("span", { tabIndex: ++j }, ((!showTextBoxInGrid || !column.editable) && !(activateCellEdit && activateCellEdit[rowNum] && activateCellEdit[rowNum]['properties'][column.key] && activateCellEdit[rowNum]['properties'][column.key].activated))
                                ?
                                    React.createElement("span", { className: controlClass.spanStyles, onDoubleClick: function () { return (props.enableCellEdit == true && column.editable == true)
                                            ? editCellValue(column.key, rowNum, true)
                                            : null; } }, item[column.key])
                                :
                                    React.createElement(TextField, { label: item.text, ariaLabel: "Value", styles: textFieldStyles, onChange: function (ev, text) { return onCellValueChange(ev, text, item, rowNum, column.key, column); }, defaultValue: item[column.key], onKeyDown: function (event) { return onKeyDownEvent(event, column, rowNum, false); }, maxLength: column.maxLength != null ? column.maxLength : 1000 }));
                            break;
                    }
                },
            });
            i++;
        });
        if (props.enableRowEdit) {
            columnConfigs.push({
                key: 'action',
                name: 'Actions',
                ariaLabel: 'Actions',
                fieldName: 'action',
                isResizable: true,
                minWidth: 50,
                maxWidth: 250,
                onRender: function (item, index) { return (React.createElement("div", null, (activateCellEdit && activateCellEdit[index] && activateCellEdit[index]['isActivated'])
                    ?
                        React.createElement(IconButton, { disabled: showTextBoxInGrid, onClick: function () { return showRowEditMode(index, false); }, iconProps: { iconName: 'Save' }, title: 'Save' })
                    :
                        React.createElement(IconButton, { onClick: function () { return showRowEditMode(index, true); }, iconProps: { iconName: 'Edit' }, title: 'Edit' }))); },
            });
        }
        return columnConfigs;
    };
    var gridColumns = createColumnConfigs();
    useEffect(function () {
        //alert('Updated');
        console.log(defaultGridData);
        var activateCellEditTmp = [];
        defaultGridData.forEach(function (item, index) {
            var activateCellEditRowTmp = { 'isActivated': false, properties: {} };
            var objectKeys = Object.keys(item);
            objectKeys.forEach(function (objKey) {
                activateCellEditRowTmp.properties[objKey] = { 'activated': false, 'value': item[objKey] };
            });
            activateCellEditTmp.push(activateCellEditRowTmp);
        });
        console.log(activateCellEditTmp);
        setActivateCellEdit(activateCellEditTmp);
        setIsGridInEdit(false);
    }, [defaultGridData]);
    useEffect(function () {
        var user = {
            fromDate: '2020-06-15',
            toDate: '2020-08-06',
            companyCode: '1190',
            loggedInUser: 'seachana',
        };
        if (props && props.gridData && props.gridData.length > 0) {
            //let a = props.gridData.map((obj, index) => {obj._grid_row_id_ = index; return obj;});
            //console.log(a);
            setGridEditState(false);
            setGridData(props.gridData.map(function (obj, index) {
                obj._grid_row_id_ = index;
                obj._grid_row_operation_ = Operation.None;
                return obj;
            }));
            setDefaultGridData(props.gridData.map(function (obj, index) {
                obj._grid_row_id_ = index;
                obj._grid_row_operation_ = Operation.None;
                return obj;
            }));
            //setGridData(props.gridData);
            //setDefaultGridData(props.gridData);
            setColumns(gridColumns);
            debugger;
            console.log('new');
            // console.log(gridColumns.filter((x) => x[_grid_row_operation_] != Operation.Delete));
        }
    }, [props]);
    function _copyAndSort(item, columnKey, isSortedDescending) {
        var key = columnKey;
        return item.slice(0).sort(function (a, b) { return ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1); });
    }
    var onRenderDetailsHeader = function (props, defaultRender) {
        if (!props) {
            return null;
        }
        var onRenderColumnHeaderTooltip = function (tooltipHostProps) { return (React.createElement(TooltipHost, __assign({}, tooltipHostProps))); };
        return (React.createElement(Sticky, { stickyPosition: StickyPositionType.Header, isScrollSynced: true }, defaultRender(__assign(__assign({}, props), { onRenderColumnHeaderTooltip: onRenderColumnHeaderTooltip }))));
    };
    React.useEffect(function () {
        EventEmitter.subscribe(EventType.onSearch, onSearchHandler);
        return function cleanup() {
            EventEmitter.unsubscribe(EventType.onSearch, onSearchHandler);
        };
    });
    React.useEffect(function () {
        EventEmitter.subscribe(EventType.onClick, onSearchHandler);
        return function cleanup() {
            EventEmitter.unsubscribe(EventType.onClick, onSearchHandler);
        };
    });
    return (React.createElement(Fabric, null,
        React.createElement(Panel, { isOpen: isOpenForEdit, onDismiss: dismissPanelForEdit, isLightDismiss: true, headerText: "Edit Grid Data", closeButtonAriaLabel: "Close", type: PanelType.smallFixedFar },
            React.createElement(EditPanel, { onChange: onEditPanelChange, columnConfigurationData: props.columnConfigurationData, panelValues: rowValuesInPanel, editType: editType })),
        props.enableExport || props.enableSave ?
            React.createElement(CommandBar, { items: _items, ariaLabel: "Command Bar", farItems: _farItems }) : null,
        props.height && props.position
            ?
                // <div >
                React.createElement(ScrollablePane, { style: { position: props.position == 'relative' ? 'relative' : 'inherit', height: props.height } },
                    React.createElement(MarqueeSelection, { selection: _selection },
                        React.createElement(DetailsList, { compact: true, columns: gridColumns, items: defaultGridData.length > 0 ? defaultGridData.filter(function (x) { return x._grid_row_operation_ != Operation.Delete; }) : [], layoutMode: props.layoutMode, selectionMode: props.selectionMode, selection: _selection, setKey: "none", constrainMode: props.constrainMode, onRenderDetailsHeader: onRenderDetailsHeader, ariaLabelForSelectAllCheckbox: "Toggle selection for all items", ariaLabelForSelectionColumn: "Toggle selection", checkButtonAriaLabel: "Row checkbox" })))
            // </div>
            :
                React.createElement(MarqueeSelection, { selection: _selection },
                    React.createElement(DetailsList, { compact: true, columns: gridColumns, items: defaultGridData.length > 0 ? defaultGridData.filter(function (x) { return x._grid_row_operation_ != Operation.Delete; }) : [], layoutMode: props.layoutMode, selectionMode: props.selectionMode, selection: _selection, setKey: "none", constrainMode: props.constrainMode, 
                        // onRenderDetailsHeader={(headerProps, defaultRender) => {
                        //     return (
                        //       <Sticky
                        //         stickyPosition={StickyPositionType.Header}
                        //         isScrollSynced={true}
                        //         stickyBackgroundColor="transparent"
                        //       >
                        //         <div>{defaultRender(headerProps)}</div>
                        //       </Sticky>
                        //     );
                        //   }} 
                        onRenderDetailsHeader: onRenderDetailsHeader, ariaLabelForSelectAllCheckbox: "Toggle selection for all items", ariaLabelForSelectionColumn: "Toggle selection", checkButtonAriaLabel: "Row checkbox" })),
        React.createElement(Dialog, { hidden: !dialogContent, onDismiss: closeRenameDialog, closeButtonAriaLabel: "Close" }, dialogContent),
        messageDialogProps.visible
            ?
                React.createElement(MessageDialog, { message: messageDialogProps.message, subMessage: messageDialogProps.subMessage, onDialogClose: closeMessageDialog })
            :
                null,
        props.enableColumnEdit && isUpdateColumnClicked ?
            React.createElement(ColumnUpdateDialog, { columnConfigurationData: props.columnConfigurationData, onDialogCancel: closeColumnUpdateDialog, onDialogSave: updateGridColumnData })
            :
                null));
};
var areEqual = function (prevProps, nextProps) {
    return (prevProps.gridData === nextProps.gridData) && (prevProps.columnConfigurationData === nextProps.columnConfigurationData);
};
export default React.memo(EditableGrid, areEqual);
