var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { useState } from 'react';
import { DetailsList } from 'office-ui-fabric-react/lib/components/DetailsList/DetailsList';
import { Selection, SelectionMode, } from 'office-ui-fabric-react/lib/DetailsList';
import { Stack, } from 'office-ui-fabric-react';
import { ConstrainMode, Sticky, StickyPositionType, TooltipHost, ScrollablePane, } from 'office-ui-fabric-react';
import { CoherencePagination, CoherencePageSize } from '@cseo/controls';
var DetailstListWithPagination = function (props) {
    var DEFAULT_PAGE_SIZE = 100;
    var TOTAL_RECORDS = props.gridData.length;
    var selectedPage = React.useRef(1);
    var pageCount = React.useRef(0);
    var defaultPageSize = React.useRef(DEFAULT_PAGE_SIZE);
    var _selection;
    var _a = useState(), selectedItems = _a[0], setSelectedItems = _a[1];
    var _b = useState(0), selectionCount = _b[0], setSelectionCount = _b[1];
    var _c = useState([]), selectedIndices = _c[0], setSelectedIndices = _c[1];
    var _d = React.useState([]), previewData = _d[0], setPreviewData = _d[1];
    var _e = React.useState(false), isLoadingTable = _e[0], setIsLoadingTable = _e[1];
    var _f = useState(''), selectionDetails = _f[0], setSelectionDetails = _f[1];
    function _getSelectionDetails() {
        var count = _selection.getSelectedCount();
        setSelectionCount(count);
        setSelectedItems(_selection.getSelection());
        setSelectedIndices(_selection.getSelectedIndices());
        console.log("selectionChange");
        if (props.onGridSelectionChange) {
            props.onGridSelectionChange(_selection.getSelection());
        }
        switch (count) {
            case 0:
                return 'No items selected';
            case 1:
                return '1 item selected: ';
            default:
                return count + " items selected";
        }
    }
    _selection = new Selection({
        onSelectionChanged: function () { return setSelectionDetails(_getSelectionDetails()); },
    });
    var fetchData = function (pageSize) {
        setIsLoadingTable(true);
        var data = [];
        for (var i = pageSize * (selectedPage.current - 1); i < Math.min(pageSize * (selectedPage.current - 1) + pageSize, props.gridData.length); ++i) {
            data.push(props.gridData[i]);
        }
        pageCount.current = Math.ceil(TOTAL_RECORDS / defaultPageSize.current);
        setPreviewData(data);
        setIsLoadingTable(false);
    };
    var onPageChange = function (newPageNumber) {
        if (newPageNumber !== selectedPage.current) {
            selectedPage.current = newPageNumber;
            fetchData(defaultPageSize.current);
        }
    };
    var telemetryHook = {
        trackMetric: function (metric, customProperties) {
            console.log('TrackEvent: ');
        },
        trackException: function (exception) {
            console.log('TrackEvent: ');
        },
        trackEvent: function (event, customProperties) {
            console.log('TrackEvent: ');
        },
        trackTrace: function (trace, customProperties) {
            console.log('TrackEvent: ');
        }
    };
    var paginationProps = {
        pageCount: pageCount.current,
        selectedPage: selectedPage.current,
        previousPageAriaLabel: 'previous page',
        nextPageAriaLabel: 'next page',
        inputFieldAriaLabel: 'page number',
        telemetryHook: telemetryHook,
        onPageChange: onPageChange
    };
    var onPageSizeChange = function (newPageSize) {
        defaultPageSize.current = newPageSize;
        selectedPage.current = 1;
        fetchData(defaultPageSize.current);
    };
    var onRenderDetailsHeader = function (props, defaultRender) {
        if (!props) {
            return null;
        }
        var onRenderColumnHeaderTooltip = function (tooltipHostProps) { return (React.createElement(TooltipHost, __assign({}, tooltipHostProps))); };
        return (React.createElement(Sticky, { stickyPosition: StickyPositionType.Header, isScrollSynced: true }, defaultRender(__assign(__assign({}, props), { onRenderColumnHeaderTooltip: onRenderColumnHeaderTooltip }))));
    };
    var paginationPageSizeProps = {
        pageSize: defaultPageSize.current,
        pageSizeList: [
            { key: 100, text: '100' },
            { key: 200, text: '200' },
            { key: 300, text: '300' },
            { key: 400, text: '400' },
            { key: 500, text: '500' }
        ],
        comboBoxAriaLabel: 'select page size',
        telemetryHook: telemetryHook,
        onPageSizeChange: onPageSizeChange
    };
    React.useEffect(function () {
        fetchData(defaultPageSize.current);
    }, [props]);
    return (React.createElement(ScrollablePane, { style: { height: '550px', position: 'relative' } },
        React.createElement("div", { style: { position: 'relative', height: '75%' } },
            React.createElement(ScrollablePane, { style: { height: '420px', position: 'relative' } },
                React.createElement(DetailsList, { columns: props.gridColumns, items: previewData, selectionMode: SelectionMode.single, selection: _selection, onRenderDetailsHeader: onRenderDetailsHeader, constrainMode: ConstrainMode.unconstrained, setKey: "none" }))),
        React.createElement(Stack, { horizontal: true, horizontalAlign: 'end' },
            React.createElement(Stack, { horizontal: true, horizontalAlign: 'space-between', styles: { root: { width: '50%' } } },
                React.createElement("div", { style: { marginLeft: -132 } },
                    React.createElement(CoherencePagination, __assign({}, paginationProps))),
                React.createElement(CoherencePageSize, __assign({}, paginationPageSizeProps))))));
};
export default DetailstListWithPagination;
