export var EditType;
(function (EditType) {
    EditType[EditType["None"] = 0] = "None";
    EditType[EditType["ColumnEdit"] = 1] = "ColumnEdit";
    EditType[EditType["BulkEdit"] = 2] = "BulkEdit";
    EditType[EditType["RowEdit"] = 3] = "RowEdit";
    EditType[EditType["DeleteRow"] = 4] = "DeleteRow";
    EditType[EditType["AddRow"] = 5] = "AddRow";
})(EditType || (EditType = {}));
export var ExportType;
(function (ExportType) {
    ExportType[ExportType["XLSX"] = 0] = "XLSX";
    ExportType[ExportType["CSV"] = 1] = "CSV";
})(ExportType || (ExportType = {}));
export var Operation;
(function (Operation) {
    Operation[Operation["None"] = 1] = "None";
    Operation[Operation["Add"] = 2] = "Add";
    Operation[Operation["Update"] = 3] = "Update";
    Operation[Operation["Delete"] = 4] = "Delete";
})(Operation || (Operation = {}));
export var ExchangeRateColumnConfigurationData = [
    {
        key: 'fDate',
        text: 'From Date',
        editable: false,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'eDate',
        text: 'End Date',
        editable: false,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'exchange_Rate',
        text: 'Exchange Rate',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'modifiedBy',
        text: 'Modified By',
        editable: false,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'modifiedDate',
        text: 'Modified Date',
        editable: false,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'createdAt',
        text: 'Create Date',
        editable: false,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
];
export var DutyRateColumnConfigurationData = [
    {
        key: 'company_Code',
        text: 'Company Code',
        editable: false,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'hsN_Code',
        text: 'HSN Code',
        editable: false,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'igsT_Rate',
        text: 'IGST Rate',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'basic_Customs_Duty_Rate',
        text: 'Basic Customs Duty Rate',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'ecess',
        text: 'Ecess',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'spl_Duty',
        text: 'SplDuty',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'cess_Rate',
        text: 'Cess Rate',
        editable: true,
        dataType: 'number',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'modifiedBy',
        text: 'Modified By',
        editable: false,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'modifiedDate',
        text: 'Modified Date',
        editable: false,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
];
export var NotificationRateColumnConfigurationData = [
    {
        key: 'notification_Number',
        text: 'Notification Number',
        editable: false,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'serial_Number',
        text: 'Serial Number',
        editable: false,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'dutyRateType',
        text: 'Duty Rate Type',
        editable: true,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
    },
    {
        key: 'new_Rate',
        text: 'New Rate',
        editable: true,
        dataType: 'number',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'valid_From',
        text: 'Valid From',
        editable: false,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'valid_To',
        text: 'Valid To',
        editable: false,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
    {
        key: 'modified_By',
        text: 'Modified By',
        editable: false,
        dataType: 'string',
        minWidth: 200,
        maxWidth: 250,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: true,
    },
    {
        key: 'modified_Date',
        text: 'Modified Date',
        editable: false,
        dataType: 'string',
        minWidth: 150,
        maxWidth: 200,
        isResizable: true,
        includeColumnInExport: true,
        includeColumnInSearch: false,
    },
];
