var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import * as React from 'react';
import { withContext } from '@employee-experience/common/lib/withContext';
import * as Styled from '../../shared/layout';
import { DetailsListLayoutMode, SelectionMode, DefaultButton, Label, ConstrainMode, } from 'office-ui-fabric-react';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { ComponentContext } from '@employee-experience/common';
import { useEffect, useState } from 'react';
import EditableGrid from '../CommonUtilities/editable-grid/EditableGrid';
import { ASNColumnConfigurationData, ASNLineItemColumnConfigurationData, Operation, ExportType, } from '../CommonUtilities/editable-grid/EditableGridConfig';
import { toast } from 'react-toastify';
import { useBoolean } from '@uifabric/react-hooks';
import { LoadingIndicator } from '../CommonUtilities/LoadingIndicator';
import { ExportToCSVUtil, ExportToExcelUtil } from '../CommonUtilities/ExcelExport';
import { HeirarchyType, POASNExportColumnsConfig } from './POASNConfig';
import { httpGet, httpPost } from '../../shared/APICalls';
import { AddIcon, stackItemStyles, stackItemStylesSeparator, stackStyles, stackTokens, styles, SubtractIcon, theme, } from './POASNStyles';
var appConfig = require('../../config/appConfig.js').appConfig;
var baseUrl = appConfig.AppSettings.APIUrl;
var POASN = function (props) {
    var context = React.useContext(ComponentContext);
    var _httpClient = context === null || context === void 0 ? void 0 : context.httpClient;
    var _a = useState(0), totalInvoiceValue = _a[0], setTotalInvoiceValue = _a[1];
    var _b = useState([]), shipmentDetails = _b[0], setShipmentDetails = _b[1];
    var _c = useState([]), shipmentLineItemDetails = _c[0], setShipmentLineItemDetails = _c[1];
    var _d = useState([]), exchangeRates = _d[0], setExchangeRates = _d[1];
    var _e = useState([]), items = _e[0], setItems = _e[1];
    var _f = useState([]), columns = _f[0], setColumns = _f[1];
    var _g = { disabled1: false, checked1: false }, disabled1 = _g.disabled1, checked1 = _g.checked1;
    var _h = { disabled2: false, checked2: false }, disabled2 = _h.disabled2, checked2 = _h.checked2;
    var _j = useBoolean(false), muted1 = _j[0], setMuted1 = _j[1].toggle;
    var _k = useBoolean(true), muted2 = _k[0], setMuted2 = _k[1].toggle;
    var _l = useState([]), shipmentStatusList = _l[0], setShipmentStatusesList = _l[1];
    // const [notificationDropdownList, setNotificationDropdownList] = useState<IDropdownOption[]>([]);
    var _m = useState([]), notificationsIGST = _m[0], setNotificationsIGST = _m[1];
    var _o = useState([]), notificationsCESS = _o[0], setNotificationsCESS = _o[1];
    var _p = useState([]), notificationsECESS = _p[0], setNotificationsECESS = _p[1];
    var _q = useState([]), notificationsBDR = _q[0], setNotificationsBDR = _q[1];
    var _r = useState([]), notificationsSPLDuty = _r[0], setNotificationsSPLDuty = _r[1];
    var FetchShipmentLineItemDetails = function () { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            url = baseUrl + "/ASN/GetShipmentLineItemDetails?shipmentRefNo=" + props.shipmentRefNumber;
            httpGet(_httpClient, url, function (response) {
                setShipmentLineItemDetails(response.data);
            }, function (error) {
                console.log(error);
            });
            return [2 /*return*/];
        });
    }); };
    var FetchShipmentDetails = function () { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            url = baseUrl + "/ASN/GetShipmentDetails?shipmentRefNo=" + props.shipmentRefNumber;
            httpGet(_httpClient, url, function (response) {
                setShipmentDetails(response.data);
            }, function (error) {
                console.log(error);
            });
            return [2 /*return*/];
        });
    }); };
    var FetchShipmneStatusesDetails = function () { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            url = baseUrl + "/ASN/DistinctShipmentStatuses";
            httpGet(_httpClient, url, function (response) {
                console.log('shipmentStatus');
                console.log(response);
                setShipmentStatusesList(response.data.map(function (name) { return ({ key: name.id, text: name.shipmentStatus }); }));
            }, function (error) {
                console.log(error);
            });
            return [2 /*return*/];
        });
    }); };
    var onErrorCallback = function (error) {
        toast.error('Invalid request or server error. Please try again later.');
    };
    var FetchNotificationDropdownListDetails = function () { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            url = baseUrl + "/ConfigureRates/NotificationRates";
            httpGet(_httpClient, url, function (response) {
                var notifData = response.data ? response.data : [];
                setNotificationsECESS(__spreadArrays([
                    { key: null, text: 'Clear notification' }
                ], notifData
                    .filter(function (item) { return item.dutyRateType == 'ECESS'; })
                    .map(function (name) { return ({
                    key: name.id,
                    text: name.notification_Number + '-' + name.serial_Number,
                }); })));
                setNotificationsCESS(__spreadArrays([
                    { key: null, text: 'Clear notification' }
                ], notifData
                    .filter(function (item) { return item.dutyRateType == 'CessRate'; })
                    .map(function (name) { return ({
                    key: name.id,
                    text: name.notification_Number + '-' + name.serial_Number,
                }); })));
                setNotificationsIGST(__spreadArrays([
                    { key: null, text: 'Clear notification' }
                ], notifData
                    .filter(function (item) { return item.dutyRateType == 'IGST'; })
                    .map(function (name) { return ({
                    key: name.id,
                    text: name.notification_Number + '-' + name.serial_Number,
                }); })));
                setNotificationsSPLDuty(__spreadArrays([
                    { key: null, text: 'Clear notification' }
                ], notifData
                    .filter(function (item) { return item.dutyRateType == 'SplDuty'; })
                    .map(function (name) { return ({
                    key: name.id,
                    text: name.notification_Number + '-' + name.serial_Number,
                }); })));
                setNotificationsBDR(__spreadArrays([
                    { key: null, text: 'Clear notification' }
                ], notifData
                    .filter(function (item) { return item.dutyRateType == 'BasicDutyRate'; })
                    .map(function (name) { return ({
                    key: name.id,
                    text: name.notification_Number + '-' + name.serial_Number,
                }); })));
            }, onErrorCallback);
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        if (shipmentDetails.length > 0 && shipmentStatusList.length > 0) {
            var status = shipmentDetails[0]['shipmentStatus'];
            if (status == 2) {
                var statusList = shipmentStatusList.filter(function (items) { return items.key == 2; });
                ASNColumnConfigurationData.filter(function (items) { return items.key == 'shipmentStatus'; })[0].dropdownValues =
                    statusList;
                ASNColumnConfigurationData.filter(function (items) { return items.key == 'shipmentStatus'; })[0].editable = false;
            }
            else {
                var statusList = shipmentStatusList.filter(function (items) { return items.key != 2; });
                ASNColumnConfigurationData.filter(function (items) { return items.key == 'shipmentStatus'; })[0].dropdownValues =
                    statusList;
                ASNColumnConfigurationData.filter(function (items) { return items.key == 'shipmentStatus'; })[0].editable = true;
            }
        }
    }, [shipmentStatusList, shipmentDetails]);
    useEffect(function () {
        if (shipmentLineItemDetails.length > 0) {
            ASNLineItemColumnConfigurationData.filter(function (items) { return items.key == 'notifIGSTID'; })[0].dropdownValues =
                notificationsIGST;
            ASNLineItemColumnConfigurationData.filter(function (items) { return items.key == 'notifSplDutyID'; })[0].dropdownValues =
                notificationsSPLDuty;
            ASNLineItemColumnConfigurationData.filter(function (items) { return items.key == 'notifBasicCustomID'; })[0].dropdownValues =
                notificationsBDR;
            ASNLineItemColumnConfigurationData.filter(function (items) { return items.key == 'notifCessID'; })[0].dropdownValues =
                notificationsCESS;
            ASNLineItemColumnConfigurationData.filter(function (items) { return items.key == 'notifEcessID'; })[0].dropdownValues =
                notificationsECESS;
        }
    }, [
        shipmentLineItemDetails,
        notificationsBDR,
        notificationsCESS,
        notificationsECESS,
        notificationsIGST,
        notificationsSPLDuty,
    ]);
    var FetchExchangeRatesCache = function () { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            url = baseUrl + "/ASN/ExchangeRates";
            httpGet(_httpClient, url, function (response) {
                setExchangeRates(response.data);
            }, function (error) {
                console.log(error);
            });
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        setTotalPriceInColumnHeader(shipmentLineItemDetails);
    }, [shipmentLineItemDetails]);
    useEffect(function () {
        toast.configure({
            position: 'top-center',
            autoClose: 5000,
            bodyClassName: 'toast-body',
            hideProgressBar: true,
            style: { marginTop: 50 },
        });
    }, []);
    useEffect(function () {
        if (props.shipmentRefNumber != null && props.shipmentRefNumber != '') {
            FetchShipmneStatusesDetails();
            FetchNotificationDropdownListDetails();
            FetchShipmentLineItemDetails();
            FetchShipmentDetails();
            FetchExchangeRatesCache();
            //onExchangeRateDateChanged(new Date());
        }
    }, [props.shipmentRefNumber]);
    var FormatDate = function (date) {
        var d = new Date(date);
        var month = '' + (d.getMonth() + 1), day = '' + d.getDate();
        var year = d.getFullYear();
        if (month.length < 2)
            month = '0' + month;
        if (day.length < 2)
            day = '0' + day;
        return [year, month, day].join('');
    };
    var ConstructExportData = function () {
        var data = [];
        shipmentLineItemDetails
            .filter(function (item) { return item._grid_row_operation_ != Operation.Delete; })
            .forEach(function (item, index) {
            var dataRow = {};
            POASNExportColumnsConfig.forEach(function (element) {
                switch (element.heirarchyType) {
                    case HeirarchyType.Header:
                        if (element.key == 'shipmentRefNo') {
                            dataRow[element.text] =
                                shipmentDetails[0][element.key] + '-' + item['shipmentLineItemNumber'];
                        }
                        else {
                            dataRow[element.text] =
                                element.type != 'Date'
                                    ? shipmentDetails[0][element.key]
                                    : shipmentDetails[0][element.key] == null ||
                                        shipmentDetails[0][element.key].length == 0
                                        ? ''
                                        : FormatDate(shipmentDetails[0][element.key]);
                        }
                        break;
                    case HeirarchyType.LineItem:
                        dataRow[element.text] =
                            element.type != 'Date'
                                ? item[element.key]
                                : item[element.key] == null || item[element.key].length == 0
                                    ? ''
                                    : FormatDate(item[element.key]);
                        break;
                    case HeirarchyType.AbsoluteHeader:
                        if (index == 0) {
                            dataRow[element.text] =
                                element.type != 'Date'
                                    ? shipmentDetails[0][element.key]
                                    : shipmentDetails[0][element.key] == null ||
                                        shipmentDetails[0][element.key].length == 0
                                        ? ''
                                        : FormatDate(shipmentDetails[0][element.key]);
                        }
                        else {
                            dataRow[element.text] = '';
                        }
                        break;
                }
            });
            data.push(dataRow);
        });
        console.log('Printing Export data');
        console.log(data);
        return data;
    };
    var onASNExcelExport = function (exportType) {
        var data = ConstructExportData();
        switch (exportType) {
            case ExportType.XLSX:
                ExportToExcelUtil(data, 'WWT ASN.xlsx');
                break;
            case ExportType.CSV:
                ExportToCSVUtil(data, 'WWT ASN.csv');
                break;
        }
    };
    var getTotalInvoiceValue = function (ASNLineItemData) {
        var sum = 0;
        ASNLineItemData.filter(function (item) { return item._grid_row_operation_ != Operation.Delete; }).forEach(function (item) { return (sum += Number(item.invoiceValue)); });
        return Math.round(sum * 100) / 100;
    };
    var updateTotalInvoiceValue = function (ASNLineItemData) {
        var sum = getTotalInvoiceValue(ASNLineItemData);
        setTotalInvoiceValue(Math.round(sum * 100) / 100);
    };
    var onASNLineItemGridUpdate = function (ASNLineItemData) {
        //(ASNLineItemData);
    };
    var onPOSTRequestSuccessCallback = function (response) {
        if (response.data.status == false) {
            toast.error(response.data.message);
        }
        if (response.data.status == true) {
            toast.success(response.data.message);
        }
    };
    var onASNLineItemGridSave = function (ASNLineItemData) {
        //debugger;
        ASNLineItemData.map(function (item) { return (item.shipmentRefNum = props.shipmentRefNumber); });
        ASNLineItemData.map(function (item) { return (item.companyCode = shipmentDetails[0].companyCode); });
        var url = baseUrl + "/ASN/SaveShipmentLineItemDetails";
        httpPost(_httpClient, url, function (response) {
            onPOSTRequestSuccessCallback(response);
            FetchShipmentLineItemDetails();
        }, onErrorCallback, JSON.stringify(ASNLineItemData));
    };
    var onASNHeaderSave = function (ASNHeaderData) {
        var url = baseUrl + "/ASN/SaveShipmentDetails";
        httpPost(_httpClient, url, function (response) {
            if (response.data == true) {
                toast.success('ASN Header Data Submitted');
            }
            else {
                toast.error('ASN Header Data Submit Failed');
            }
            FetchShipmentDetails();
        }, function (error) {
            toast.error(error.data.Message);
        }, JSON.stringify(ASNHeaderData[0]));
    };
    var onExchangeRateDateChanged = function (item, callbackProps) {
        if (item['currency'] &&
            item['currency'].length > 0 &&
            (item['currency'] == 'USD' || item['currency'].toLowerCase() == 'dollar')) {
            debugger;
            var exchangeRateObj = GetExchangeRateObjFromCache(item['exchangeRateDate']);
            callbackProps.callback({ exchangeRate: exchangeRateObj[0]['exchange_Rate'] }, callbackProps.rowNum, callbackProps.changeSourceKey, callbackProps.activateCurrentCell);
        }
    };
    var GetExchangeRateObjFromCache = function (value) {
        var dt = new Date(value).setHours(0, 0, 0, 0);
        var exchangeRateObj = exchangeRates.filter(function (item) { return new Date(item.fDate).setHours(0, 0, 0, 0) <= dt && new Date(item.eDate).setHours(0, 0, 0, 0) >= dt; });
        return exchangeRateObj;
    };
    var onCurrencyChanged = function (item, callbackProps) {
        debugger;
        var text = item['currency'];
        if (text != null && text.length > 0) {
            if (text.toLowerCase() == 'inr') {
                callbackProps.callback({ exchangeRate: 1 }, callbackProps.rowNum, callbackProps.changeSourceKey, callbackProps.activateCurrentCell);
            }
            else if (text.toLowerCase() == 'usd' || text.toLowerCase() == 'dollar') {
                var exchangeRateObj = GetExchangeRateObjFromCache(item['exchangeRateDate']);
                callbackProps.callback({ exchangeRate: exchangeRateObj[0]['exchange_Rate'] }, callbackProps.rowNum, callbackProps.changeSourceKey, callbackProps.activateCurrentCell);
            }
        }
    };
    var setTotalPriceInColumnHeader = function (ASNLineItemData) {
        var sum = getTotalInvoiceValue(ASNLineItemData);
        ASNLineItemColumnConfigurationData.filter(function (item) { return item.key == 'invoiceValue'; }).map(function (item) { return (item.text = 'Total Price (' + sum + ')'); });
    };
    var onInvoiceDetailsChanged = function (item, callbackProps) {
        debugger;
        if (item['invoiceQty'] == null ||
            item['invoicePrice'] == null ||
            isNaN(item['invoiceQty']) ||
            item['invoiceQty'].length == 0 ||
            isNaN(item['invoicePrice']) ||
            item['invoicePrice'].length == 0)
            callbackProps.callback({ invoiceValue: 0 }, callbackProps.rowNum, callbackProps.changeSourceKey, callbackProps.activateCurrentCell);
        else {
            var value = Number(item['invoiceQty']) * Number(item['invoicePrice']);
            var shipmentLineItemDetailsTmp = __spreadArrays(shipmentLineItemDetails);
            if (item._grid_row_operation_ == Operation.Update) {
                shipmentLineItemDetailsTmp[callbackProps.rowNum]['invoiceValue'] = value;
            }
            else if (item._grid_row_operation_ == Operation.Add) {
                item['invoiceValue'] = value;
                shipmentLineItemDetailsTmp.push(item);
            }
            //updateTotalInvoiceValue(shipmentLineItemDetailsTmp);
            setTotalPriceInColumnHeader(shipmentLineItemDetailsTmp);
            callbackProps.callback({ invoiceValue: value }, callbackProps.rowNum, callbackProps.changeSourceKey, callbackProps.activateCurrentCell);
        }
    };
    var onICNoChanged = function (item, callbackProps) {
        debugger;
        if (item['icNo'] == null || item['icNo'].length == 0)
            callbackProps.callback({ icDate: null }, callbackProps.rowNum, callbackProps.changeSourceKey, callbackProps.activateCurrentCell);
    };
    var onPCNoChanged = function (item, callbackProps) {
        debugger;
        if (item['pcNo'] == null || item['pcNo'].length == 0)
            callbackProps.callback({ pcDate: null }, callbackProps.rowNum, callbackProps.changeSourceKey, callbackProps.activateCurrentCell);
    };
    var onProformaInvoiceChanged = function (item, callbackProps) {
        debugger;
        if (item['proformaInv'] == null || item['proformaInv'].length == 0)
            callbackProps.callback({ proformaDate: null }, callbackProps.rowNum, callbackProps.changeSourceKey, callbackProps.activateCurrentCell);
    };
    var attachASNHeaderColumnChangeCallbacks = function (ASNColumnConfigurationDataObj) {
        ASNColumnConfigurationDataObj.filter(function (item) { return item.key == 'exchangeRateDate'; }).map(function (item) { return (item.onChange = onExchangeRateDateChanged); });
        ASNColumnConfigurationDataObj.filter(function (item) { return item.key == 'currency'; }).map(function (item) { return (item.onChange = onCurrencyChanged); });
        ASNColumnConfigurationDataObj.filter(function (item) { return item.key == 'icNo'; }).map(function (item) { return (item.onChange = onICNoChanged); });
        ASNColumnConfigurationDataObj.filter(function (item) { return item.key == 'pcNo'; }).map(function (item) { return (item.onChange = onPCNoChanged); });
        return ASNColumnConfigurationDataObj;
    };
    var attachASNLineItemColumnChangeCallbacks = function (ASNColumnConfigurationDataObj) {
        ASNColumnConfigurationDataObj.filter(function (item) { return item.key == 'invoiceQty'; }).map(function (item) { return (item.onChange = onInvoiceDetailsChanged); });
        ASNColumnConfigurationDataObj.filter(function (item) { return item.key == 'invoicePrice'; }).map(function (item) { return (item.onChange = onInvoiceDetailsChanged); });
        ASNColumnConfigurationDataObj.filter(function (item) { return item.key == 'proformaInv'; }).map(function (item) { return (item.onChange = onProformaInvoiceChanged); });
        return ASNColumnConfigurationDataObj;
    };
    return (React.createElement(Styled.listContainer, null,
        React.createElement("div", null,
            React.createElement(LoadingIndicator, null)),
        React.createElement(Stack, { verticalAlign: "space-between", tokens: stackTokens },
            React.createElement(Stack, { horizontal: true, styles: stackStyles, tokens: stackTokens },
                React.createElement(Stack.Item, { styles: stackItemStyles },
                    React.createElement(DefaultButton, { toggle: true, styles: styles, checked: muted1 || checked1, 
                        //text={muted1 ? 'Shipment Airway Bill Details' : 'Shipment Airway Bill Details'}
                        iconProps: muted1 ? AddIcon : SubtractIcon, onClick: setMuted1, ariaLabel: "Expand Shipment Airway Bill Details" })),
                React.createElement(Stack.Item, { grow: true, styles: stackItemStylesSeparator },
                    React.createElement(Label, { theme: theme }, "Shipment Airway Bill Details"))),
            !muted1 && shipmentDetails.length > 0 && shipmentStatusList.length > 0 ? (React.createElement(EditableGrid, { enableSave: true, columnConfigurationData: attachASNHeaderColumnChangeCallbacks(ASNColumnConfigurationData), layoutMode: DetailsListLayoutMode.justified, selectionMode: SelectionMode.multiple, enableRowEdit: true, gridData: shipmentDetails, enableCellEdit: true, enableTextFieldEditMode: true, onGridSave: onASNHeaderSave, enableUnsavedEditIndicator: true })) : null,
            React.createElement(Stack, { horizontal: true, styles: stackStyles, tokens: stackTokens },
                React.createElement(Stack.Item, { styles: stackItemStyles },
                    React.createElement(DefaultButton, { toggle: true, styles: styles, checked: muted2 || checked2, 
                        //text={muted1 ? 'Shipment Airway Bill Details' : 'Shipment Airway Bill Details'}
                        iconProps: muted2 ? AddIcon : SubtractIcon, onClick: setMuted2, ariaLabel: "Expand ASN Line Item" })),
                React.createElement(Stack.Item, { grow: true, styles: stackItemStylesSeparator },
                    React.createElement(Label, { theme: theme }, "ASN Line Item"))),
            !muted2 ? (React.createElement("div", null,
                React.createElement(EditableGrid, { enableColumnEdit: true, enableExport: true, onExcelExport: onASNExcelExport, enableSave: true, columnConfigurationData: attachASNLineItemColumnChangeCallbacks(ASNLineItemColumnConfigurationData), layoutMode: DetailsListLayoutMode.justified, selectionMode: SelectionMode.multiple, enableRowEdit: true, enableBulkEdit: true, gridData: shipmentLineItemDetails, enableGridRowsDelete: true, enableGridRowsAdd: shipmentDetails[0].isWithoutPO, enableCellEdit: true, enableTextFieldEditMode: true, onGridSave: onASNLineItemGridSave, onGridUpdate: onASNLineItemGridUpdate, height: '50vh', position: 'relative', constrainMode: ConstrainMode.unconstrained, enableUnsavedEditIndicator: true }))) : null)));
};
var connected = withContext(POASN);
export { connected as POASN };
