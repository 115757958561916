var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { withContext } from '@employee-experience/common/lib/withContext';
import * as Styled from '../../shared/layout';
import { Fabric, PrimaryButton, Breadcrumb, } from 'office-ui-fabric-react';
import { mergeStyles, DayOfWeek } from '@fluentui/react';
import { addDays, addMonths, addYears } from 'office-ui-fabric-react/lib/utilities/dateMath/DateMath';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { DatePicker } from 'office-ui-fabric-react';
import SearchResultsData from './SearchResultsData';
import { useState, useEffect } from 'react';
import { withRouter, useHistory } from 'react-router-dom';
import { ComponentContext } from '@employee-experience/common';
import { httpGet } from '../../shared/APICalls';
import { controlClass, controlStyles, dropdownStyles, stackTokens } from './AdvancedSearchStyles';
import { trackPromise } from 'react-promise-tracker';
var appConfig = require('../../config/appConfig.js').appConfig;
var baseUrl = appConfig.AppSettings.APIUrl;
var today = new Date(Date.now());
var minDate = addYears(today, -10);
var maxDate = addMonths(today, 0);
function AdvancedSearch() {
    var _this = this;
    var _a = React.useContext(ComponentContext), telemetryClient = _a.telemetryClient, authClient = _a.authClient;
    var context = React.useContext(ComponentContext);
    var history = useHistory();
    var _httpClient = context === null || context === void 0 ? void 0 : context.httpClient;
    var _b = React.useState(''), loggedInUser = _b[0], setLoggedInUser = _b[1];
    React.useEffect(function () {
        authClient.getUserId().then(function (res) {
            setLoggedInUser(res.split('@', 2)[0]);
        });
    });
    var itemsWithHref = [
        {
            text: 'Home',
            key: 'f1',
            onClick: function () {
                navigateToHomePage();
            },
        },
        {
            text: 'Workspace',
            key: 'f5',
            isCurrentItem: true,
        },
    ];
    var DayPickerStrings = {
        months: [
            'January',
            'February',
            'March',
            'April',
            'May',
            'June',
            'July',
            'August',
            'September',
            'October',
            'November',
            'December',
        ],
        shortMonths: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        days: ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'],
        shortDays: ['S', 'M', 'T', 'W', 'T', 'F', 'S'],
        goToToday: 'Go to today',
        prevMonthAriaLabel: 'Go to previous month',
        nextMonthAriaLabel: 'Go to next month',
        prevYearAriaLabel: 'Go to previous year',
        nextYearAriaLabel: 'Go to next year',
        closeButtonAriaLabel: 'Close date picker',
        isRequiredErrorMessage: 'Field is required.',
        invalidInputErrorMessage: 'Date format must be in MM/DD/YYYY format',
        isOutOfBoundsErrorMessage: "Date must be between " + minDate.toLocaleDateString() + "-" + maxDate.toLocaleDateString(),
    };
    var _c = useState({
        searchId: '',
        fromDate: null,
        toDate: null,
        companyCode: '',
        shipmentStatus: '',
        loggedInUser: '',
    }), gridFilteringData = _c[0], setGridFilteringData = _c[1];
    var _d = useState(sessionStorage.getItem('fromDate') ? new Date(sessionStorage.getItem('fromDate')) : addMonths(today, -2)), fromDate = _d[0], setFromDate = _d[1];
    var _e = useState(sessionStorage.getItem('toDate') ? new Date(sessionStorage.getItem('toDate')) : addDays(today, 0)), toDate = _e[0], setToDate = _e[1];
    var _f = React.useState([]), companyCodeOptions = _f[0], setcompanyCodeOptions = _f[1];
    var _g = useState(sessionStorage.getItem('companyCode') || '1190'), companyCode = _g[0], setCompanyCode = _g[1];
    var _h = useState(''), shipmentStatus = _h[0], setShipmentStatus = _h[1];
    var _j = useState(), shipmentStatusList = _j[0], setShipmentStatusesList = _j[1];
    React.useEffect(function () {
        sessionStorage.setItem('fromDate', fromDate.toString());
        sessionStorage.setItem('toDate', toDate.toString());
        sessionStorage.setItem('companyCode', companyCode);
    }, [fromDate, toDate, companyCode, shipmentStatus]);
    var onSelectFromDate = function (date) {
        setFromDate(date);
    };
    var onSelectToDate = function (date) {
        setToDate(date);
    };
    var onSelectCompanyCode = function (event, item) {
        setCompanyCode(item.text);
    };
    var onSelectShipmentStatus = function (event, item) {
        setShipmentStatus(item.key);
    };
    useEffect(function () {
        setGridFilteringData({
            searchId: '',
            fromDate: null,
            toDate: null,
            companyCode: '',
            shipmentStatus: '',
            loggedInUser: loggedInUser,
        });
    }, [loggedInUser]);
    var firstDayOfWeek = DayOfWeek.Sunday;
    function dateToISOLikeButLocal(date) {
        var offsetMs = date.getTimezoneOffset() * 60 * 1000;
        var msLocal = date.getTime() - offsetMs;
        var dateLocal = new Date(msLocal);
        var iso = dateLocal.toISOString();
        var isoLocal = iso.slice(0, 10);
        return isoLocal;
    }
    var setFilters = function () {
        setGridFilteringData({
            searchId: sessionStorage.getItem('searchText'),
            fromDate: dateToISOLikeButLocal(fromDate),
            toDate: dateToISOLikeButLocal(toDate),
            shipmentStatus: shipmentStatus,
            companyCode: companyCode,
            loggedInUser: loggedInUser,
        });
    };
    var navigateToHomePage = function () {
        history.push("/dashboard");
    };
    useEffect(function () {
        var url = baseUrl + "/CompanyCode/DistinctCompanyCodes";
        httpGet(_httpClient, url, function (response) {
            setcompanyCodeOptions(response.data.map(function (name) { return ({ key: name, text: name }); }));
        }, function (error) {
            console.log(error);
        });
    }, []);
    var rootClass = mergeStyles({ maxWidth: 300, selectors: { '> *': { marginBottom: 15 } } });
    useEffect(function () {
        var FetchShipmneStatusesDetails = function () { return __awaiter(_this, void 0, void 0, function () {
            var url;
            return __generator(this, function (_a) {
                url = baseUrl + "/ASN/DistinctShipmentStatuses";
                httpGet(_httpClient, url, function (response) {
                    var dataList = response.data;
                    dataList.unshift({ id: "-2", shipmentStatus: "All" }, { id: "-1", shipmentStatus: "No Status" });
                    setShipmentStatusesList(dataList.map(function (name) { return ({ key: name.id, text: name.shipmentStatus }); }));
                }, function (error) {
                    console.log(error);
                });
                return [2 /*return*/];
            });
        }); };
        trackPromise(FetchShipmneStatusesDetails());
    }, []);
    return (React.createElement(Styled.Container, null,
        React.createElement(Fabric, null,
            React.createElement("h1", null, "Workspace"),
            React.createElement(Breadcrumb, { items: itemsWithHref, maxDisplayedItems: 3, ariaLabel: "Create Items", overflowAriaLabel: "More links", style: { backgroundColor: '#FFFFFF' } }),
            React.createElement("div", null,
                React.createElement("br", null)),
            React.createElement("div", null,
                React.createElement(Stack, { horizontalAlign: "space-between", horizontal: true, tokens: stackTokens },
                    React.createElement(Stack, { horizontalAlign: "start", horizontal: true, tokens: stackTokens },
                        React.createElement("div", { className: rootClass },
                            React.createElement(DatePicker, { showWeekNumbers: true, showMonthPickerAsOverlay: true, className: controlClass.control, label: "From Date:", isRequired: false, firstDayOfWeek: firstDayOfWeek, strings: DayPickerStrings, placeholder: "Select a date", value: fromDate, ariaLabel: "Select a date", minDate: minDate, maxDate: maxDate, allowTextInput: true, styles: controlStyles, onSelectDate: onSelectFromDate })),
                        React.createElement("div", { className: rootClass },
                            React.createElement(DatePicker, { showWeekNumbers: true, showMonthPickerAsOverlay: true, className: controlClass.control, label: "To Date:", isRequired: false, firstDayOfWeek: firstDayOfWeek, strings: DayPickerStrings, placeholder: "Select a data", value: toDate, ariaLabel: "Select a date", minDate: minDate, maxDate: maxDate, allowTextInput: true, styles: controlStyles, onSelectDate: onSelectToDate })),
                        React.createElement(Dropdown, { placeholder: "Select the company code", label: "Company Code:", options: companyCodeOptions, styles: dropdownStyles, selectedKey: companyCode, onChange: onSelectCompanyCode }),
                        React.createElement(Dropdown, { placeholder: shipmentStatus == '' ? 'Select the status' : shipmentStatus, label: "Status:", options: shipmentStatusList, styles: dropdownStyles, onChange: onSelectShipmentStatus }),
                        React.createElement(PrimaryButton, { text: "Search", className: controlClass.searchStyles, onClick: setFilters })))),
            shipmentStatusList != null && shipmentStatusList.length > 0 &&
                React.createElement("div", null,
                    React.createElement(SearchResultsData, { gridFilteringData: gridFilteringData, shipmentsStatusList: shipmentStatusList })))));
}
var connected = withContext(withRouter(AdvancedSearch));
export { connected as AdvancedSearch };
