import * as React from 'react';
import { ComponentContext } from '@employee-experience/common/lib/ComponentContext';
// @ts-ignore
import { EventEmitter, EventType } from '../src/containers/CommonUtilities/EventEmitter';
export function useHeaderConfig() {
    var telemetryHook = React.useContext(ComponentContext).telemetryClient;
    return {
        headerLabel: __APP_NAME__,
        appNameSettings: {
            label: __APP_NAME__,
        },
        // searchSettings: {
        //     placeholder: 'Search PO...',
        //     //onSearch: (term: string): void => EventEmitter.dispatch(EventType.onSearch, event),
        //     onChange: (event) => EventEmitter.dispatch(EventType.onSearch, event),
        // },
        searchSettings: {
            placeholder: 'Search for PO# or Shipment Ref# or HAWB# or Shipment Invoice#',
            onSearch: function (term) { return EventEmitter.dispatch(EventType.onClick, event); },
            onChange: function (event, newValue) {
                return EventEmitter.dispatch(EventType.onSearch, event);
            },
        },
        farRightSettings: {
            notificationsSettings: {
                items: [],
                newNotifications: 0,
                telemetryHook: telemetryHook,
            },
            feedbackSettings: {
                ocvButtonIsFocused: false,
                onClick: function () {
                    return true;
                },
                launchOptions: {
                    categories: {
                        show: true,
                        customCategories: [
                            'Dashboard',
                            'Feed',
                            'Catalog',
                            'Vision',
                            'Hearing',
                            'Mobility',
                            'Cognitive',
                        ],
                    },
                },
            },
            settingsSettings: {
                body: React.createElement(React.Fragment, null, "Settings Contents"),
                telemetryHook: telemetryHook,
            },
            helpSettings: {
                body: React.createElement(React.Fragment, null, "Help Contents"),
                telemetryHook: telemetryHook,
            },
        },
        telemetryHook: telemetryHook,
    };
}
