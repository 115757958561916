var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import * as React from 'react';
import { PrimaryButton, TextField, Dropdown, SpinButton, ScrollablePane } from 'office-ui-fabric-react';
import { DatePicker, mergeStyles } from 'office-ui-fabric-react';
import { useBoolean } from '@uifabric/react-hooks';
import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { useEffect } from 'react';
import { InputType, OperandsType } from './GenericFormConfig';
import { DayPickerStrings } from '../DatePickerConfigs';
import { Position } from 'office-ui-fabric-react/lib/utilities/positioning';
import { toast } from 'react-toastify';
import { isNumeric } from '../ValidationFunctions';
var GenericForm = function (props) {
    var textFieldStyles = { fieldGroup: { width: 180 } };
    var _a = React.useState({}), formValuesOnPage = _a[0], setFormValuesOnPage = _a[1];
    var _b = React.useState([]), ddOptions = _b[0], setDdOptions = _b[1];
    var _c = React.useState([]), textboxValidations = _c[0], setTextboxValidations = _c[1];
    var _d = useBoolean(false), multiline = _d[0], toggleMultiline = _d[1].toggle;
    var rootClass = mergeStyles({ maxWidth: 300, selectors: { '> *': { marginBottom: 15 } } });
    var onTextUpdate = function (ev, text, index, item) {
        var _a;
        debugger;
        var textboxValidationTmp = textboxValidations;
        var idx = textboxValidations.findIndex(function (obj) { return obj.index === index; });
        if (text.length <= item.maxLength) {
            setFormValuesOnPage(__assign(__assign({}, formValuesOnPage), (_a = {}, _a[ev.target.id] = text, _a)));
            if (text === "") {
                if (item.required === true) {
                    textboxValidationTmp[idx].errormessage = "Please enter a value";
                    textboxValidationTmp[idx].show = true;
                }
                else {
                    textboxValidationTmp[idx].errormessage = "";
                    textboxValidationTmp[idx].show = false;
                }
            }
            else {
                if (item.dataType === 'number' && !isNumeric(text)) {
                    textboxValidationTmp[idx].errormessage = "Entered value should be numeric.";
                    textboxValidationTmp[idx].show = true;
                }
                else {
                    textboxValidationTmp[idx].errormessage = "";
                    textboxValidationTmp[idx].show = false;
                }
            }
            setTextboxValidations(textboxValidationTmp);
        }
    };
    var onMultiFieldTextBoxChange = function (ev, text, index, item) {
        var _a;
        var newMultiline = text.length > 148;
        if (newMultiline !== multiline) {
            toggleMultiline();
        }
        var textboxValidationTmp = textboxValidations;
        var idx = textboxValidations.findIndex(function (obj) { return obj.index === index; });
        if (text.length <= item.maxLength) {
            setFormValuesOnPage(__assign(__assign({}, formValuesOnPage), (_a = {}, _a[ev.target.id] = text, _a)));
            if (text === "" && item.required === true) {
                textboxValidationTmp[idx].errormessage = "Please enter a value";
                textboxValidationTmp[idx].show = true;
            }
            else {
                textboxValidationTmp[idx].errormessage = "";
                textboxValidationTmp[idx].show = false;
            }
            setTextboxValidations(textboxValidationTmp);
        }
    };
    var verticalGapStackTokens = {
        childrenGap: 15,
        padding: 10,
    };
    var stackStyles = { root: { width: 500 } };
    var horizontalGapStackTokens = {
        childrenGap: 10,
        padding: 10,
    };
    useEffect(function () {
        var textboxValidationTmp = textboxValidations;
        props.formConfigurationData.forEach(function (item, index) {
            switch (item.inputType) {
                case InputType.TextField:
                    textboxValidationTmp = textboxValidations;
                    textboxValidationTmp.push({ index: index, errormessage: '', show: false });
                    break;
                case InputType.MultilineTextField:
                    textboxValidationTmp = textboxValidations;
                    textboxValidationTmp.push({ index: index, errormessage: '', show: false });
                    break;
            }
        });
        setTextboxValidations(textboxValidations);
    }, []);
    var onPanelSubmit = function () {
        if (props.onPanelSubmit) {
            props.onPanelSubmit(formValuesOnPage);
        }
    };
    var getDateControlClass = function (colspan) {
        var widthVal = (200 * colspan);
        return mergeStyleSets({
            datePickerControl: {
                width: widthVal + "px",
            }
        });
    };
    var controlClass = mergeStyleSets({
        searchStyles: {
            marginTop: '30px',
        },
        submitStyles: {
            marginTop: '20px',
            marginLeft: '10px',
            marginRight: '10px',
            maxWidth: '300px',
        },
        labelValue: {
            fontWeight: 'bold',
        },
    });
    function dateToISOLikeButLocal(date) {
        var offsetMs = date.getTimezoneOffset() * 60 * 1000;
        var msLocal = date.getTime() - offsetMs;
        var dateLocal = new Date(msLocal);
        var iso = dateLocal.toISOString();
        var isoLocal = iso.slice(0, 10);
        return isoLocal;
    }
    var getDateMonth = function (month) {
        if (month <= 8)
            return '0' + (month + 1);
        return (month + 1).toString();
    };
    var getDateOfMonth = function (date) {
        if (date <= 9)
            return '0' + (date);
        return (date).toString();
    };
    var getDateHours = function (hour) {
        if (hour <= 9)
            return '0' + (hour);
        return (hour).toString();
    };
    var getDateMinutes = function (minute) {
        if (minute <= 9)
            return '0' + (minute);
        return (minute).toString();
    };
    var getDateSeconds = function (second) {
        if (second <= 9)
            return '0' + (second);
        return (second).toString();
    };
    var daysBetween = function (date1, date2) {
        debugger;
        // The number of milliseconds in one day
        var ONE_DAY = 1000 * 60 * 60 * 24;
        // Calculate the difference in milliseconds
        var differenceMs = Math.abs(date1 - date2);
        // Convert back to days and return
        return Math.floor(differenceMs / ONE_DAY);
    };
    var constructDate = function (date) {
        return date.getFullYear() + '-' + getDateMonth(date.getMonth()) + '-' + getDateOfMonth(date.getDate()) + 'T' + getDateHours(date.getHours()) + ':' + getDateMinutes(date.getMinutes()) + ':' + getDateSeconds(date.getSeconds());
    };
    var checkCalculationsFieldUpdate = function (formValuesOnPageTmp, item) {
        props.formConfigurationData.filter(function (item2) { return item2.isCalculated == true; }).forEach(function (item2, index) {
            var _a, _b, _c;
            if (((_a = item2.calculation) === null || _a === void 0 ? void 0 : _a.key1) == item.key || ((_b = item2.calculation) === null || _b === void 0 ? void 0 : _b.key2) == item.key) {
                switch ((_c = item2.calculation) === null || _c === void 0 ? void 0 : _c.operandsType) {
                    case OperandsType.Date:
                        formValuesOnPageTmp[item2.key] = daysBetween((new Date(formValuesOnPage[item2.calculation.key1])), (new Date(formValuesOnPage[item2.calculation.key2])));
                        break;
                    case OperandsType.Number:
                        formValuesOnPageTmp[item2.key] = Number(formValuesOnPage[item2.calculation.key1]) - Number(formValuesOnPage[item2.calculation.key1]);
                }
            }
        });
        return formValuesOnPageTmp;
    };
    var onCellDateChange = function (date, item) {
        var _a;
        debugger;
        var formValuesOnPageTmp = formValuesOnPage;
        var localDate = new Date(dateToISOLikeButLocal(date));
        var newDate = '';
        if (formValuesOnPageTmp[item.key] == null) {
            newDate = constructDate(localDate);
        }
        else {
            var split = (_a = formValuesOnPageTmp[item.key]) === null || _a === void 0 ? void 0 : _a.split('T');
            split[0] = localDate.getFullYear() + '-' + getDateMonth(localDate.getMonth()) + '-' + getDateOfMonth(localDate.getDate());
            newDate = split.join("T");
            //formValuesOnPageTmp[item.key] = new Date(dateToISOLikeButLocal(date));
        }
        formValuesOnPageTmp[item.key] = newDate;
        formValuesOnPageTmp = checkCalculationsFieldUpdate(formValuesOnPageTmp, item);
        setFormValuesOnPage(__assign(__assign({}, formValuesOnPage), { formValuesOnPageTmp: formValuesOnPageTmp }));
    };
    var updateHourInDateTime = function (value, item) {
        var _a, _b, _c;
        var formValuesOnPageTmp = formValuesOnPage;
        if (formValuesOnPageTmp[item.key] == null) {
            var localDate = new Date(dateToISOLikeButLocal(new Date()));
            var newDate = constructDate(localDate);
            formValuesOnPageTmp[item.key] = newDate;
        }
        var indexT = (_a = formValuesOnPageTmp[item.key]) === null || _a === void 0 ? void 0 : _a.indexOf('T');
        var indexColon = (_b = formValuesOnPageTmp[item.key]) === null || _b === void 0 ? void 0 : _b.indexOf(':');
        var dateTime = ((_c = formValuesOnPageTmp[item.key]) === null || _c === void 0 ? void 0 : _c.substring(0, indexT + 1)) + (value.length == 1 ? ('0' + value) : value) + formValuesOnPageTmp[item.key].substring(indexColon);
        formValuesOnPageTmp[item.key] = dateTime;
        setFormValuesOnPage(formValuesOnPageTmp);
    };
    var updateMinuteInDateTime = function (value, item) {
        var _a;
        debugger;
        var formValuesOnPageTmp = formValuesOnPage;
        if (formValuesOnPageTmp[item.key] == null) {
            var localDate = new Date(dateToISOLikeButLocal(new Date()));
            var newDate = constructDate(localDate);
            formValuesOnPageTmp[item.key] = newDate;
        }
        var split = (_a = formValuesOnPageTmp[item.key]) === null || _a === void 0 ? void 0 : _a.split(':');
        split[1] = (value.length == 1 ? ('0' + value) : value);
        var dateTime = split.join(":");
        formValuesOnPageTmp[item.key] = dateTime;
        setFormValuesOnPage(formValuesOnPageTmp);
    };
    var onHourChange = function (value, item, isIncrement) {
        if ((isIncrement && Number(value) == 23) || (!isIncrement && Number(value) == 0)) {
            return value;
        }
        value = (Number(value) + (isIncrement ? 1 : -1)).toString();
        updateHourInDateTime(value, item);
        return value;
    };
    var onMinuteChange = function (value, item, isIncrement) {
        if ((isIncrement && Number(value) == 59) || (!isIncrement && Number(value) == 0)) {
            return value;
        }
        value = (Number(value) + (isIncrement ? 1 : -1)).toString();
        updateMinuteInDateTime(value, item);
        return value;
    };
    var onHourValidate = function (value, item) {
        if (value.trim().length === 0 || isNaN(+value)) {
            return '0';
        }
        if (Number(value) > 23) {
            value = '23';
        }
        if (Number(value) < 0) {
            value = '0';
        }
        updateHourInDateTime(value, item);
        return String(value);
    };
    var onMinuteValidate = function (value, item) {
        if (value.trim().length === 0 || isNaN(+value)) {
            return '0';
        }
        if (Number(value) > 59) {
            value = '59';
        }
        if (Number(value) < 0) {
            value = '0';
        }
        updateMinuteInDateTime(value, item);
        return String(value);
    };
    var createFormFields = function () {
        debugger;
        var tmpRenderObj = [];
        var rowsObj = [];
        var rowspan = 0;
        props.formConfigurationData.forEach(function (item, index) {
            if (rowspan + item.colspan > 6) {
                rowsObj.push(React.createElement(Stack, { horizontalAlign: "start", horizontal: true, tokens: horizontalGapStackTokens }, tmpRenderObj));
                tmpRenderObj = [];
                rowspan = 0;
            }
            switch (item.inputType) {
                case InputType.TextField:
                    var textFieldStyle = { fieldGroup: { width: 180 * item.colspan } };
                    tmpRenderObj.push(React.createElement(TextField, { id: item.key, name: item.key, label: item.labelVal, styles: textFieldStyle, onChange: !item.readOnly ? function (ev, text) { onTextUpdate(ev, text, index, item); } : null, required: item.required, errorMessage: (textboxValidations.findIndex(function (obj) { return obj.index === index; }) != -1 &&
                            textboxValidations[textboxValidations.findIndex(function (obj) { return obj.index === index; })].show === true) ?
                            textboxValidations[textboxValidations.findIndex(function (obj) { return obj.index === index; })].errormessage : "", 
                        //disabled={item.readOnly}
                        //value={props.formValues ? props.formValues[item.key] : ''}
                        // value={ !item.isCalculated
                        //     ?
                        //     formValuesOnPage[item.key]
                        //     :
                        //     item.calculation?.operandsType == OperandsType.Date
                        //         ?
                        //         daysBetween((new Date(formValuesOnPage[item.calculation.key1])), (new Date(formValuesOnPage[item.calculation.key2])))
                        //         :
                        //         Number(formValuesOnPage[item.calculation.key1]) - Number(formValuesOnPage[item.calculation.key1])
                        // }
                        value: formValuesOnPage[item.key] }));
                    break;
                case InputType.MultilineTextField:
                    var textFieldStyle = { fieldGroup: { width: 180 * item.colspan } };
                    tmpRenderObj.push(React.createElement(TextField, { id: item.key, name: item.key, label: item.labelVal, multiline: multiline, styles: textFieldStyle, required: item.required, disabled: item.readOnly, errorMessage: (textboxValidations.findIndex(function (obj) { return obj.index === index; }) != -1 &&
                            textboxValidations[textboxValidations.findIndex(function (obj) { return obj.index === index; })].show === true) ?
                            textboxValidations[textboxValidations.findIndex(function (obj) { return obj.index === index; })].errormessage : "", 
                        // eslint-disable-next-line react/jsx-no-bind
                        //onChange={!item.readOnly ? onMultiFieldTextBoxChange : null}
                        onChange: function (ev, text) { onMultiFieldTextBoxChange(ev, text, index, item); }, value: formValuesOnPage[item.key] }));
                    break;
                case InputType.DropDown:
                    var dropdownStyles = { dropdown: { width: 180 * item.colspan } };
                    tmpRenderObj.push(React.createElement(Dropdown, { placeholder: "", label: item.labelVal, options: ddOptions, styles: dropdownStyles, 
                        //selectedKey={companyCode}
                        onChange: onSelectCompanyCode, required: item.required, disabled: item.readOnly }));
                    break;
                case InputType.Date:
                    var datePickerControlClassName = getDateControlClass(item.colspan);
                    tmpRenderObj.push(React.createElement("div", { className: rootClass },
                        React.createElement(DatePicker, { className: datePickerControlClassName.datePickerControl, 
                            //styles={style}
                            showWeekNumbers: true, showMonthPickerAsOverlay: true, label: item.labelVal, isRequired: item.required, strings: DayPickerStrings, placeholder: "Select a date...", ariaLabel: "Select a date", disabled: item.readOnly, onSelectDate: function (date) { return onCellDateChange(date, item); }, 
                            //value={new Date(formValuesOnPage[item.key])}
                            value: formValuesOnPage[item.key] == null ? (item.required ? new Date() : null) : (new Date(formValuesOnPage[item.key])) })));
                    break;
                case InputType.DateTime:
                    var datePickerControlClassName = getDateControlClass(item.colspan * 0.6);
                    var spinButtonStyles = {
                        root: {
                            width: 180 * item.colspan * 0.1,
                            verticalAlign: 'middle'
                        },
                    };
                    tmpRenderObj.push(React.createElement("div", { className: rootClass },
                        React.createElement(DatePicker, { className: datePickerControlClassName.datePickerControl, 
                            //styles={style}
                            label: item.labelVal, isRequired: item.required, strings: DayPickerStrings, placeholder: "Select a date...", ariaLabel: "Select a date", disabled: item.readOnly, onSelectDate: function (date) { return onCellDateChange(date, item); }, 
                            //value={new Date(formValuesOnPage[item.key])}
                            value: formValuesOnPage[item.key] == null ? (item.required ? new Date() : null) : (new Date(formValuesOnPage[item.key])) })));
                    tmpRenderObj.push(React.createElement(SpinButton, { styles: spinButtonStyles, defaultValue: "0", value: formValuesOnPage[item.key] != null ? ((new Date(formValuesOnPage[item.key])).getHours()).toString() : '0', label: 'Hour', labelPosition: Position.top, min: 0, max: 23, step: 1, incrementButtonAriaLabel: 'Increase value by 1', decrementButtonAriaLabel: 'Decrease value by 1', onIncrement: function (value) { return onHourChange(value, item, true); }, onDecrement: function (value) { return onHourChange(value, item, false); }, onValidate: function (value) { return onHourValidate(value, item); } }));
                    tmpRenderObj.push(React.createElement(SpinButton, { styles: spinButtonStyles, 
                        //upArrowButtonStyles={upArrowButtonStyles}
                        //downArrowButtonStyles={downArrowButtonStyles}
                        defaultValue: "0", value: formValuesOnPage[item.key] != null ? ((new Date(formValuesOnPage[item.key])).getMinutes()).toString() : '0', label: 'Minute', labelPosition: Position.top, min: 0, max: 59, step: 1, incrementButtonAriaLabel: 'Increase value by 1', decrementButtonAriaLabel: 'Decrease value by 1', onIncrement: function (value) { return onMinuteChange(value, item, true); }, onDecrement: function (value) { return onMinuteChange(value, item, false); }, onValidate: function (value) { return onMinuteValidate(value, item); } }));
                    break;
            }
            rowspan += item.colspan;
        });
        rowsObj.push(React.createElement(Stack, { horizontalAlign: "start", horizontal: true, tokens: horizontalGapStackTokens }, tmpRenderObj));
        //console.log(rowsObj);
        return rowsObj;
    };
    useEffect(function () {
        setFormValuesOnPage(props.formValues);
    }, [props.formValues]);
    useEffect(function () {
        debugger;
        console.log(formValuesOnPage);
    }, [formValuesOnPage]);
    var isValidSubmission = function () {
        var _a;
        var flag = true;
        var textboxValidationTmp = textboxValidations;
        for (var i = 0; i < textboxValidations.length; i++) {
            var idx = textboxValidations[i].index;
            if (props.formConfigurationData[idx].required === true &&
                (formValuesOnPage[props.formConfigurationData[idx].key] === null || formValuesOnPage[props.formConfigurationData[idx].key] === "")) {
                setFormValuesOnPage(__assign(__assign({}, formValuesOnPage), (_a = {}, _a[props.formConfigurationData[idx].key] = "", _a)));
                textboxValidationTmp[i].show = true;
                textboxValidationTmp[i].errormessage = "Please enter a value.";
            }
            if (textboxValidationTmp[i].show != false) {
                flag = false;
            }
        }
        setTextboxValidations(textboxValidationTmp);
        //console.log(textboxValidations);
        return flag;
    };
    var fixDataTypes = function () {
        var formValuesOnPageTmp = formValuesOnPage;
        for (var i = 0; i < textboxValidations.length; i++) {
            var idx = textboxValidations[i].index;
            if (props.formConfigurationData[idx].dataType === 'number') {
                var currFieldKey = props.formConfigurationData[idx].key;
                formValuesOnPageTmp[currFieldKey] = formValuesOnPageTmp[currFieldKey] != "" ? parseFloat(formValuesOnPageTmp[currFieldKey]) : null;
            }
        }
        return formValuesOnPageTmp;
    };
    var onSubmit = function (e) {
        if (isValidSubmission() === true) {
            setFormValuesOnPage(fixDataTypes);
            onPanelSubmit();
        }
        else {
            toast.error("Failed to submit the data.");
        }
    };
    return (React.createElement("div", null,
        React.createElement(Stack, null,
            React.createElement(ScrollablePane, { style: { position: 'relative', height: '120vh' } },
                React.createElement(Stack, { tokens: verticalGapStackTokens }, createFormFields())),
            React.createElement(Stack, { horizontal: true, disableShrink: true, styles: stackStyles, tokens: horizontalGapStackTokens },
                React.createElement(PrimaryButton, { text: props.submitButtonText != null && props.submitButtonText.length > 0 ? props.submitButtonText : "Submit", className: controlClass.submitStyles, onClick: onSubmit, allowDisabledFocus: true })))));
};
export default GenericForm;
