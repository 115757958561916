export var CalculationType;
(function (CalculationType) {
    CalculationType[CalculationType["Addition"] = 0] = "Addition";
    CalculationType[CalculationType["Subtraction"] = 1] = "Subtraction";
    CalculationType[CalculationType["Multiplication"] = 2] = "Multiplication";
    CalculationType[CalculationType["Division"] = 3] = "Division";
})(CalculationType || (CalculationType = {}));
export var OperandsType;
(function (OperandsType) {
    OperandsType[OperandsType["Number"] = 0] = "Number";
    OperandsType[OperandsType["Date"] = 1] = "Date";
})(OperandsType || (OperandsType = {}));
export var InputType;
(function (InputType) {
    InputType[InputType["None"] = 0] = "None";
    InputType[InputType["TextField"] = 1] = "TextField";
    InputType[InputType["DropDown"] = 2] = "DropDown";
    InputType[InputType["Date"] = 3] = "Date";
    InputType[InputType["MultilineTextField"] = 4] = "MultilineTextField";
    InputType[InputType["DateTime"] = 5] = "DateTime";
})(InputType || (InputType = {}));
export var PostCleranceFormConfigurationData = [
    {
        key: 'boENUM',
        labelVal: 'Bill of Entry',
        inputType: InputType.TextField,
        required: true,
        readOnly: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 150,
        colspan: 1.2,
        maxLength: 60,
    },
    {
        key: 'boEDAT',
        labelVal: 'Bill of Entry Date',
        inputType: InputType.Date,
        required: true,
        readOnly: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 150,
        colspan: 1.2,
    },
    {
        key: 'inboundNo',
        labelVal: 'Internal Bond Number',
        inputType: InputType.TextField,
        required: false,
        readOnly: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 150,
        colspan: 1.2,
        maxLength: 20,
    },
    {
        key: 'rewarhDate',
        labelVal: 'Re-warehousing date',
        inputType: InputType.DateTime,
        required: false,
        readOnly: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 150,
        colspan: 2.2,
    },
    {
        key: 'dutyChallanNum',
        labelVal: 'Duty Challan number',
        inputType: InputType.TextField,
        required: false,
        readOnly: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 150,
        colspan: 1.2,
        maxLength: 20,
    },
    {
        key: 'dutyChallanDate',
        labelVal: 'Duty Challan date',
        inputType: InputType.Date,
        required: false,
        readOnly: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 150,
        colspan: 1.2,
    },
    {
        key: 'dutyPerChallan',
        labelVal: 'Duty as per Challan INR',
        inputType: InputType.TextField,
        required: false,
        readOnly: false,
        dataType: 'number',
        minWidth: 120,
        maxWidth: 150,
        colspan: 1.2,
        maxLength: 13,
    },
    {
        key: 'eddChalNo',
        labelVal: 'EDD Challan number',
        inputType: InputType.TextField,
        required: false,
        readOnly: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 150,
        colspan: 1.2,
        maxLength: 20,
    },
    {
        key: 'interestAmount',
        labelVal: 'Interest Amount',
        inputType: InputType.TextField,
        required: false,
        readOnly: false,
        dataType: 'number',
        minWidth: 120,
        maxWidth: 150,
        colspan: 1.2,
        maxLength: 15,
    },
    {
        key: 'eddChalDate',
        labelVal: 'EDD Challan date',
        inputType: InputType.Date,
        required: false,
        readOnly: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 150,
        colspan: 1.2,
    },
    {
        key: 'edDepositAmount',
        labelVal: 'ED Deposit INR',
        inputType: InputType.TextField,
        required: false,
        readOnly: false,
        dataType: 'number',
        minWidth: 120,
        maxWidth: 150,
        colspan: 1.2,
        maxLength: 13,
    },
    {
        key: 'igmDate',
        labelVal: 'Arrival Date',
        inputType: InputType.Date,
        required: true,
        readOnly: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 150,
        colspan: 1.2,
    },
    {
        key: 'clrDateOOC',
        labelVal: 'Clearance Date',
        inputType: InputType.Date,
        required: true,
        readOnly: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 150,
        colspan: 1.2,
    },
    {
        key: 'penaltyAmount',
        labelVal: 'Penalty Amount',
        inputType: InputType.TextField,
        required: false,
        readOnly: false,
        dataType: 'number',
        minWidth: 120,
        maxWidth: 150,
        colspan: 1.2,
        maxLength: 15,
    },
    {
        key: 'tat',
        labelVal: 'Turnaround Time(Days)',
        inputType: InputType.TextField,
        required: true,
        readOnly: true,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 150,
        colspan: 1.5,
        isCalculated: true,
        calculation: { key1: 'clrDateOOC', key2: 'igmDate', calculationType: CalculationType.Subtraction, operandsType: OperandsType.Date }
    },
    {
        key: 'vehicleDetail',
        labelVal: 'Vehicle details',
        inputType: InputType.MultilineTextField,
        required: false,
        readOnly: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 150,
        colspan: 4.5,
        maxLength: 255,
    },
    {
        key: 'remark',
        labelVal: 'Reasons for Delay/Comments',
        inputType: InputType.MultilineTextField,
        required: true,
        readOnly: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 150,
        colspan: 6,
        maxLength: 60,
    },
    {
        key: 'broker1stInvoiceReceivedDate',
        labelVal: 'Broker 1st Invoice received date',
        inputType: InputType.Date,
        required: false,
        readOnly: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 150,
        colspan: 1.2,
    },
    {
        key: 'broker1stInvoiceNo',
        labelVal: 'Broker 1st Invoice No',
        inputType: InputType.TextField,
        required: false,
        readOnly: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 150,
        colspan: 1.2,
        maxLength: 20,
    },
    {
        key: 'broker1stInvoiceDate',
        labelVal: 'Brokler 1st invoice Date',
        inputType: InputType.Date,
        required: false,
        readOnly: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 150,
        colspan: 1.2,
    },
    {
        key: 'broker1stInvoiceAmount',
        labelVal: 'Broker 1st Invoice Amount',
        inputType: InputType.TextField,
        required: false,
        readOnly: false,
        dataType: 'number',
        minWidth: 120,
        maxWidth: 150,
        colspan: 1.2,
        maxLength: 20,
    },
    {
        key: 'broker1stInvoiceSubmissionDate',
        labelVal: 'Broker 1st Invoice Submission Date',
        inputType: InputType.Date,
        required: false,
        readOnly: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 150,
        colspan: 1.2,
    },
    {
        key: 'broker2ndInvoiceReceivedDate',
        labelVal: 'Broker 2nd Invoice received date',
        inputType: InputType.Date,
        required: false,
        readOnly: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 150,
        colspan: 1.2,
    },
    {
        key: 'broker2ndInvoiceNo',
        labelVal: 'Broker 2nd Invoice No',
        inputType: InputType.TextField,
        required: false,
        readOnly: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 150,
        colspan: 1.2,
        maxLength: 20,
    },
    {
        key: 'broker2ndInvoiceDate',
        labelVal: 'Broker 2nd Invoice Date',
        inputType: InputType.Date,
        required: false,
        readOnly: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 150,
        colspan: 1.2,
    },
    {
        key: 'broker2ndInvoiceAmount',
        labelVal: 'Broker 2nd Invoice Amount',
        inputType: InputType.TextField,
        required: false,
        readOnly: false,
        dataType: 'number',
        minWidth: 120,
        maxWidth: 150,
        colspan: 1.2,
        maxLength: 20,
    },
    {
        key: 'broker2ndInvoiceSubmissionDate',
        labelVal: 'Broker 2nd Invoice Submission Date',
        inputType: InputType.Date,
        required: false,
        readOnly: false,
        dataType: 'string',
        minWidth: 120,
        maxWidth: 150,
        colspan: 1.2,
    },
];
