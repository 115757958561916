var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { withContext } from '@employee-experience/common/lib/withContext';
import * as Styled from '../../shared/layout';
import { PrimaryButton, Pivot, PivotLinkSize, PivotItem, Breadcrumb, } from 'office-ui-fabric-react';
import { DatePicker, DayOfWeek } from 'office-ui-fabric-react/lib/DatePicker';
import { addDays, addMonths, addYears } from 'office-ui-fabric-react/lib/utilities/dateMath/DateMath';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
import { useState } from 'react';
import DetailedReport from './DetailedReport/DetailedReport';
import SummaryReport from './SummaryReport/SummaryReport';
import { withRouter, useHistory } from 'react-router-dom';
import { useEffect } from 'react';
import { ComponentContext } from '@employee-experience/common/lib/Context/ComponentContext';
import { trackPromise } from 'react-promise-tracker';
import { appConfig } from '../../config/appConfig';
import { DayPickerStrings } from '../CommonUtilities/DatePickerConfigs';
import { controlClass, controlStyles, dropdownStyles, stackTokens } from './IndiaImportsReportsStyles';
import { dateToISOLikeButLocal } from '../CommonUtilities/CommonFunctions';
import { httpGet } from '../../shared/APICalls';
var today = new Date(Date.now());
var minDate = addYears(today, -5);
var maxDate = addMonths(today, 0);
var baseUrl = appConfig.AppSettings.APIUrl;
var resourceId = appConfig.AppSettings.AppClientId;
var firstDayOfWeek = DayOfWeek.Sunday;
function IndiaImportsReports() {
    var _this = this;
    var context = React.useContext(ComponentContext);
    var _httpClient = context === null || context === void 0 ? void 0 : context.httpClient;
    var history = useHistory();
    var itemsWithHref = [
        {
            text: 'Home',
            key: 'f1',
            onClick: function () {
                navigateToHomePage();
            },
        },
        {
            text: 'Reports',
            key: 'f5',
            isCurrentItem: true,
        },
    ];
    var navigateToHomePage = function () {
        history.push("/dashboard");
    };
    var _a = useState(sessionStorage.getItem('BoEFromDate') ? new Date(sessionStorage.getItem('BoEFromDate')) : addMonths(today, -1)), fromDate = _a[0], setFromDate = _a[1];
    var _b = useState(sessionStorage.getItem('BoEToDate') ? new Date(sessionStorage.getItem('BoEToDate')) : addDays(today, 0)), toDate = _b[0], setToDate = _b[1];
    var _c = useState(sessionStorage.getItem('ReportsCompanyCode') || ''), companyCode = _c[0], setCompanyCode = _c[1];
    var _d = useState(''), shipmentStatus = _d[0], setShipmentStatus = _d[1];
    var _e = useState(''), filterType = _e[0], setFilterType = _e[1];
    var _f = useState(), companyCodesList = _f[0], setCompanyCodesList = _f[1];
    var _g = useState(), shipmentStatusList = _g[0], setShipmentStatusesList = _g[1];
    var onSelectFromDate = function (date) {
        setFromDate(date);
    };
    var onSelectToDate = function (date) {
        setToDate(date);
    };
    var onSelectCompanyCode = function (event, item) {
        setCompanyCode(item.text);
    };
    var onSelectFilterType = function (event, item) {
        setFilterType(item.key);
    };
    var onSelectShipmentStatus = function (event, item) {
        setShipmentStatus(item.key);
    };
    var _h = useState({
        companyCode: companyCode,
        fromDate: dateToISOLikeButLocal(fromDate),
        toDate: dateToISOLikeButLocal(toDate),
        shipmentStatus: shipmentStatus,
        filterType: filterType,
    }), gridFilteringData = _h[0], setGridFilteringData = _h[1];
    var setFilters = function () {
        sessionStorage.setItem('BoEFromDate', fromDate.toString());
        sessionStorage.setItem('BoEToDate', toDate.toString());
        sessionStorage.setItem('ReportsCompanyCode', companyCode);
        if (fromDate <= toDate) {
            setGridFilteringData({
                companyCode: companyCode,
                fromDate: dateToISOLikeButLocal(fromDate),
                toDate: dateToISOLikeButLocal(toDate),
                shipmentStatus: shipmentStatus.toString(),
                filterType: filterType,
            });
        }
    };
    useEffect(function () {
        var FetchCompanyCodeData = function () { return __awaiter(_this, void 0, void 0, function () {
            return __generator(this, function (_a) {
                try {
                    _httpClient
                        .get(baseUrl + "/CompanyCode/DistinctCompanyCodes", {
                        method: 'GET',
                        resource: resourceId,
                        headers: {
                            'Content-Type': 'application/json',
                        },
                    }).then(function (response) {
                        setCompanyCodesList(response.data.map(function (name) { return ({ key: name, text: name }); }));
                    });
                }
                catch (_b) { }
                return [2 /*return*/];
            });
        }); };
        trackPromise(FetchCompanyCodeData());
    }, []);
    useEffect(function () {
        var FetchShipmneStatusesDetails = function () { return __awaiter(_this, void 0, void 0, function () {
            var url;
            return __generator(this, function (_a) {
                url = baseUrl + "/ASN/DistinctShipmentStatuses";
                httpGet(_httpClient, url, function (response) {
                    var dataList = response.data;
                    dataList.unshift({ id: "-2", shipmentStatus: "All" }, { id: "-1", shipmentStatus: "No Status" });
                    setShipmentStatusesList(dataList.map(function (name) { return ({ key: name.id, text: name.shipmentStatus }); }));
                }, function (error) {
                    console.log(error);
                });
                return [2 /*return*/];
            });
        }); };
        trackPromise(FetchShipmneStatusesDetails());
    }, []);
    return (React.createElement(Styled.Container, null,
        React.createElement("h1", null, "Reports"),
        React.createElement(Breadcrumb, { items: itemsWithHref, maxDisplayedItems: 3, ariaLabel: "Create Items", overflowAriaLabel: "More links", style: { backgroundColor: '#FFFFFF' } }),
        React.createElement(Styled.filtersContainer, null,
            React.createElement("div", null,
                React.createElement(Stack, { horizontalAlign: "start", horizontal: true, tokens: stackTokens },
                    React.createElement(Dropdown, { placeholder: companyCode == '' ? 'Select the company code' : companyCode, label: "Company Code:", options: companyCodesList, styles: dropdownStyles, onChange: onSelectCompanyCode }),
                    React.createElement(Dropdown, { placeholder: "Select the date filter type", label: "Date Filter Type:", options: [{ key: '0', text: 'BoE Date' },
                            { key: '1', text: 'Shipment Created Date' }], styles: dropdownStyles, onChange: onSelectFilterType }),
                    React.createElement(DatePicker, { className: controlClass.control, label: "Start Date:", isRequired: false, firstDayOfWeek: firstDayOfWeek, strings: DayPickerStrings, placeholder: "Select a date", value: fromDate, ariaLabel: "Select a date", minDate: minDate, maxDate: toDate, allowTextInput: true, styles: controlStyles, onSelectDate: onSelectFromDate }),
                    React.createElement(DatePicker, { className: controlClass.control, label: "End Date:", isRequired: false, firstDayOfWeek: firstDayOfWeek, strings: DayPickerStrings, placeholder: "Select a data", value: toDate, ariaLabel: "Select a date", minDate: minDate, maxDate: maxDate, allowTextInput: true, styles: controlStyles, onSelectDate: onSelectToDate }),
                    filterType == '1' &&
                        React.createElement(Dropdown, { placeholder: shipmentStatus == '' ? 'Select the status' : shipmentStatusList.filter(function (obj) { return obj.key == shipmentStatus; })[0].text, label: "Status:", options: shipmentStatusList, styles: dropdownStyles, onChange: onSelectShipmentStatus }),
                    React.createElement(PrimaryButton, { text: "Search", className: controlClass.searchStyles, onClick: setFilters })))),
        React.createElement(Pivot, { "aria-label": "Large Link Size Pivot Example", linkSize: PivotLinkSize.large },
            React.createElement(PivotItem, { headerText: "Summary Report" },
                React.createElement(SummaryReport, { gridFilteringData: gridFilteringData })),
            React.createElement(PivotItem, { headerText: "Detailed Report" },
                React.createElement(DetailedReport, { gridFilteringData: gridFilteringData })))));
}
var connected = withContext(withRouter(IndiaImportsReports));
export { connected as IndiaImportsReports };
