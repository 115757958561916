import * as React from 'react';
import { Card, CardHeading, CardContent } from '@employee-experience/common/lib/Card';
import { withContext } from '@employee-experience/common/lib/withContext';
import { addDays, addMonths } from 'office-ui-fabric-react/lib/utilities/dateMath/DateMath';
import { useState, useEffect } from 'react';
import { ComponentContext } from '@employee-experience/common/lib/Context/ComponentContext';
import '!style-loader!css-loader!react-toastify/dist/ReactToastify.css';
import { Pie } from 'react-chartjs-2';
import { httpPost } from '../../shared/APICalls';
import { dateToISOLikeButLocal } from '../CommonUtilities/CommonFunctions';
var appConfig = require('../../config/appConfig.js').appConfig;
var baseUrl = appConfig.AppSettings.APIUrl;
function ShipmentsSTPIDetailsCard() {
    var context = React.useContext(ComponentContext);
    var _httpClient = context === null || context === void 0 ? void 0 : context.httpClient;
    var _a = useState(0), numberOfSTPIShipments = _a[0], setNumberOfSTPIShipments = _a[1];
    var _b = useState(0), numberOfNonSTPIShipments = _b[0], setNumberOfNonSTPIShipments = _b[1];
    useEffect(function () {
        var url = baseUrl + "/Dashboard/GetDashboardSTPIShipmentDetails";
        httpPost(_httpClient, url, function (response) {
            setNumberOfSTPIShipments(response.data.numberOfSTPIShipments);
            setNumberOfNonSTPIShipments(response.data.numberOfNonSTPIShipments);
        }, function (error) {
            console.log(error);
        }, JSON.stringify({
            fromDate: dateToISOLikeButLocal(addMonths(new Date(Date.now()), -2)),
            toDate: dateToISOLikeButLocal(addDays(new Date(Date.now()), 0)),
        }));
    }, []);
    return (React.createElement(Card, null,
        React.createElement(CardHeading, null, "Shipments by Type"),
        React.createElement(CardContent, null,
            React.createElement("div", null,
                React.createElement(Pie, { data: {
                        labels: ['Non-tax Exempted Shpments', 'Tax Exempted Shipments'],
                        datasets: [
                            {
                                label: 'Shipments Type',
                                backgroundColor: [
                                    '#ef9a9a',
                                    '#039be5'
                                ],
                                hoverBackgroundColor: [
                                    '#ef9a9a',
                                    '#039be5'
                                ],
                                data: [numberOfSTPIShipments, numberOfNonSTPIShipments]
                            }
                        ]
                    }, width: 250, height: 250, options: {
                        title: {
                            display: false,
                            text: '',
                            fontSize: 0
                        },
                        maintainAspectRatio: false,
                        legend: {
                            display: true,
                            position: 'bottom'
                        }
                    } })))));
}
var connected = withContext(ShipmentsSTPIDetailsCard);
export { connected as ShipmentsSTPIDetailsCard };
