import { DefaultButton, Dialog, DialogFooter, mergeStyleSets } from 'office-ui-fabric-react';
import * as React from 'react';
import { useEffect } from 'react';
var MessageDialog = function (props) {
    var _a = React.useState(undefined), messageDialogContent = _a[0], setMessageDialogContent = _a[1];
    var closeDialog = React.useCallback(function () {
        if (props.onDialogClose) {
            props.onDialogClose();
        }
        setMessageDialogContent(undefined);
    }, []);
    var controlClass = mergeStyleSets({
        dialogSubMessageStyles: {
            margin: 10,
        },
        dialogHeaderStyles: {
            margin: 10,
        }
    });
    useEffect(function () {
        setMessageDialogContent(React.createElement(React.Fragment, null,
            React.createElement("div", null,
                React.createElement("h3", { className: controlClass.dialogHeaderStyles }, props && props.message ? props.message : ''),
                React.createElement("div", { className: controlClass.dialogSubMessageStyles }, props && props.subMessage ? props.subMessage : '')),
            React.createElement(DialogFooter, null,
                React.createElement(DefaultButton, { onClick: function () { return closeDialog(); }, text: "Close" }))));
    }, [props]);
    return (React.createElement(Dialog, { hidden: !messageDialogContent, onDismiss: closeDialog, closeButtonAriaLabel: "Close" }, messageDialogContent));
};
export default MessageDialog;
