import * as React from 'react';
import * as Styled from '../../shared/layout';
import { PivotLinkSize, Pivot, PivotItem, initializeIcons, Breadcrumb, Stack } from 'office-ui-fabric-react';
import { withRouter, useHistory } from 'react-router-dom';
import { withContext } from '@employee-experience/common/lib/withContext';
import { PostClearence } from '../../containers/PostClearence/PostClearence';
import { PreClearence } from '../../containers/PreClearence/PreClearence';
import { POASN } from '../../containers/POASN/POASN';
import { useEffect, useState } from 'react';
var stackStyles = { root: { display: 'flex', flexWrap: 'wrap' } };
var ShipmentDetails = function (props) {
    initializeIcons();
    var _a = useState(''), shipmentRefNumber = _a[0], setShipmentRefNumber = _a[1];
    var history = useHistory();
    var itemsWithHref = [
        {
            text: 'Home',
            key: 'f1',
            onClick: function () {
                navigateToHomePage();
            },
        },
        {
            text: 'Workspace',
            key: 'f5',
            onClick: function () {
                navigateToWorkspacePage();
            },
            isCurrentItem: true,
        },
        {
            text: "Shipment Details - " + shipmentRefNumber,
            key: 'f5',
            isCurrentItem: true,
        },
    ];
    useEffect(function () {
        var split = location.pathname.split("/");
        setShipmentRefNumber(split[split.length - 1]);
    }, []);
    var navigateToHomePage = function () {
        history.push("/dashboard");
    };
    var navigateToWorkspacePage = function () {
        history.push("/advanced-search");
    };
    return (React.createElement(Styled.Container, null,
        React.createElement("div", null,
            React.createElement("h1", null, "Shipment Details"),
            React.createElement(Stack, null,
                React.createElement(Breadcrumb, { items: itemsWithHref, maxDisplayedItems: 3, ariaLabel: "Create Items", overflowAriaLabel: "More links", style: { backgroundColor: '#FFFFFF' } }))),
        React.createElement(Styled.Space, null,
            React.createElement("div", null,
                React.createElement(Pivot, { styles: stackStyles, "aria-label": "Large Link Size Pivot Example", linkSize: PivotLinkSize.large },
                    React.createElement(PivotItem, { headerText: "ASN Data" },
                        React.createElement(POASN, { shipmentRefNumber: shipmentRefNumber })),
                    React.createElement(PivotItem, { headerText: "Pre Clearance Calculations" },
                        React.createElement(PreClearence, { shipmentRefNumber: shipmentRefNumber })),
                    React.createElement(PivotItem, { headerText: "Post Clearance Upload" },
                        React.createElement(PostClearence, { shipmentRefNumber: shipmentRefNumber })))))));
};
var connected = withContext(withRouter(ShipmentDetails));
export { connected as ShipmentDetails };
// export default withContext(withRouter(ShipmentDetails));
