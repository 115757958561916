import * as React from 'react';
import { withContext } from '@employee-experience/common/lib/withContext';
import { DefaultPalette, Stack } from 'office-ui-fabric-react';
import { withRouter } from 'react-router-dom';
import '!style-loader!css-loader!./Dashboard.css';
import CompanyWiseDistributionCard from './CompanyWiseDIstributionCard';
import DateWiseDistributionCard from './DateWiseDIstributionCard';
import { ShipmentsSTPIDetailsCard } from './ShipmentsSTPIDetailsCard';
import { ShipmentsPOTypeDetailsCard } from './ShipmentsPOTypeDetailsCard';
//import '!style-loader!css-loader!react-toastify/dist/ReactToastify.css';
var itemStyles = {
    alignItems: 'center',
    background: DefaultPalette.yellow,
    color: DefaultPalette.white,
    display: 'flex',
    height: 50,
    justifyContent: 'center',
    width: 50,
};
var sectionStackTokens = { childrenGap: 1 };
var wrapStackTokens = { childrenGap: 25 };
function Dashboard() {
    var _a = React.useState(100), stackWidth = _a[0], setStackWidth = _a[1];
    // Mutating styles definition
    var stackStyles = {
        root: {
            background: DefaultPalette.whiteTranslucent40,
            width: stackWidth + "%",
        },
    };
    return (React.createElement("div", { className: "app" },
        React.createElement("h1", { className: "dashboard_header" }, "Dashboard"),
        React.createElement("br", null),
        React.createElement(Stack, { horizontal: true, wrap: true, styles: stackStyles, tokens: wrapStackTokens },
            React.createElement("div", { className: "tile35" },
                React.createElement(CompanyWiseDistributionCard, null)),
            React.createElement("div", { className: "tile35" },
                React.createElement(DateWiseDistributionCard, null)),
            React.createElement("div", { className: "tile35" },
                React.createElement(ShipmentsSTPIDetailsCard, null)),
            React.createElement("div", { className: "tile35" },
                React.createElement(ShipmentsPOTypeDetailsCard, null)))));
}
var connected = withContext(withRouter(Dashboard));
export { connected as Dashboard };
