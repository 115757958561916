var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
import * as React from 'react';
import { Text } from 'office-ui-fabric-react/lib/Text';
import * as Styled from '../../shared/layout';
import { PrimaryButton, TextField, ChoiceGroup, Checkbox, DetailsList, Label, GroupHeader, SelectionMode, Selection, TagPicker, } from 'office-ui-fabric-react';
import { mergeStyleSets } from 'office-ui-fabric-react/lib/Styling';
import { Stack } from 'office-ui-fabric-react/lib/Stack';
import { Dropdown } from 'office-ui-fabric-react/lib/Dropdown';
var EventEmitter = require('../CommonUtilities/EventEmitter.js').EventEmitter;
var EventType = require('../CommonUtilities/EventEmitter.js').EventType;
//import POLineItems from './POLineItems';
import { useState, useEffect, useMemo } from 'react';
import { toast } from 'react-toastify';
import { ComponentContext } from '@employee-experience/common';
import { httpGet, httpPost } from '../../shared/APICalls';
var appConfig = require('../../config/appConfig.js').appConfig;
var baseUrl = appConfig.AppSettings.APIUrl;
var GenerateShipmentRefNumber = function (props) {
    var resourceId = appConfig.AppSettings.AppClientId;
    var context = React.useContext(ComponentContext);
    var _httpClient = context === null || context === void 0 ? void 0 : context.httpClient;
    var _a = useState([]), selectedPOs = _a[0], setSelectedPOs = _a[1];
    var selectedPOsRef = React.useRef([]);
    var selectedCompanyCodeRef = React.useRef('');
    var _b = useState(''), selectedShipmentRefNo = _b[0], setSelectedShipmentRefNo = _b[1];
    var selectedShipmentRefNoTypeRef = React.useRef('');
    var _c = React.useState(true), hideShipmentRefNoSearchbox = _c[0], setHideShipmentRefNoSearchbox = _c[1];
    var _d = React.useState(true), hideShipmentLineItemGrid = _d[0], setHideShipmentLineItemGrid = _d[1];
    var _e = React.useState(false), hideGetNewShipmentRefNoBtn = _e[0], setHideGetNewShipmentRefNoBtn = _e[1];
    var _f = React.useState(false), hasPOFilterText = _f[0], setHasPOFilterText = _f[1];
    var _g = useState([]), shipmentDetails = _g[0], setShipmentDetails = _g[1];
    var _h = useState([
        {
            poNumber: '',
            lineItemNo: '',
            lineItemDesc: '',
            invoiceAmount: '',
            invoiceQuantiy: 0,
            quantityShipped: 0,
            isChanged: false,
        },
    ]), shipmentLineItems = _h[0], setShipmentLineItems = _h[1];
    var shippedQuantyDataRef = React.useRef([]);
    var _j = React.useState([]), shippedQuantyData = _j[0], setShippedQuantyData = _j[1];
    var _k = React.useState(true), isNew = _k[0], SetIsNew = _k[1];
    var _l = React.useState(false), isShipmentWithoutPOs = _l[0], setIsShipmentWithoutPOs = _l[1];
    var _m = React.useState(false), isDutyExempted = _m[0], setIsDutyExempted = _m[1];
    var _o = React.useState(false), isPartial = _o[0], setIsPartial = _o[1];
    var _p = React.useState(false), disableCompanyCode = _p[0], setDisableCompanyCode = _p[1];
    var _q = React.useState(false), disableShipmentWithoutPOsCheckbox = _q[0], setDisableShipmentWithoutPOsCheckbox = _q[1];
    var isPartialRef = React.useRef(false);
    var disableCompanyCodeRef = React.useRef(false);
    var isShipmentWithoutPOsRef = React.useRef(false);
    var isNewRef = React.useRef(true);
    var _r = React.useState(false), isValidationPassed = _r[0], setIsValidationPassed = _r[1];
    var _s = React.useState({
    // shipmentPOs: {
    //     isNew: true,
    //     isDutyExempted: false,
    //     isShipmentWithoutPOs: false,
    //     companyCode: '',
    //     shipmentRefNumber: '',
    //     isPartial: false,
    //     shipmentLineItems: shipmentLineItems,
    //     poList: [''],
    // },
    }), linkShipmentPOData = _s[0], setLinkShipmentPOData = _s[1];
    var _t = React.useState([]), companyCodeOptions = _t[0], setcompanyCodeOptions = _t[1];
    var _u = useState(sessionStorage.getItem('companyCode') || '1190'), companyCode = _u[0], setCompanyCode = _u[1];
    var _v = useState({
        companyCode: '',
        poNumber: '',
        shipmentRefNo: '',
        poList: '',
    }), errors = _v[0], setErrors = _v[1];
    var _w = useState(0), shipmentRefNoCount = _w[0], setShipmentRefNoCount = _w[1];
    var dropdownStyles = {
        dropdown: { width: 300 },
    };
    var textFieldStyles = { fieldGroup: {} };
    var formFieldStyle = {
        marginLeft: '15%',
        marginTop: 5,
        marginRight: '20%',
        marginBottom: 18,
    };
    var buttonFieldStyle = {
        width: 200,
        marginLeft: '50%',
        marginRight: '50%',
    };
    var searchBoxStyle = {
        marginTop: '10px',
    };
    var verticalGapStackTokens = {
        childrenGap: 15,
        padding: 10,
    };
    var controlClass = mergeStyleSets({
        control: {
            marginBottom: '10px',
            marginRight: '30px',
            maxWidth: '300px',
        },
        searchStyles: {
            marginTop: '30px',
        },
        submitStyles: {
            marginTop: '20px',
            marginLeft: '10px',
            marginRight: '10px',
            maxWidth: '300px',
        },
    });
    var stackStyles = { root: { width: 500 } };
    var horizontalGapStackTokens = {
        childrenGap: 10,
        padding: 10,
    };
    var inputProps = {
        onBlur: function (ev) { return console.log('onBlur called'); },
        onFocus: function (ev) { return console.log('onFocus called'); },
    };
    var stackTokens = { childrenGap: 10 };
    var getTextFromItem = function (item) {
        return item.name;
    };
    var pickerSuggestionsProps = {
        suggestionsHeaderText: 'Suggested tags',
        noResultsFoundText: 'No item tags found',
    };
    var options = [
        { key: 'New', text: 'New Shipment Ref Number' },
        { key: 'Existing', text: 'Existing Shipment Ref Number' },
    ];
    var _x = useState([]), selectedIndices = _x[0], setSelectedIndices = _x[1];
    var _y = useState(), selectedItems = _y[0], setSelectedItems = _y[1];
    var selection;
    selection = useMemo(function () {
        return new Selection({
            onSelectionChanged: function () {
                setSelectedItems(selection.getSelection());
                setSelectedIndices(selection.getSelectedIndices());
            },
            selectionMode: SelectionMode.multiple,
        });
    }, []);
    useEffect(function () {
        if (selectedItems && selectedItems.length > 0) {
            SelectedItemsInvoiceSum(selectedItems);
        }
        else if (selectedItems && selectedItems.length == 0) {
            setInvoiceSum(0);
        }
    }, [selectedItems]);
    var _z = useState(0), invoiceSum = _z[0], setInvoiceSum = _z[1];
    var SelectedItemsInvoiceSum = function (selectedItems) {
        var invoiceSumTeamp = 0;
        selectedItems.forEach(function (item) {
            invoiceSumTeamp += item.quantityShipped * item.unitPrice;
        });
        setInvoiceSum(invoiceSumTeamp);
    };
    useEffect(function () {
        var url = baseUrl + "/CompanyCode/DistinctCompanyCodes";
        httpGet(_httpClient, url, function (response) {
            setcompanyCodeOptions(response.data.map(function (name) { return ({ key: name, text: name }); }));
        }, function (error) {
            console.log(error);
        });
    }, []);
    var onSelectCompanyCode = function (event, item) {
        setCompanyCode(item.text);
    };
    function _onOptionBtnChange(ev, option) {
        //        console.dir(option);
        if (option.key == 'Existing') {
            setHideShipmentRefNoSearchbox(false);
            setHideGetNewShipmentRefNoBtn(true);
            setHideShipmentLineItemGrid(true);
            setIsPartial(false);
            setIsPartialRef(false);
            SetIsNew(false);
            setIsNewRef(false);
            setIsDutyExempted(false);
            setIsShipmentWithoutPOs(false);
            setDisableShipmentWithoutPOsCheckbox(true);
            if (getIsShipmentWithoutPOsRef()) {
                //setDisableCompanyCode(true);
                setDisableCompanyCodeRef(true);
                //setIsShipmentWithoutPOsRef(true);
            }
        }
        else if (option.key == 'New') {
            setHideShipmentRefNoSearchbox(true);
            setHideGetNewShipmentRefNoBtn(false);
            setHideShipmentLineItemGrid(true);
            setIsPartial(false);
            setIsPartialRef(false);
            SetIsNew(true);
            setIsNewRef(true);
            setIsDutyExempted(false);
            setIsShipmentWithoutPOs(false);
            setDisableShipmentWithoutPOsCheckbox(false);
            //setDisableCompanyCode(false);
            setDisableCompanyCodeRef(false);
        }
    }
    var onSubmit = function (e) { };
    var preparePOShipmentData = function () {
        setLinkShipmentPOData({
            //...linkShipmentPOData,
            shipmentPOs: {
                isNew: isNew,
                isDutyExempted: isDutyExempted,
                isShipmentWithoutPOs: isShipmentWithoutPOs,
                companyCode: isShipmentWithoutPOs ? companyCode : '',
                shipmentRefNumber: isNew == true ? '' : selectedShipmentRefNo,
                isPartial: getIsPartialRef(),
                //shipmentLineItems: shipmentLineItems.filter((item) => item.isChanged == true),
                shipmentLineItems: selectedItems,
                poList: selectedPOsRef.current,
            },
        });
    };
    useEffect(function () {
        if (linkShipmentPOData.shipmentPOs != null) {
            var errorObj = validationsWhileLinkPOsShipments();
            if (errorObj.isPassed) {
                linkPOShipmentAPICall();
            }
            else {
                toast.error(errorObj.errorMessage);
            }
        }
    }, [linkShipmentPOData]);
    var validationsWhileLinkPOsShipments = function () {
        var errorObj = {
            isPassed: false,
            errorMessage: ''
        };
        if (!isShipmentWithoutPOs && selectedPOsRef.current.length == 0) {
            errorObj.errorMessage = "Search for PO's and Select at least one";
            errorObj.isPassed = false;
            return errorObj;
        }
        if (!linkShipmentPOData.shipmentPOs.isNew) {
            //Existing Shipment Validations
            if (linkShipmentPOData.shipmentPOs.shipmentRefNumber == "") {
                errorObj.errorMessage = "Please ensure to have valid ShipmentRefNo";
                errorObj.isPassed = false;
                return errorObj;
            }
            if (shipmentRefNoCount > 1) {
                errorObj.errorMessage = "You should always have only One Shipment Ref No";
                errorObj.isPassed = false;
                return errorObj;
            }
            if (!isShipmentWithoutPOs && linkShipmentPOData.shipmentPOs.poList.length == 0) {
                errorObj.errorMessage = "Please ensure to have valid PO Number(s) in PO Searchbox";
                errorObj.isPassed = false;
                return errorObj;
            }
        }
        if (linkShipmentPOData.shipmentPOs.isNew == true) {
            //New Shipment Validations
            if (!isShipmentWithoutPOs && linkShipmentPOData.shipmentPOs.poList.length == 0) {
                errorObj.errorMessage = "Please ensure to have valid PO Number(s) in PO Searchbox";
                errorObj.isPassed = false;
                return errorObj;
            }
        }
        errorObj.isPassed = true;
        return errorObj;
    };
    var linkPOShipmentAPICall = function () {
        var url = baseUrl + "/Workspace/LinkShipmentRefWithPOandPOLineItems";
        httpPost(_httpClient, url, function (response) {
            if (response) {
                toast.success(response.data);
                if (props.onPanelSubmit) {
                    props.onPanelSubmit();
                }
            }
            else {
                toast.error(response.data);
            }
        }, function (error) {
            toast.error(error.data.Message);
        }, linkShipmentPOData.shipmentPOs);
    };
    var getShipmentPOLineItemDetails = function () {
        var url = baseUrl + "/Workspace/ShipmentPOLineItemDetails?poNumbers=" + selectedPOsRef.current;
        httpGet(_httpClient, url, function (response) {
            setShipmentLineItems(response.data);
        }, function (error) {
            toast.error(error.data.Message);
        });
    };
    var linkShipmentRefWithPOandPOLineItems = function () {
        preparePOShipmentData();
    };
    var onIsPartialShipmentChkboxChange = React.useCallback(function (ev, checked) {
        setIsPartial(!!checked);
        setIsPartialRef(!!checked);
        if (checked) {
            setHideShipmentLineItemGrid(false);
            getShipmentPOLineItemDetails();
        }
        else if (!checked) {
            setHideShipmentLineItemGrid(true);
        }
    }, []);
    var onIsShipmentWithoutPOsChkboxChange = React.useCallback(function (ev, checked) {
        setIsShipmentWithoutPOs(checked);
        if (checked) {
            setHideShipmentLineItemGrid(true);
            if (!isNew) {
                //setDisableCompanyCode(true);
                setDisableCompanyCodeRef(true);
            }
        }
        else {
            //reset Is Partial
            setIsPartial(false);
            setIsPartialRef(false);
            //setDisableCompanyCode(false);
            setDisableCompanyCodeRef(false);
        }
    }, []);
    var onIsDutyExemptedChkboxChange = React.useCallback(function (ev, checked) {
        setIsDutyExempted(!!checked);
        setIsDutyExempted(checked);
    }, []);
    var onShippedQuantyUpdate = function (ev, text, index) {
        var shipmentLineItemsTemp = __spreadArrays(shipmentLineItems);
        shipmentLineItemsTemp[index].quantityShipped = Number(text);
        setShipmentLineItems(shipmentLineItemsTemp);
    };
    useEffect(function () {
        setLinkShipmentPOData(function (prevState) {
            return __assign(__assign({}, prevState), { shipmentLineItems: shipmentLineItems });
        });
    }, [shipmentLineItems]);
    var _0 = useState([]), items = _0[0], setItems = _0[1];
    var shipmentLineItemColumns = [
        {
            key: 'poNumber',
            name: 'PO Number',
            ariaLabel: 'poNumber',
            fieldName: 'poNumber',
            isResizable: true,
            minWidth: 120,
            maxWidth: 150,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            //onColumnClick: handleColumnClick,
            //onRender: (item) => <span> {item.poNumber}</span>,
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.poNumber); },
        },
        {
            key: 'lineItemNo',
            name: 'LineItem No',
            ariaLabel: 'lineItemNo',
            fieldName: 'lineItemNo',
            isResizable: true,
            minWidth: 50,
            maxWidth: 100,
            data: 'string',
            //onColumnClick: handleColumnClick,
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.lineItemNo); },
        },
        {
            key: 'lineItemDesc',
            name: 'LineItem Description',
            ariaLabel: 'lineItemDesc',
            fieldName: 'lineItemDesc',
            isResizable: true,
            minWidth: 80,
            maxWidth: 200,
            data: 'string',
            //onColumnClick: handleColumnClick,
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.lineItemDesc); },
        },
        {
            key: 'unitPrice',
            name: 'Unit Price',
            ariaLabel: 'Unit Price',
            fieldName: 'unitPrice',
            isResizable: true,
            minWidth: 50,
            maxWidth: 150,
            data: 'string',
            //onColumnClick: handleColumnClick,
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.unitPrice); },
        },
        {
            key: 'invoiceQuantiy',
            name: 'Invoice Quantiy',
            ariaLabel: 'Invoice Quantiy',
            fieldName: 'invoiceQuantity',
            isResizable: true,
            minWidth: 50,
            maxWidth: 100,
            data: 'string',
            //onColumnClick: handleColumnClick,
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.invoiceQuantity); },
        },
        {
            key: 'quantityShipped',
            name: 'Quantity Shipped',
            fieldName: 'quantityShipped',
            isResizable: true,
            minWidth: 50,
            maxWidth: 100,
            onRender: function (item, index) { return (React.createElement("div", null,
                React.createElement(TextField, { ariaLabel: 'quantityShipped', value: item.quantityShipped, onChange: function (e, text) { return onShippedQuantyUpdate(e, text, index); } }))); },
        },
    ];
    var listContainsDocument = function (tag, tagList) {
        if (!tagList || !tagList.length || tagList.length === 0) {
            return false;
        }
        return tagList.filter(function (compareTag) { return compareTag.key === tag.key; }).length > 0;
    };
    var setCompanyCodeRef = function (value) {
        selectedCompanyCodeRef.current = value;
    };
    var clearCompanyCodeRef = function () {
        selectedCompanyCodeRef.current = '';
    };
    var getCompanyCodeRef = function () {
        return selectedCompanyCodeRef.current;
    };
    var setSelectedPOsRef = function (value) {
        selectedPOsRef.current = value;
    };
    var clearSelectedPOsRef = function () {
        selectedPOsRef.current = [];
    };
    var getSelectedPOsRef = function () {
        return selectedPOsRef.current;
    };
    var setIsPartialRef = function (value) {
        isPartialRef.current = value;
    };
    var clearIsPartialRef = function () {
        isPartialRef.current = false;
    };
    var getIsPartialRef = function () {
        return isPartialRef.current;
    };
    var setIsNewRef = function (value) {
        isNewRef.current = value;
    };
    var clearIsNewRef = function () {
        isNewRef.current = true;
    };
    var getIsNewRef = function () {
        return isNewRef.current;
    };
    var setIsShipmentWithoutPOsRef = function (value) {
        isShipmentWithoutPOsRef.current = value;
    };
    var clearIsShipmentWithoutPOsRef = function () {
        isShipmentWithoutPOsRef.current = false;
    };
    var getIsShipmentWithoutPOsRef = function () {
        return isShipmentWithoutPOsRef.current;
    };
    var setSelectedShipmentRefNoTypeRef = function (value) {
        selectedShipmentRefNoTypeRef.current = value;
    };
    var clearSelectedShipmentRefNoTypeRef = function () {
        selectedShipmentRefNoTypeRef.current = '';
    };
    var getSelectedShipmentRefNoTypeRef = function () {
        return selectedShipmentRefNoTypeRef.current;
    };
    var setDisableCompanyCodeRef = function (value) {
        disableCompanyCodeRef.current = value;
    };
    var clearDisableCompanyCodeRef = function () {
        disableCompanyCodeRef.current = false;
    };
    var getDisableCompanyCodeRef = function () {
        return disableCompanyCodeRef.current;
    };
    var setCompanyCodeRefFromPOTagList = function (tagList) {
        var POs = tagList.map(function (item) { return item.name; });
        if (POs.length > 0 && getCompanyCodeRef().length == 0) {
            var companyCodeTmp = props.poShipmentData.filter(function (item) { return item.poNumber == POs[0]; })[0].companyCode;
            setCompanyCodeRef(companyCodeTmp);
        }
    };
    var setCompanyCodeRefFromShipmentNumberTagList = function (tagList) {
        var ShipmentNumber = tagList.map(function (item) { return item.name; });
        setSelectedShipmentRefNo(ShipmentNumber[0]);
        if (ShipmentNumber.length > 0 && getCompanyCodeRef().length == 0) {
            var companyCodeTmp = props.poShipmentData.filter(function (item) { return item.shipmentRefNo == ShipmentNumber[0]; })[0]
                .companyCode;
            setCompanyCodeRef(companyCodeTmp);
        }
    };
    var getSelectedShipmentRefNoType = function () {
        var selectedShipmentRefNoType = props.poShipmentData.filter(function (item) { return item.shipmentRefNo == getSelectedShipmentRefNoTypeRef(); });
    };
    var onShipmentNumberTagListChanged = React.useCallback(function (tagList) {
        if (tagList.length == 1) {
            setCompanyCodeRefFromShipmentNumberTagList(tagList);
        }
        else if (tagList.length == 0) {
            clearCompanyCodeRef();
        }
        setShipmentRefNoCount(tagList.length);
    }, []);
    var onPOTagListChanged = React.useCallback(function (tagList) {
        if (tagList.length == 1) {
            setCompanyCodeRefFromPOTagList(tagList);
        }
        else if (tagList.length == 0) {
            clearCompanyCodeRef();
        }
        var POs = tagList.map(function (item) { return item.name; });
        setSelectedPOsRef(POs);
        if (getIsPartialRef()) {
            getShipmentPOLineItemDetails();
        }
    }, []);
    var onFilterChanged = React.useCallback(function (filterText, tagList) {
        if (filterText.length > 5) {
            var companyCode_1 = getCompanyCodeRef();
            var filteredTags = void 0;
            setHasPOFilterText(!!filterText);
            filteredTags = filterText
                ? Array.from(new Set(props.poShipmentData
                    .filter(function (item) { return (companyCode_1.length > 0 ? item.companyCode == companyCode_1 : item); })
                    .map(function (item) { return item.poNumber; })))
                    .map(function (item) { return ({ key: item, name: item }); })
                    .filter(function (tag) { return (tag.name !== null) && tag.name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0; })
                    .filter(function (tag) { return !listContainsDocument(tag, tagList); })
                : [];
            return filteredTags;
        }
    }, []);
    var onFilterShipmentRefNoChanged = React.useCallback(function (filterText, tagList) {
        if (filterText.length > 3 && tagList.length == 0) {
            var companyCode_2 = getCompanyCodeRef();
            var filteredTags = void 0;
            setHasPOFilterText(!!filterText);
            filteredTags = filterText
                ? Array.from(new Set(props.poShipmentData
                    .filter(function (item) { return item.shipmentRefNo != null; })
                    .filter(function (item) { return (companyCode_2.length > 0 ? item.companyCode == companyCode_2 : item); })
                    .filter(function (item) { return item.shipmentRefNo != null || item.shipmentRefNo != ''; })
                    .map(function (item) { return item.shipmentRefNo; })))
                    .map(function (item) { return ({ key: item, name: item }); })
                    .filter(function (tag) { return tag.name.toLowerCase().indexOf(filterText.toLowerCase()) >= 0; })
                    .filter(function (tag) { return !listContainsDocument(tag, tagList); })
                : [];
            return filteredTags;
        }
    }, []);
    return (React.createElement(Styled.listContainer, null,
        React.createElement(Stack, { tokens: verticalGapStackTokens },
            React.createElement(ChoiceGroup, { defaultSelectedKey: "New", options: options, onChange: _onOptionBtnChange, required: true })),
        !hideShipmentRefNoSearchbox ? (React.createElement(Stack, { tokens: stackTokens },
            React.createElement(Text, null, "Search for Shipment Ref No"),
            React.createElement(TagPicker, { removeButtonAriaLabel: "Remove this Shipment Ref No", "aria-label": "Search for Shipment Ref No", onResolveSuggestions: onFilterShipmentRefNoChanged, getTextFromItem: getTextFromItem, pickerSuggestionsProps: pickerSuggestionsProps, inputProps: __assign(__assign({}, inputProps), { id: 'picker1' }), onChange: onShipmentNumberTagListChanged }))) : null,
        React.createElement(Stack, { tokens: verticalGapStackTokens },
            React.createElement(Checkbox, { label: "Is Shipment Duty Exempted?", checked: isDutyExempted, onChange: onIsDutyExemptedChkboxChange })),
        React.createElement(Stack, { tokens: verticalGapStackTokens },
            React.createElement(Checkbox, { label: "Is Shipment Without PO's?", checked: isShipmentWithoutPOs, 
                //disabled={disableShipmentWithoutPOsCheckbox}
                onChange: onIsShipmentWithoutPOsChkboxChange })),
        !isShipmentWithoutPOs && props.poShipmentData && props.poShipmentData.length > 0 ? (React.createElement("div", null,
            React.createElement(Stack, { tokens: stackTokens },
                React.createElement(Text, null, "Search PO Number(s) to link"),
                React.createElement(TagPicker, { removeButtonAriaLabel: "Remove this PO", selectionAriaLabel: "Selected POs", "aria-label": "Search PO Number(s) to link", onResolveSuggestions: onFilterChanged, getTextFromItem: getTextFromItem, pickerSuggestionsProps: pickerSuggestionsProps, inputProps: __assign(__assign({}, inputProps), { id: 'picker2' }), 
                    //onRemoveSuggestion={onPOTagRemoved}
                    onChange: onPOTagListChanged })),
            React.createElement(Stack, { tokens: verticalGapStackTokens },
                React.createElement(Checkbox, { label: "Does Shipment contain Partial POs", checked: getIsPartialRef(), onChange: onIsPartialShipmentChkboxChange })))) : (React.createElement("div", null,
            React.createElement(Dropdown, { placeholder: "Select the company code", label: "Company Code:", options: companyCodeOptions, disabled: getDisableCompanyCodeRef(), styles: dropdownStyles, selectedKey: companyCode, onChange: onSelectCompanyCode }))),
        !hideShipmentLineItemGrid ? (React.createElement("div", null,
            React.createElement(Stack, null,
                React.createElement(DetailsList, { columns: shipmentLineItemColumns, items: shipmentLineItems, ariaLabelForSelectAllCheckbox: "Toggle selection for all items", ariaLabelForSelectionColumn: "Toggle selection", checkButtonAriaLabel: "Row checkbox", selection: selection, selectionPreservedOnEmptyClick: true, groupProps: {
                        onRenderHeader: function (props) {
                            return React.createElement(GroupHeader, __assign({}, props, { selectedItems: selection }));
                        },
                        showEmptyGroups: true,
                    }, onRenderItemColumn: function (item, index, column) { return renderItemColumn(item, index, column); } })),
            React.createElement(Stack, null,
                React.createElement(Label, null,
                    "Sum of Selected Invoice's amount: ",
                    invoiceSum)))) : null,
        React.createElement(PrimaryButton, { type: "submit", text: getIsNewRef() ? (isShipmentWithoutPOs ? 'Generate New Shipment Ref No' : 'Generate New Shipment Ref No and Link POs') : 'Update Shipment Ref No', 
            //style={buttonFieldStyle}
            className: controlClass.submitStyles, onClick: function (event) {
                linkShipmentRefWithPOandPOLineItems();
                EventEmitter.dispatch(EventType.onClick, event);
            }, allowDisabledFocus: true })));
};
export default GenerateShipmentRefNumber;
