export var HeirarchyType;
(function (HeirarchyType) {
    HeirarchyType[HeirarchyType["Header"] = 0] = "Header";
    HeirarchyType[HeirarchyType["LineItem"] = 1] = "LineItem";
    HeirarchyType[HeirarchyType["AbsoluteHeader"] = 2] = "AbsoluteHeader";
})(HeirarchyType || (HeirarchyType = {}));
export var POASNExportColumnsConfig = [
    {
        key: 'shipmentRefNo',
        text: 'Shipment Reference Number',
        type: 'string',
        heirarchyType: HeirarchyType.Header
    },
    {
        key: 'poNum',
        text: 'Purchase Order No',
        type: 'string',
        heirarchyType: HeirarchyType.LineItem
    },
    {
        key: 'poliNum',
        text: 'Purchase Order Line item',
        type: 'number',
        heirarchyType: HeirarchyType.LineItem
    },
    {
        key: 'proformaInv',
        text: 'Proforma Invoice Number',
        type: 'string',
        heirarchyType: HeirarchyType.LineItem
    },
    {
        key: 'proformaDate',
        text: 'Proforma Invoice Date',
        type: 'Date',
        heirarchyType: HeirarchyType.LineItem
    },
    {
        key: 'icNo',
        text: 'IC No',
        type: 'string',
        heirarchyType: HeirarchyType.Header
    },
    {
        key: 'icDate',
        text: 'IC Date',
        type: 'Date',
        heirarchyType: HeirarchyType.Header
    },
    {
        key: 'pcNo',
        text: 'PC No',
        type: 'string',
        heirarchyType: HeirarchyType.Header
    },
    {
        key: 'pcDate',
        text: 'PC Date',
        type: 'Date',
        heirarchyType: HeirarchyType.Header
    },
    {
        key: 'invoiceNo',
        text: 'Shipper\'s Invoice Number',
        type: 'string',
        heirarchyType: HeirarchyType.LineItem
    },
    {
        key: 'invoiceDate',
        text: 'Shipper\'s Invoice Date',
        type: 'Date',
        heirarchyType: HeirarchyType.LineItem
    },
    {
        key: 'invoiceDescription',
        text: 'Shipper Invoice Description',
        type: 'string',
        heirarchyType: HeirarchyType.LineItem
    },
    {
        key: 'freightForwarder',
        text: 'Name of Freight Forwader',
        type: 'string',
        heirarchyType: HeirarchyType.Header
    },
    {
        key: 'countryOfOrigin',
        text: 'Country of Origin',
        type: 'string',
        heirarchyType: HeirarchyType.LineItem
    },
    {
        key: 'portOfShipment',
        text: 'Port of Shipment',
        type: 'string',
        heirarchyType: HeirarchyType.Header
    },
    {
        key: 'mawbNo',
        text: 'MAWB No',
        type: 'string',
        heirarchyType: HeirarchyType.Header
    },
    {
        key: 'maDate',
        text: 'MAWB Date',
        type: 'Date',
        heirarchyType: HeirarchyType.Header
    },
    {
        key: 'hawbNo',
        text: 'HAWB#/ B L #',
        type: 'string',
        heirarchyType: HeirarchyType.Header
    },
    {
        key: 'hawbDate',
        text: 'HAWB / BL Date',
        type: 'Date',
        heirarchyType: HeirarchyType.Header
    },
    {
        key: 'incoTerms',
        text: 'Inco terms',
        type: 'string',
        heirarchyType: HeirarchyType.Header
    },
    {
        key: 'shipType',
        text: 'Type of Shipment',
        type: 'string',
        heirarchyType: HeirarchyType.Header
    },
    {
        key: 'portEntry',
        text: 'Port of Entry in India',
        type: 'string',
        heirarchyType: HeirarchyType.Header
    },
    {
        key: 'estimateArrivalDate',
        text: 'ETA',
        type: 'Date',
        heirarchyType: HeirarchyType.Header
    },
    {
        key: 'grossWeight',
        text: 'Gross Weight',
        type: 'string',
        heirarchyType: HeirarchyType.AbsoluteHeader
    },
    {
        key: 'noPallet',
        text: 'No. of Pallets',
        type: 'number',
        heirarchyType: HeirarchyType.AbsoluteHeader
    },
    {
        key: 'invoiceQty',
        text: 'Invoice Qty',
        type: 'number',
        heirarchyType: HeirarchyType.LineItem
    },
    {
        key: 'invoicePrice',
        text: 'Invoice Unit Price',
        type: 'string',
        heirarchyType: HeirarchyType.LineItem
    },
    {
        key: 'invoiceValue',
        text: 'Invoice value',
        type: 'string',
        heirarchyType: HeirarchyType.LineItem
    },
    {
        key: 'hsnCode',
        text: 'HSN Code',
        type: 'string',
        heirarchyType: HeirarchyType.LineItem
    },
];
