import { TelemetryClient } from '@employee-experience/core/lib/TelemetryClient';
import { HttpClient } from '@employee-experience/core/lib/HttpClient';
import { ComponentLoader } from '@employee-experience/core/lib/ComponentLoader';
import { GraphClient } from '@employee-experience/core/lib/GraphClient';
import { MSALClient } from '@employee-experience/core/lib/MSALClient';
import { Shell } from '@employee-experience/shell/lib/Shell';
import { withStore } from '@employee-experience/shell/lib/withStore';
import { StoreBuilder } from '@employee-experience/shell/lib/StoreBuilder';
import { ReducerRegistry } from '@employee-experience/shell/lib/ReducerRegistry';
var globals = require('../config').globals;
var telemetryClient = new TelemetryClient({
    instrumentationKey: globals.__INSTRUMENTATION_KEY__,
    UTPConfig: {
        EnvironmentName: '__EnvironmentName__',
        ServiceOffering: 'Finance',
        ServiceLine: 'Global Trade',
        Service: 'Global Trade Reporting',
        ComponentName: 'India Imports',
        ComponentId: 'a4ee6ba4-4a3f-431e-89cd-72053d22e9b4',
    },
    defaultProperties: {
        appName: globals.__APP_NAME__,
    },
});
var authClient = new MSALClient({
    auth: {
        clientId: globals.__CLIENT_ID__,
        redirectUri: window.location.origin,
        authority: 'https://login.microsoftonline.com/72f988bf-86f1-41af-91ab-2d7cd011db47'
    },
}, telemetryClient);
var httpClient = new HttpClient(telemetryClient, authClient);
var graphClient = new GraphClient(httpClient);
var componentLoader = new ComponentLoader(telemetryClient);
var reducerRegistry = new ReducerRegistry();
var storeResult = new StoreBuilder(reducerRegistry, {})
    .configureSaga({ telemetryClient: telemetryClient, authClient: authClient, httpClient: httpClient, componentLoader: componentLoader, graphClient: graphClient })
    .configureLogger(globals.__IS_DEVELOPMENT__)
    .build();
export var ShellWithStore = withStore(storeResult)(Shell);
