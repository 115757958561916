import * as React from 'react';
import { useState, useEffect } from 'react';
import { CommandBar } from 'office-ui-fabric-react/lib/CommandBar';
import { Fabric } from 'office-ui-fabric-react';
import { ExportType, Operation } from './EditableGridConfigReportPages';
import DetailstListWithPagination from './DetailslistWithPagination';
import { EventEmitter, EventType } from '../CommonUtilities/EventEmitter';
import { ExportToCSVUtil, ExportToExcelUtil } from '../CommonUtilities/ExcelExport';
import MessageDialog from '../CommonUtilities/editable-grid/MessageDialog';
var EditableGridWithPagination = function (props) {
    var _a = React.useState(false), showTextBoxInGrid = _a[0], setShowTextBoxInGrid = _a[1];
    var _b = useState([]), columns = _b[0], setColumns = _b[1];
    var _c = useState([]), gridData = _c[0], setGridData = _c[1];
    var _d = useState([]), defaultGridData = _d[0], setDefaultGridData = _d[1];
    var _e = useState([]), selectedIndices = _e[0], setSelectedIndices = _e[1];
    var _f = useState([]), selectedItem = _f[0], setSelectedItem = _f[1];
    var _g = React.useState({
        visible: false,
        message: '',
        subMessage: ''
    }), messageDialogProps = _g[0], setMessageDialogProps = _g[1];
    var showMessageDialog = function (message, subMessage) {
        setMessageDialogProps({
            visible: true,
            message: message,
            subMessage: subMessage
        });
    };
    var getExportableData = function () {
        var exportableColumns = props.columnConfigurationData.filter(function (x) { return x.includeColumnInExport == true; });
        var exportableData = [];
        var exportableObj = {};
        gridData.forEach(function (item1) {
            exportableColumns.forEach(function (item2) {
                exportableObj[item2.text] = item1[item2.key];
            });
            exportableData.push(exportableObj);
            exportableObj = {};
        });
        return exportableData;
    };
    var ExportToCSV = function (dataRows, fileName) {
        if (!props.onExcelExport) {
            ExportToCSVUtil(dataRows, fileName);
        }
        else {
            props.onExcelExport(ExportType.CSV);
        }
    };
    var ExportToExcel = function (dataRows, fileName) {
        if (!props.onExcelExport) {
            ExportToExcelUtil(dataRows, fileName);
        }
        else {
            props.onExcelExport(ExportType.XLSX);
        }
    };
    var onExportClick = function (type) {
        var _a;
        var fileName = (_a = props.exportFileName) !== null && _a !== void 0 ? _a : 'ExcelExport';
        switch (type) {
            case ExportType.XLSX:
                ExportToExcel(getExportableData(), fileName + '.xlsx');
                break;
            case ExportType.CSV:
                ExportToCSV(getExportableData(), fileName + '.csv');
                break;
        }
    };
    var updateValueForSelectedRow = function (item) {
        if (selectedItem.length > 0) {
            props.onRowEdit(selectedItem);
        }
        else {
            showMessageDialog('No Rows Selected', 'Please select some rows to perform this operation');
        }
        return true;
    };
    var createCommandBarItemProps = function () {
        var commandBarItems = [];
        if (props.enableExport == true) {
            commandBarItems.push({
                key: 'exportGrid',
                text: 'Export',
                disabled: showTextBoxInGrid,
                cacheKey: 'myCacheKey',
                iconProps: { iconName: 'Download' },
                subMenuProps: {
                    items: [
                        {
                            key: 'exportToExcel',
                            text: 'Excel Export',
                            iconProps: { iconName: 'Excel' },
                            onClick: function () { return onExportClick(ExportType.XLSX); }
                        },
                        {
                            key: 'exportToCSV',
                            text: 'CSV Export',
                            iconProps: { iconName: 'CSV' },
                            onClick: function () { return onExportClick(ExportType.CSV); }
                        }
                    ],
                }
            });
        }
        if (props.enableRowEdit == true) {
            commandBarItems.push({
                key: 'rowEdit',
                text: "Row Edit",
                disabled: false,
                iconProps: { iconName: "Edit" },
                onClick: function () { return updateValueForSelectedRow({}); }
            });
        }
        return commandBarItems;
    };
    var _items = createCommandBarItemProps();
    var onSearchHandler = function (event) {
        if (event && event.target) {
            var queryText_1 = event.target.value;
            if (queryText_1) {
                var searchableColumns_1 = props.columnConfigurationData.filter(function (x) { return x.includeColumnInSearch == true; }).map(function (x) { return x.key; });
                var searchResult_1 = [];
                gridData.filter(function (_gridData) {
                    var BreakException = {};
                    try {
                        searchableColumns_1.forEach(function (item2, index2) {
                            if (_gridData[item2] && _gridData[item2].toString().toLowerCase() && _gridData[item2].toString().toLowerCase().includes(queryText_1.trim().toLowerCase())) {
                                searchResult_1.push(_gridData);
                                throw BreakException;
                            }
                        });
                    }
                    catch (error) {
                        console.log(error);
                    }
                });
                setDefaultGridData(searchResult_1);
            }
            else {
                setDefaultGridData(gridData);
            }
        }
        else {
            setDefaultGridData(gridData);
        }
    };
    var createColumnConfigs = function () {
        var columnConfigs = [];
        var i = 1;
        props.columnConfigurationData.forEach(function (column, index) {
            columnConfigs.push({
                key: 'col' + i,
                name: column.text,
                ariaLabel: column.text,
                fieldName: column.key,
                isResizable: true,
                minWidth: column.minWidth,
                maxWidth: column.maxWidth,
                sortAscendingAriaLabel: 'Sorted A to Z',
                sortDescendingAriaLabel: 'Sorted Z to A',
                onRender: function (item, rowNum) {
                    return React.createElement("span", null, item[column.key]);
                }
            });
            i++;
        });
        return columnConfigs;
    };
    var gridColumns = createColumnConfigs();
    useEffect(function () {
        if (props && props.gridData && props.gridData.length > 0) {
            setGridData(props.gridData.map(function (obj, index) {
                obj._grid_row_id_ = index;
                obj._grid_row_operation_ = Operation.None;
                return obj;
            }));
            setDefaultGridData(props.gridData.map(function (obj, index) { obj._grid_row_id_ = index; return obj; }));
            setColumns(gridColumns);
        }
    }, [props]);
    useEffect(function () {
        EventEmitter.subscribe(EventType.onSearch, onSearchHandler);
        return function cleanup() {
            EventEmitter.unsubscribe(EventType.onSearch, onSearchHandler);
        };
    });
    useEffect(function () {
        EventEmitter.subscribe(EventType.onClick, onSearchHandler);
        return function cleanup() {
            EventEmitter.unsubscribe(EventType.onClick, onSearchHandler);
        };
    });
    var onGridSelectionChange = function (item) {
        console.log(item);
        setSelectedItem(item);
        console.log("oneGridSelectionChange");
    };
    var closeMessageDialog = function () {
        setMessageDialogProps({
            visible: false,
            message: '',
            subMessage: ''
        });
    };
    return (React.createElement(Fabric, null,
        messageDialogProps.visible
            ?
                React.createElement(MessageDialog, { message: messageDialogProps.message, subMessage: messageDialogProps.subMessage, onDialogClose: closeMessageDialog })
            :
                null,
        props.enableExport || props.enableRowEdit ?
            React.createElement(CommandBar, { items: _items, ariaLabel: "Use left and right arrow keys to navigate between commands" }) : null,
        gridData.length > 0 &&
            React.createElement(DetailstListWithPagination, { gridData: defaultGridData, gridColumns: gridColumns, onGridSelectionChange: onGridSelectionChange })));
};
export default EditableGridWithPagination;
