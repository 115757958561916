import * as React from 'react';
import { withContext } from '@employee-experience/common/lib/withContext';
import * as Styled from '../../shared/layout';
import { Pivot, PivotLinkSize, PivotItem, } from 'office-ui-fabric-react';
import { withRouter } from 'react-router-dom';
import { ConfigureExchangeRate } from './ConfigureExchangeRate';
import { ConfigureDutyRate } from './ConfigureDutyRate';
import { ConfigureNotification } from './ConfigureNotification';
function ConfigureRatesPage() {
    return (React.createElement(Styled.Container, null,
        React.createElement("h1", null, "ConfigureRates"),
        React.createElement(Pivot, { "aria-label": "Large Link Size Pivot Example", linkSize: PivotLinkSize.large },
            React.createElement(PivotItem, { headerText: "Configure Exchange Rate" },
                React.createElement(ConfigureExchangeRate, null)),
            React.createElement(PivotItem, { headerText: "Configure Duty Rate" },
                React.createElement(ConfigureDutyRate, null)),
            React.createElement(PivotItem, { headerText: "Configure Notification" },
                React.createElement(ConfigureNotification, null)))));
}
var connected = withContext(withRouter(ConfigureRatesPage));
export { connected as ConfigureRates };
