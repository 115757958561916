export var Config = {
    name: 'IndiaImports',
    script: '/bundles/india-imports.js'
};
;
export var GraphConfig = {
    graphurl: '/me/transitiveMemberOf/microsoft.graph.group',
    graphQuery: '$count=true&$top=999&$select=displayName,id',
    authGrpId: '__AuthGroupId__'
};
export var Scopes = ['https://graph.microsoft.com/User.Read', 'https://graph.microsoft.com/Directory.Read.All'];
