export function isNumeric(num) {
    if (String(num).length == 0)
        return false;
    return !isNaN(parseFloat(String(num))) && isFinite(Number(num));
}
export function isAlphaNumeric(str) {
    if (str.length == 0)
        return false;
    var regex = new RegExp('^[a-z0-9]+$/i');
    return regex.test(str);
}
