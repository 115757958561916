import * as React from 'react';
import * as Styled from '../../../shared/layout';
import { Fabric, DetailsListLayoutMode, SelectionMode } from 'office-ui-fabric-react';
import { useState, useEffect } from 'react';
import { ComponentContext } from '@employee-experience/common/lib/Context/ComponentContext';
import { toast } from 'react-toastify';
// @ts-ignore
import { EventEmitter, EventType } from '../../CommonUtilities/EventEmitter';
import '!style-loader!css-loader!react-toastify/dist/ReactToastify.css';
import { LoadingIndicator } from '../../CommonUtilities/LoadingIndicator';
import { appConfig } from '../../../config/appConfig';
import EditableGridWithPagination from '../EditableGridWithPagination';
import { DetailReportColumnConfigurationData } from '../EditableGridConfigReportPages';
import { ExportToCSVUtil, ExportToExcelUtil } from '../../CommonUtilities/ExcelExport';
import { ExportType } from '../../CommonUtilities/editable-grid/EditableGridConfig';
import { httpGet } from '../../../shared/APICalls';
var baseUrl = appConfig.AppSettings.APIUrl;
function DetailedReport(props) {
    var context = React.useContext(ComponentContext);
    var _httpClient = context === null || context === void 0 ? void 0 : context.httpClient;
    var _a = useState([]), defaultItems = _a[0], setDefaultItems = _a[1];
    var onSuccessCallback = function (response) {
        setDefaultItems(response.data);
        if (response) {
            if (response.data.length == 0)
                toast.success("No entries to show.");
        }
    };
    var onErrorCallback = function (error) {
        console.log(error);
    };
    var onClickGetDataHandler = function (event) {
        var searchId = event.target.value;
        var url = baseUrl + "/Reports/Details?searchId=" + searchId;
        httpGet(_httpClient, url, onSuccessCallback, onErrorCallback);
    };
    var ConstructExportData = function () {
        var data = [];
        defaultItems.forEach(function (item, index) {
            var dataRow = {};
            DetailReportColumnConfigurationData.forEach(function (element) {
                dataRow[element.text] = item[element.key];
            });
            data.push(dataRow);
        });
        return data;
    };
    var onDetailedExcelExport = function (exportType) {
        var data = ConstructExportData();
        switch (exportType) {
            case ExportType.XLSX:
                ExportToExcelUtil(data, 'DetailedReport.xlsx');
                break;
            case ExportType.CSV:
                ExportToCSVUtil(data, 'DetailedReport.csv');
                break;
        }
    };
    useEffect(function () {
        var url = baseUrl + "/Reports/Details?fromDate=" + props.gridFilteringData['fromDate'] + "&toDate=" + props.gridFilteringData['toDate'] + "&companyCode=" + props.gridFilteringData['companyCode'] + "&shipmentStatus=" + props.gridFilteringData['shipmentStatus'] + "&filterType=" + props.gridFilteringData['filterType'];
        httpGet(_httpClient, url, onSuccessCallback, onErrorCallback);
    }, [props.gridFilteringData]);
    useEffect(function () {
        EventEmitter.subscribe(EventType.onClick, onClickGetDataHandler);
        return function cleanup() {
            EventEmitter.unsubscribe(EventType.onClick, onClickGetDataHandler);
        };
    });
    useEffect(function () {
        toast.configure({
            position: 'top-center',
            autoClose: 5000,
            bodyClassName: 'toast-body',
            hideProgressBar: true,
            style: { marginTop: 50 },
        });
    }, []);
    return (React.createElement(Styled.listContainer, null,
        React.createElement(Fabric, null,
            React.createElement("div", null,
                React.createElement(LoadingIndicator, null)),
            defaultItems.length > 0 && (React.createElement(EditableGridWithPagination, { enableColumnEdit: false, enableExport: true, enableSave: false, columnConfigurationData: DetailReportColumnConfigurationData, layoutMode: DetailsListLayoutMode.justified, selectionMode: SelectionMode.none, enableRowEdit: false, enableBulkEdit: false, gridData: defaultItems, onExcelExport: onDetailedExcelExport })))));
}
export default DetailedReport;
