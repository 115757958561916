var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import * as React from 'react';
import { ConstrainMode, DetailsListLayoutMode } from 'office-ui-fabric-react/lib/components/DetailsList/DetailsList.types';
import { useState, useEffect } from 'react';
import { DetailsList } from 'office-ui-fabric-react/lib/components/DetailsList/DetailsList';
import { useConstCallback } from '@uifabric/react-hooks';
import { ComponentContext } from '@employee-experience/common/lib/Context/ComponentContext';
import { IconButton } from 'office-ui-fabric-react/lib/components/Button/IconButton/IconButton';
import { Panel, PanelType, Link, CommandBar, Fabric, TooltipHost, SelectionMode, ScrollablePane, TooltipOverflowMode, Sticky, StickyPositionType, } from 'office-ui-fabric-react';
import { toast } from 'react-toastify';
import * as Styled from '../../shared/layout';
// @ts-ignore
import { EventEmitter, EventType } from '../CommonUtilities/EventEmitter';
//import '../../../node_modules/react-toastify/dist/ReactToastify.css'
import '!style-loader!css-loader!react-toastify/dist/ReactToastify.css';
import { useHistory } from 'react-router-dom';
import GenerateShipmentRefNumber from './GenerateShipmentRefNumber';
import { PODetails } from './PODetails';
import { httpGet } from '../../shared/APICalls';
import { controlClass, hostStyles } from './AdvancedSearchStyles';
var appConfig = require('../../config/appConfig.js').appConfig;
var baseUrl = appConfig.AppSettings.APIUrl;
import { GrStatusGoodSmall } from 'react-icons/gr';
var SearchResultsData = function (props) {
    var resourceId = appConfig.AppSettings.AppClientId;
    var context = React.useContext(ComponentContext);
    var _httpClient = context === null || context === void 0 ? void 0 : context.httpClient;
    var history = useHistory();
    var _a = useState(false), isOpen = _a[0], setIsOpen = _a[1];
    var openPanel = useConstCallback(function () { return setIsOpen(true); });
    var dismissPanel = useConstCallback(function () { return setIsOpen(false); });
    var _b = React.useState(false), isOpenForCreate = _b[0], setIsOpenForCreate = _b[1];
    var openPanelForCreate = useConstCallback(function () { return setIsOpenForCreate(true); });
    var dismissPanelForCreate = useConstCallback(function () { return setIsOpenForCreate(false); });
    var _c = React.useState(false), isOpenPODetails = _c[0], setIsOpenPODetails = _c[1];
    var openPanelPODatils = useConstCallback(function (poNumber) {
        setIsOpenPODetails(true);
    });
    var dismissPanelPODetails = useConstCallback(function () { return setIsOpenPODetails(false); });
    var _d = useState(sessionStorage.getItem('searchText') || ''), searchText = _d[0], setSearchText = _d[1];
    React.useEffect(function () {
        sessionStorage.setItem('searchText', searchText);
    }, [searchText]);
    var _e = useState(''), poNumberForPODetails = _e[0], setPONumberForPODetails = _e[1];
    var _f = useState([]), searchResults = _f[0], setSearchResults = _f[1];
    var _g = useState([]), defaultSearchResults = _g[0], setDefaultSearchResults = _g[1];
    var _h = useState([]), poShipmentData = _h[0], setPoShipmentData = _h[1];
    var _j = useState([]), columns = _j[0], setColumns = _j[1];
    var _k = useState({
        hawbno: '',
        netwr: null,
        netwR_Count: null,
        misc_Charges: null,
        freight: null,
        insurance: null,
        other_additions: null,
    }), formData = _k[0], setFormData = _k[1];
    var _items = [
        {
            key: 'newItem',
            text: "Link PO's to Shipment",
            cacheKey: 'myCacheKey',
            iconProps: { iconName: 'Add' },
            onClick: function () {
                openPanelForCreate();
            },
        },
    ];
    var handleColumnClick = function (ev, column) {
        var newColumns = columns.slice();
        var currColumn = newColumns.filter(function (currCol) { return column.key === currCol.key; })[0];
        newColumns.forEach(function (newCol) {
            if (newCol === currColumn) {
                currColumn.isSortedDescending = !currColumn.isSortedDescending;
                currColumn.isSorted = true;
            }
            else {
                newCol.isSorted = false;
                newCol.isSortedDescending = true;
            }
        });
        var newItems = _copyAndSort(_items, currColumn.fieldName, currColumn.isSortedDescending);
        setColumns(newColumns);
    };
    useEffect(function () {
        toast.configure({
            position: 'top-center',
            autoClose: 5000,
            bodyClassName: 'toast-body',
            hideProgressBar: true,
            style: { marginTop: 50 },
        });
    }, []);
    var onRenderWithToolTip = function (content, index, key) {
        return React.createElement(TooltipHost, { overflowMode: TooltipOverflowMode.Parent, content: content, styles: hostStyles },
            React.createElement("span", { "aria-describedby": key + index }, content));
    };
    var column = [
        {
            key: 'poNumber',
            name: 'PO Number',
            ariaLabel: 'poNumber',
            fieldName: 'poNumber',
            isResizable: true,
            minWidth: 120,
            maxWidth: 150,
            sortAscendingAriaLabel: 'Sorted A to Z',
            sortDescendingAriaLabel: 'Sorted Z to A',
            //onColumnClick: handleColumnClick,
            //onRender: (item) => <span> {item.poNumber}</span>,
            onRender: function (item) { return ((item.poNumber != null && item.poNumber != "") &&
                React.createElement(Link, { onClick: function () {
                        openPanelPODatils(item.poNumber);
                        setPONumberForPODetails(item.poNumber);
                    } }, item.poNumber)); },
        },
        {
            key: 'shipmentRefNumber',
            name: 'Shipment Ref #',
            ariaLabel: 'shipmentRefNumber',
            fieldName: 'shipmentRefNumber',
            isResizable: true,
            minWidth: 120,
            maxWidth: 150,
            data: 'string',
            onRender: function (item) { return (React.createElement(Link, { onClick: function () {
                    navigateToShipmentDetailsPage(item.shipmentRefNumber);
                } }, item.shipmentRefNumber)); },
        },
        {
            key: 'shipmentStatus',
            name: 'Shipment Status',
            ariaLabel: 'shipmentStatus',
            fieldName: 'shipmentStatus',
            isResizable: true,
            minWidth: 200,
            maxWidth: 250,
            data: 'string',
            onRender: function (item) {
                switch (item.shipmentStatus) {
                    case 0:
                        return React.createElement("div", null,
                            React.createElement(GrStatusGoodSmall, { color: '#646464' }),
                            React.createElement("span", { className: controlClass.multilineTextStyles }, props.shipmentsStatusList != null &&
                                item.shipmentStatus != null ? props.shipmentsStatusList.filter(function (items) { return items.key == item.shipmentStatus; })[0].text
                                : "No status assigned to this shipment"));
                        break;
                    case 1:
                        return React.createElement("div", null,
                            React.createElement(GrStatusGoodSmall, { color: '#c5262c' }),
                            React.createElement(IconButton, { iconProps: { iconName: 'LocationFill' }, title: "status", ariaLabel: "status", styles: { root: { color: "#c5262c" }, } }),
                            React.createElement("span", { className: controlClass.multilineTextStyles }, props.shipmentsStatusList != null &&
                                item.shipmentStatus != null ? props.shipmentsStatusList.filter(function (items) { return items.key == item.shipmentStatus; })[0].text
                                : "No status assigned to this shipment"));
                        break;
                    case 2:
                        return React.createElement("div", null,
                            React.createElement(GrStatusGoodSmall, { color: '#107c10' }),
                            React.createElement("span", { className: controlClass.multilineTextStyles }, props.shipmentsStatusList != null &&
                                item.shipmentStatus != null ? props.shipmentsStatusList.filter(function (items) { return items.key == item.shipmentStatus; })[0].text
                                : "No status assigned to this shipment"));
                        break;
                    default:
                        return React.createElement("div", null,
                            React.createElement(GrStatusGoodSmall, { color: '#0078D4' }),
                            React.createElement("span", { className: controlClass.multilineTextStyles }, props.shipmentsStatusList != null &&
                                item.shipmentStatus != null ? props.shipmentsStatusList.filter(function (items) { return items.key == item.shipmentStatus; })[0].text
                                : "No status assigned to this shipment"));
                        break;
                }
            }
        },
        {
            key: 'vendorName',
            name: 'Vendor Name',
            ariaLabel: 'VendorName',
            fieldName: 'vendorName',
            isResizable: true,
            minWidth: 250,
            maxWidth: 250,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.vendorName); },
        },
        {
            key: 'shipTo',
            name: 'Ship To',
            ariaLabel: 'Ship To',
            fieldName: 'shipTo',
            isResizable: true,
            minWidth: 200,
            maxWidth: 200,
            data: 'string',
            onRender: function (item, index) { return onRenderWithToolTip(item.shipTo, index, 'shipTo'); }
        },
        {
            key: 'companyCode',
            name: 'Company Code',
            ariaLabel: 'companyCode',
            fieldName: 'companyCode',
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.companyCode); },
        },
        {
            key: 'airwayBillNumber',
            name: 'HAWBNO',
            ariaLabel: 'hawbno',
            fieldName: 'airwayBillNumber',
            isResizable: true,
            minWidth: 100,
            maxWidth: 150,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.airwayBillNumber); },
        },
        {
            key: 'shipmentInvoiceNumber',
            name: 'Shipment Invoice #',
            ariaLabel: 'Shipment Invoice #',
            fieldName: 'shipmentInvoiceNumber',
            isResizable: true,
            minWidth: 150,
            maxWidth: 150,
            data: 'string',
            onRender: function (item) { var _a; return React.createElement("ul", null, (_a = item.shipmentInvoiceNumber.split(",")) === null || _a === void 0 ? void 0 : _a.map(function (item, i) { return React.createElement("li", { key: item + i }, item); })); },
        },
        {
            key: 'comments',
            name: 'Comments',
            ariaLabel: 'Comments',
            fieldName: 'comments',
            isResizable: true,
            minWidth: 200,
            maxWidth: 200,
            data: 'string',
            onRender: function (item) { return React.createElement("span", { className: controlClass.multilineTextStyles }, item.comments); },
        },
        // {
        //     key: 'shipmentStatus',
        //     name: 'Shipment Status',
        //     ariaLabel: 'shipmentStatus',
        //     fieldName: 'shipmentStatus',
        //     isResizable: true,
        //     minWidth: 70,
        //     maxWidth: 150,
        //     data: 'string',
        //     onRender: (item) => <span> {item.misc_Charges}</span>,
        // },
        {
            key: 'generalDesc',
            name: 'General Description',
            ariaLabel: 'General Description',
            fieldName: 'generalDesc',
            isResizable: true,
            minWidth: 250,
            maxWidth: 250,
            data: 'string',
            onRender: function (item) { return React.createElement("span", { className: controlClass.multilineTextStyles },
                " ",
                item.generalDesc); },
        },
        {
            key: 'lastUpdatedBy',
            name: 'Last Updated By',
            ariaLabel: 'lastUpdatedBy',
            fieldName: 'lastUpdatedBy',
            isResizable: true,
            minWidth: 50,
            maxWidth: 150,
            data: 'string',
            onRender: function (item) { return React.createElement("span", null,
                " ",
                item.lastUpdatedBy); },
        },
    ];
    var SearchResults = function (searchId) { return __awaiter(void 0, void 0, void 0, function () {
        var fromDate, toDate, companyCode, shipmentStatus, url;
        return __generator(this, function (_a) {
            fromDate = dateToISOLikeButLocal(new Date(sessionStorage.getItem('fromDate')));
            toDate = dateToISOLikeButLocal(new Date(sessionStorage.getItem('toDate')));
            companyCode = sessionStorage.getItem('companyCode');
            shipmentStatus = sessionStorage.getItem('shipmentStatus');
            if (fromDate <= toDate) {
                url = baseUrl + "/Workspace/SearchResults?searchId=" + searchId + "&fromDate=" + fromDate + "&toDate=" + toDate + "&companyCode=" + companyCode + "&shipmentStatus=" + props.gridFilteringData['shipmentStatus'];
                httpGet(_httpClient, url, function (response) {
                    setColumns(column);
                    setSearchResults(response.data);
                    setDefaultSearchResults(response.data);
                }, function (error) {
                    toast.error(error.data.Message);
                });
            }
            else {
                toast.error("Please ensure FromDate should be less than or equal to ToDate");
            }
            ;
            return [2 /*return*/];
        });
    }); };
    var DefaultSearchResults = function () { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            url = baseUrl + "/Workspace";
            httpGet(_httpClient, url, function (response) {
                setColumns(column);
                setSearchResults(response.data);
                setDefaultSearchResults(response.data);
                FetchPOData();
            }, function (error) {
                toast.error(error.data.Message);
            });
            return [2 /*return*/];
        });
    }); };
    useEffect(function () {
        DefaultSearchResults();
    }, []);
    useEffect(function () {
        if (props.gridFilteringData.fromDate != null && props.gridFilteringData.toDate != null && props.gridFilteringData.companyCode != '') {
            SearchResults('');
        }
    }, [props.gridFilteringData]);
    var FetchPOData = function () { return __awaiter(void 0, void 0, void 0, function () {
        var url;
        return __generator(this, function (_a) {
            url = baseUrl + "/Workspace/GetPOsAndShipmentPOs";
            httpGet(_httpClient, url, function (response) {
                setPoShipmentData(response.data);
            }, function (error) {
                toast.error(error.data.Message);
            });
            return [2 /*return*/];
        });
    }); };
    function _copyAndSort(item, columnKey, isSortedDescending) {
        var key = columnKey;
        return item.slice(0).sort(function (a, b) { return ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1); });
    }
    var updateFormData = function (event) {
        var _a;
        setFormData(__assign(__assign({}, formData), (_a = {}, _a[event.target.name] = event.target.value, _a)));
    };
    var onSubmit = function (ev) {
        ev.preventDefault();
    };
    var onSearchHandler = function (event) {
        if (event && event.target) {
            var queryText = event.target.value;
            setSearchText(queryText);
            if (queryText) {
                var filteredData = filterData(queryText);
                setDefaultSearchResults(filteredData);
            }
            else {
                setDefaultSearchResults(searchResults);
            }
        }
        else {
            setSearchText('');
            setDefaultSearchResults(searchResults);
        }
    };
    var filterData = function (queryText) {
        return searchResults.filter(function (_items) {
            return (_items.hawbno && _items.hawbno.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.airwayBillNumber &&
                    _items.airwayBillNumber.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.shipmentInvoiceNumber &&
                    _items.shipmentInvoiceNumber.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.poNumber && _items.poNumber.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.shipmentRefNumber &&
                    _items.shipmentRefNumber.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.comments &&
                    _items.comments.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.shipTo &&
                    _items.shipTo.toLowerCase().includes(queryText.trim().toLowerCase())) ||
                (_items.generalDesc &&
                    _items.generalDesc.toLowerCase().includes(queryText.trim().toLowerCase()));
        });
    };
    function dateToISOLikeButLocal(date) {
        var offsetMs = date.getTimezoneOffset() * 60 * 1000;
        var msLocal = date.getTime() - offsetMs;
        var dateLocal = new Date(msLocal);
        var iso = dateLocal.toISOString();
        var isoLocal = iso.slice(0, 10);
        return isoLocal;
    }
    var onClickGetDataHandler = function (event) {
        if (event && event.target) {
            setIsOpenForCreate(false);
        }
        if (event.target.value.length > 0) {
            SearchResults(event.target.value);
        }
        else {
            toast.error("Please ensure Proper search criteria in Searchbox");
        }
    };
    var navigateToShipmentDetailsPage = function (shipmentRefNo) {
        history.push("/shipment-details/" + shipmentRefNo);
    };
    var onGenerateShipmentRefNoPanelSubmit = function (item) {
        dismissPanelForCreate();
        setPoShipmentData([]);
        DefaultSearchResults();
    };
    useEffect(function () {
        EventEmitter.subscribe(EventType.onSearch, onSearchHandler);
        return function cleanup() {
            EventEmitter.unsubscribe(EventType.onSearch, onSearchHandler);
        };
    });
    React.useEffect(function () {
        EventEmitter.subscribe(EventType.onClick, onClickGetDataHandler);
        return function cleanup() {
            EventEmitter.unsubscribe(EventType.onClick, onClickGetDataHandler);
        };
    });
    var onRenderDetailsHeader = function (props, defaultRender) {
        if (!props) {
            return null;
        }
        var onRenderColumnHeaderTooltip = function (tooltipHostProps) { return (React.createElement(TooltipHost, __assign({}, tooltipHostProps))); };
        return (React.createElement(Sticky, { stickyPosition: StickyPositionType.Header, isScrollSynced: true }, defaultRender(__assign(__assign({}, props), { onRenderColumnHeaderTooltip: onRenderColumnHeaderTooltip }))));
    };
    return (React.createElement(Styled.listContainer, null,
        React.createElement(Fabric, null,
            React.createElement(CommandBar, { items: _items, ariaLabel: "Use left and right arrow keys to navigate between commands", style: { marginLeft: '73%', marginTop: -40 } }),
            React.createElement("div", null, poShipmentData && poShipmentData.length > 0 ? (React.createElement(Panel, { headerText: "Link PO's to Shipment", isOpen: isOpenForCreate, onDismiss: dismissPanelForCreate, isLightDismiss: true, type: PanelType.medium, 
                // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                closeButtonAriaLabel: "Close" },
                React.createElement(GenerateShipmentRefNumber, { poShipmentData: poShipmentData, onPanelSubmit: onGenerateShipmentRefNoPanelSubmit }))) : null),
            React.createElement("div", null,
                React.createElement(Panel, { headerText: "PO Details", isOpen: isOpenPODetails, onDismiss: dismissPanelPODetails, isLightDismiss: true, type: PanelType.medium, 
                    // You MUST provide this prop! Otherwise screen readers will just say "button" with no label.
                    closeButtonAriaLabel: "Close" },
                    React.createElement(PODetails, { poNumber: poNumberForPODetails }))),
            React.createElement(ScrollablePane, { style: { position: 'relative', height: '60vh' } },
                React.createElement("div", { tabIndex: 0 },
                    React.createElement(DetailsList, { columns: columns, items: defaultSearchResults, layoutMode: DetailsListLayoutMode.fixedColumns, constrainMode: ConstrainMode.unconstrained, setKey: "none", onRenderDetailsHeader: onRenderDetailsHeader, ariaLabel: "Value", ariaLabelForSelectAllCheckbox: "Toggle selection for all items", ariaLabelForSelectionColumn: "Toggle selection", checkButtonAriaLabel: "Row checkbox", selectionMode: SelectionMode.none }))))));
};
export default SearchResultsData;
