import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { AdvancedSearch } from '../src/containers/Workspace/AdvancedSearch';
import { IndiaImportsReports } from '../src/containers/Reports/IndiaImportsReports';
import { ShipmentDetails } from '../src/containers/Workspace/ShipmentDetails';
import { Dashboard } from '../src/containers/Dashboard/Dashboard';
import { ConfigureRates } from '../src/containers/ConfigureRatesData/ConfigureRates';
export function Routes() {
    return (React.createElement(Switch, null,
        React.createElement(Route, { path: "/dashboard", component: Dashboard }),
        React.createElement(Route, { path: "/advanced-search", component: AdvancedSearch }),
        React.createElement(Route, { path: "/shipment-details", component: ShipmentDetails }),
        React.createElement(Route, { path: "/india-imports-reports", component: IndiaImportsReports }),
        React.createElement(Route, { path: "/configure-rates", component: ConfigureRates }),
        React.createElement(Route, { path: "/", component: AdvancedSearch })));
}
