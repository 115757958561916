import * as React from 'react';
import { usePromiseTracker } from 'react-promise-tracker';
import Loader from 'react-loader-spinner';
export var LoadingIndicator = function (props) {
    var promiseInProgress = usePromiseTracker().promiseInProgress;
    return (promiseInProgress && (React.createElement("div", { style: {
            position: 'fixed',
            left: 0,
            right: 0,
            top: 0,
            bottom: 0,
            width: '100%',
            opacity: 0.3,
            zIndex: 99999,
        } },
        React.createElement("div", { className: "spinner", style: { position: 'absolute', left: '50%', top: '40%' } },
            React.createElement(Loader, { type: "ThreeDots", color: "#2BAD60", height: "100", width: "100", margin: "auto" })))));
};
