export var navConfig = [
    {
        key: 'Main',
        links: [
            {
                key: 1,
                name: 'Home',
                href: '/dashboard',
                icon: 'Home',
            },
            {
                key: 2,
                name: 'India Imports',
                links: [
                    {
                        key: 1,
                        name: 'Workspace',
                        href: '/advanced-search',
                    },
                    {
                        key: 1,
                        name: 'Reports',
                        href: '/india-imports-reports',
                    },
                ],
                icon: 'CloudImportExport',
            },
            {
                key: 1,
                name: 'ConfigureRates',
                href: '/configure-rates',
            },
        ],
    },
];
