import { createTheme } from "office-ui-fabric-react";
var labelWrapperLineHeight = 14;
var labelWrapperHeight = labelWrapperLineHeight * 2 + 2;
export var stackTokens = { childrenGap: 35 };
export var stackStyles = {
    root: {
        overflow: 'hidden',
        width: '100%',
    },
};
export var stackItemStyles = {
    root: {
        alignItems: 'left',
        display: 'flex',
        height: 70,
        justifyContent: 'left',
        overflow: 'hidden',
    },
};
export var stackItemStylesSeparator = {
    root: {
        alignItems: 'middle',
        display: 'flex',
        height: 50,
        justifyContent: 'left',
        overflow: 'hidden',
    },
};
export var styles = {
    root: {
        width: 40,
        height: 40,
    },
};
export var labelStyles = {
    root: {
        color: '#0080FF'
    }
};
export var theme = createTheme({
    fonts: {
        medium: {
            fontSize: '20px',
            fontWeight: 'bold',
            backgroundColor: 'transparent',
            height: labelWrapperHeight,
            lineHeight: labelWrapperLineHeight,
        },
    },
});
export var AddIcon = { iconName: 'Add' };
export var SubtractIcon = { iconName: 'CalculatorSubtract' };
