const dynamicComponents = {
    'dashboard': './src/containers/Dashboard/Dashboard.tsx'
};

const globals = {
    /* =========================
     * Add build time variables here
     * These variables also need to added in ./global.d.ts file to be available in Typescript
     * ====================== */

    __IS_DEVELOPMENT__: '__IsDevelopment__',
    __APP_NAME__: '__AppName__',
    __CLIENT_ID__: '__AppClientId__',
    __BASE_URL__: '__BaseUrl__',
    __INSTRUMENTATION_KEY__: '__InstrumentationKeyUI__',
};

module.exports = {
    dynamicComponents,
    globals,
};
